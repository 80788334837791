import React, { useState, useEffect } from 'react';
import './Quiz.css'; // Add CSS for styling the quiz


const questions = [
    {
        question: "Which Surah is known as the heart of the Quran?",
        options: ["Al-Baqarah", "Yasin", "Al-Fatiha", "Al-Kahf"],
        correctAnswer: "Yasin",
    },
    {
        question: "How many chapters are there in the Quran?",
        options: ["110", "114", "120", "112"],
        correctAnswer: "114",
    },
    {
        question: "Which Surah was revealed first?",
        options: ["Al-Baqarah", "Al-Ikhlas", "Al-Alaq", "Al-Kahf"],
        correctAnswer: "Al-Alaq",
    },
    {
        question: "Which Surah has the longest verse in the Quran?",
        options: ["Al-Baqarah", "An-Nisa", "Al-Imran", "Al-Mumtahina"],
        correctAnswer: "Al-Baqarah",
    },
    {
        question: "What is the first word revealed in the Quran?",
        options: ["Iqra", "Bismillah", "Alhamdulillah", "Allahu Akbar"],
        correctAnswer: "Iqra",
    },
    {
        question: "Which Prophet is mentioned most in the Quran?",
        options: ["Prophet Musa", "Prophet Isa", "Prophet Muhammad", "Prophet Ibrahim"],
        correctAnswer: "Prophet Musa",
    },
    {
        question: "Which Surah is the shortest in the Quran?",
        options: ["Al-Kawthar", "Al-Ikhlas", "Al-Fatiha", "An-Nas"],
        correctAnswer: "Al-Kawthar",
    },
    {
        question: "What does the word 'Quran' mean?",
        options: ["The Book", "The Revelation", "The Recitation", "The Message"],
        correctAnswer: "The Recitation",
    },
    {
        question: "Which Surah has the verse of 'Ayat al-Kursi'?",
        options: ["Al-Baqarah", "An-Nisa", "Al-Imran", "Al-Alaq"],
        correctAnswer: "Al-Baqarah",
    },
    {
        question: "What was the first revelation received by Prophet Muhammad?",
        options: ["Surah Al-Ikhlas", "Surah Al-Alaq", "Surah Al-Fatiha", "Surah Yasin"],
        correctAnswer: "Surah Al-Alaq",
    },
    {
        question: "Which Surah is called 'The Mother of the Book'?",
        options: ["Al-Baqarah", "Al-Fatiha", "An-Nisa", "Al-Ikhlas"],
        correctAnswer: "Al-Fatiha",
    },
    {
        question: "How many times is the name of Prophet Muhammad mentioned in the Quran?",
        options: ["3", "4", "5", "7"],
        correctAnswer: "4",
    },
    {
        question: "In which Surah do we find the story of the Cow?",
        options: ["Al-Baqarah", "Al-Kahf", "Yusuf", "An-Nisa"],
        correctAnswer: "Al-Baqarah",
    },
    {
        question: "What is the meaning of the word 'Bismillah'?",
        options: ["In the name of Allah", "Praise be to Allah", "Glory be to Allah", "There is no God but Allah"],
        correctAnswer: "In the name of Allah",
    },
    {
        question: "Who was the first person to accept Islam after Prophet Muhammad?",
        options: ["Abu Bakr", "Khadijah", "Ali", "Zayd ibn Haritha"],
        correctAnswer: "Khadijah",
    },
    {
        question: "Which Surah was revealed to Prophet Muhammad during the Night Journey (Isra and Mi'raj)?",
        options: ["Al-Isra", "Al-Alaq", "Al-Fatiha", "An-Nisa"],
        correctAnswer: "Al-Isra",
    },
    {
        question: "Which is the longest Surah in the Quran?",
        options: ["Al-Baqarah", "Al-Imran", "Al-Nisa", "Al-Kahf"],
        correctAnswer: "Al-Baqarah",
      },
      {
        question: "Who is the first Caliph of Islam?",
        options: ["Abu Bakr", "Umar ibn al-Khattab", "Ali ibn Abi Talib", "Uthman ibn Affan"],
        correctAnswer: "Abu Bakr",
      },
      {
        question: "What is the first month of the Islamic calendar?",
        options: ["Muharram", "Rajab", "Ramadan", "Shawwal"],
        correctAnswer: "Muharram",
      },
      {
        question: "Which Prophet was swallowed by a whale?",
        options: ["Prophet Yunus", "Prophet Musa", "Prophet Isa", "Prophet Ibrahim"],
        correctAnswer: "Prophet Yunus",
      },
      {
        question: "Which Surah is the 100th Surah of the Quran?",
        options: ["Al-Qari'ah", "At-Takathur", "Al-Bayyina", "Al-Zalzalah"],
        correctAnswer: "At-Takathur",
      },
      {
        question: "Who was the last Prophet in Islam?",
        options: ["Prophet Isa", "Prophet Musa", "Prophet Muhammad", "Prophet Ibrahim"],
        correctAnswer: "Prophet Muhammad",
      },
      {
        question: "How many years did Prophet Muhammad live in Medina?",
        options: ["10 years", "12 years", "13 years", "15 years"],
        correctAnswer: "10 years",
      },
      {
        question: "What was the name of the battle fought in the second year of Hijra?",
        options: ["Battle of Badr", "Battle of Uhud", "Battle of Tabuk", "Battle of Khandaq"],
        correctAnswer: "Battle of Badr",
      },
      {
        question: "Which Surah is named after a woman?",
        options: ["An-Nisa", "Maryam", "Al-Ahzab", "At-Tahrim"],
        correctAnswer: "Maryam",
      },
      {
        question: "Which companion of the Prophet was known as the 'Sword of Allah'?",
        options: ["Ali ibn Abi Talib", "Abu Bakr", "Umar ibn al-Khattab", "Khalid ibn al-Walid"],
        correctAnswer: "Khalid ibn al-Walid",
      },
      {
        question: "What is the name of the Angel who is responsible for blowing the trumpet on the Day of Judgement?",
        options: ["Jibril", "Israfil", "Mika'il", "Azrael"],
        correctAnswer: "Israfil",
      },
      {
        question: "How many times is the word 'Bismillah' mentioned in the Quran?",
        options: ["112", "113", "114", "115"],
        correctAnswer: "114",
      },
      {
        question: "Which Surah was revealed to Prophet Muhammad while he was on his deathbed?",
        options: ["An-Nas", "Al-Ikhlas", "Al-Fatiha", "Al-Mulk"],
        correctAnswer: "An-Nas",
      },
      {
        question: "Which Prophet was thrown into the fire by his people?",
        options: ["Prophet Ibrahim", "Prophet Musa", "Prophet Isa", "Prophet Muhammad"],
        correctAnswer: "Prophet Ibrahim",
      },
      {
        question: "How many wives did Prophet Muhammad have?",
        options: ["4", "9", "11", "12"],
        correctAnswer: "9",
      },
      {
        question: "Which Surah has the verse, 'Indeed, Allah is with those who fear Him and those who are doers of good'?",
        options: ["At-Tawbah", "Al-Baqarah", "Al-Imran", "An-Nisa"],
        correctAnswer: "At-Tawbah",
      },
      {
        question: "Which Surah is named after a specific month?",
        options: ["Al-Mulk", "Al-Rum", "Al-Tawbah", "Al-Ahzab"],
        correctAnswer: "Al-Rum",
      },
      {
        question: "Which Prophet is known for his patience?",
        options: ["Prophet Nuh", "Prophet Yusuf", "Prophet Musa", "Prophet Ayyub"],
        correctAnswer: "Prophet Ayyub",
      },
      {
        question: "What is the capital city of Saudi Arabia?",
        options: ["Mecca", "Medina", "Riyadh", "Jeddah"],
        correctAnswer: "Riyadh",
      },
      {
        question: "Which angel brings revelations to the Prophets?",
        options: ["Jibril", "Mika'il", "Azrael", "Israfil"],
        correctAnswer: "Jibril",
      },
      {
        question: "In which Surah do we find the verse of the Throne (Ayat al-Kursi)?",
        options: ["Al-Baqarah", "Al-Imran", "An-Nisa", "Al-Mulk"],
        correctAnswer: "Al-Baqarah",
      },
      {
        question: "What is the last Surah of the Quran?",
        options: ["Al-Fil", "An-Nas", "Al-Ikhlas", "Al-Mulk"],
        correctAnswer: "An-Nas",
      },
      {
        question: "Which Prophet built the Ka'bah in Mecca?",
        options: ["Prophet Ibrahim", "Prophet Musa", "Prophet Nuh", "Prophet Muhammad"],
        correctAnswer: "Prophet Ibrahim",
      },
      {
        question: "Which is the smallest Surah in the Quran?",
        options: ["Al-Ikhlas", "Al-Fil", "Al-Nas", "Al-Asr"],
        correctAnswer: "Al-Fil",
      },
      {
        question: "What is the name of the battle in which the Muslims won despite being outnumbered?",
        options: ["Battle of Badr", "Battle of Uhud", "Battle of Tabuk", "Battle of Khandaq"],
        correctAnswer: "Battle of Badr",
      },
      {
        question: "Which Surah is known as the 'Surah of Light'?",
        options: ["An-Nur", "Al-Ahzab", "At-Tawbah", "Al-Baqarah"],
        correctAnswer: "An-Nur",
      },
      {
        question: "Which Prophet's story is associated with the journey of the Ark?",
        options: ["Prophet Musa", "Prophet Ibrahim", "Prophet Nuh", "Prophet Muhammad"],
        correctAnswer: "Prophet Nuh",
      },
      {
        question: "How many times is the word 'Allah' mentioned in the Quran?",
        options: ["500", "1000", "2200", "2699"],
        correctAnswer: "2200",
      },
      {
        question: "Which Surah was revealed in the year of sorrow (the year Prophet Muhammad lost his uncle and wife)?",
        options: ["Al-Ahzab", "Al-Qamar", "Al-Kawthar", "At-Tawbah"],
        correctAnswer: "Al-Qamar",
      },
      {
        question: "What is the name of the first Muslim woman?",
        options: ["Khadijah", "Aisha", "Fatimah", "Sumayyah"],
        correctAnswer: "Khadijah",
      },
      {
        question: "Which Surah is known as the 'Surah of Prosperity'?",
        options: ["Al-Asr", "Al-Baqarah", "Al-Nasr", "At-Takathur"],
        correctAnswer: "Al-Asr",
      },
      {
        question: "Who was the first person to accept Islam after Prophet Muhammad?",
        options: ["Abu Bakr", "Ali ibn Abi Talib", "Khadijah", "Umar ibn al-Khattab"],
        correctAnswer: "Khadijah",
      },
      {
        question: "Which Prophet was known for leading the people of Israel out of Egypt?",
        options: ["Prophet Musa", "Prophet Dawud", "Prophet Isa", "Prophet Muhammad"],
        correctAnswer: "Prophet Musa",
      },
      {
        question: "What is the name of the book given to Prophet Musa?",
        options: ["Injeel", "Tawrat", "Zabur", "Quran"],
        correctAnswer: "Tawrat",
      },
    ];


function Quiz() {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [timer, setTimer] = useState(10); // Total time for each question
    const [selectedOption, setSelectedOption] = useState(null); // Store the selected option
    const [disableButtons, setDisableButtons] = useState(false); // To disable options after answer selection
    const [highlightedOption, setHighlightedOption] = useState(null); // To store the correct option for highlighting
    
    // Update timer and move to the next question when it reaches zero
    useEffect(() => {
        if (timer > 0 && !showScore && !disableButtons) {
        const interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);
        return () => clearInterval(interval); // Clear the interval when component unmounts
        } else if (timer === 0 && !showScore) {
        // Move to next question when timer reaches zero
        handleNextQuestion();
        }
    }, [timer, showScore, disableButtons]);
    
    const handleAnswerOptionClick = (selectedOption) => {
        setSelectedOption(selectedOption); // Store selected option
        setDisableButtons(true); // Disable buttons after selection
    
        // Check if the answer is correct
        if (selectedOption === questions[currentQuestion].correctAnswer) {
        setScore(score + 1);
        }
    
        // Highlight the correct answer in green if the selected option is wrong
        setHighlightedOption(questions[currentQuestion].correctAnswer);
    
        // Wait for a short time before changing the question
        setTimeout(() => {
        handleNextQuestion();
        }, 700); // 0.3-second delay
    };
    
    const handleNextQuestion = () => {
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
        setCurrentQuestion(nextQuestion);
        setSelectedOption(null); // Reset selected option
        setHighlightedOption(null); // Reset highlighted option
        setDisableButtons(false); // Enable buttons again
        setTimer(10); // Reset timer
        } else {
        setShowScore(true); // Show score when all questions are answered
        }
    };
    
    const resetQuiz = () => {
        setCurrentQuestion(0);
        setScore(0);
        setShowScore(false);
        setSelectedOption(null); // Reset selected option
        setHighlightedOption(null); // Reset highlighted option
        setTimer(10); // Reset timer
        setDisableButtons(false); // Enable buttons
    };
    
    return (
        <div className="quiz-container">
        {showScore ? (
            <div className="score-section">
            <h2>
                You scored {score} out of {questions.length}!
            </h2>
            <button onClick={resetQuiz} className="retry-button">
                Retry Quiz
            </button>
            </div>
        ) : (
            <div className="question-section">
            <h3>
                Question {currentQuestion + 1}/{questions.length}
            </h3>
            <p>{questions[currentQuestion].question}</p>
            <div className="options-container">
                {questions[currentQuestion].options.map((option) => (
                <button
                    key={option}
                    onClick={() => handleAnswerOptionClick(option)}
                    className={`option-button ${
                    selectedOption === option
                        ? option === questions[currentQuestion].correctAnswer
                        ? 'correct'
                        : 'incorrect'
                        : ''
                    } ${
                    highlightedOption === option && option !== selectedOption
                        ? 'highlight-correct' // Highlight correct option in green
                        : ''
                    }`}
                    disabled={disableButtons} // Disable buttons after an option is selected
                >
                    {option}
                </button>
                ))}
            </div>
            <div className="timer">
                <p>Time Left: {timer}s</p>
                {/* Progress bar */}
                <progress
                className="timer-progress-bar"
                value={timer}
                max={10}
                ></progress>
            </div>
            </div>
        )}
        </div>
    );
    }
    
    export default Quiz;
    