import React, { useState, useEffect } from 'react';
import './MuslimScientists.css';

const MuslimScientists = () => {
  const [scientists, setScientists] = useState([]);
  const [selectedScientist, setSelectedScientist] = useState(null);

  useEffect(() => {
    const fetchScientists = () => {
      const scientistsData = [
        {
          name: 'Al-Khwarizmi',
          birth: 'c. 780 AD',
          death: 'c. 850 AD',
          placeOfBirth: 'Khwarazm (modern-day Uzbekistan)',
          qualifications: 'Mathematician, Astronomer, Geographer',
          achievements: 'Father of Algebra, introduced Hindu-Arabic numerals to the West.',
          contributions: 'Authored "Kitab al-Mukhtasar fi Hisab al-Jabr wal-Muqabala".',
          image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Al_Khorezmy.jpg/220px-Al_Khorezmy.jpg'
        },
        {
          name: 'Ibn Sina (Avicenna)',
          birth: '980 AD',
          death: '1037 AD',
          placeOfBirth: 'Afshana, near Bukhara (modern-day Uzbekistan)',
          qualifications: 'Physician, Philosopher, Mathematician, Astronomer',
          achievements: 'Pioneer in Medicine, author of "The Canon of Medicine".',
          contributions: 'His work influenced both Islamic and European medical traditions.',
          image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/1950_%22Avicenna%22_stamp_of_Iran.jpg/220px-1950_%22Avicenna%22_stamp_of_Iran.jpg'
        },
        {
            name: "Ibn al-Haytham (Alhazen)",
            birth: "965 AD",
            death: "1040 AD",
            placeOfBirth: "Basra, Iraq",
            qualifications: "Physicist, Mathematician, Astronomer, Engineer",
            achievements: "Founder of Modern Optics, developed theories on light and vision.",
            contributions: "Authored 'Kitab al-Manazir' (Book of Optics), laid the foundation for the scientific method.",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Hazan.png/220px-Hazan.png"
          },
          {
            name: "Jabir ibn Hayyan (Geber)",
            birth: "c. 721 AD",
            death: "c. 815 AD",
            placeOfBirth: "Tus, Iran",
            qualifications: "Chemist, Pharmacist, Engineer, Alchemist",
            achievements: "Pioneer in Alchemy and Chemistry, known as the 'Father of Chemistry'.",
            contributions: "Developed early distillation methods, authored works on acids and chemical compounds.",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Jabir_ibn_Hayyan_Geber%2C_Arabian_alchemist_Wellcome_L0005558.jpg/800px-Jabir_ibn_Hayyan_Geber%2C_Arabian_alchemist_Wellcome_L0005558.jpg"
          },
          {
            name: "Al-Razi (Rhazes)",
            birth: "854 AD",
            death: "925 AD",
            placeOfBirth: "Ray, Iran",
            qualifications: "Physician, Chemist, Philosopher",
            achievements: "Pioneer in Medicine, wrote 'Kitab al-Hawi' (Comprehensive Book on Medicine).",
            contributions: "First to differentiate smallpox from measles, significant contributions to pediatrics and obstetrics.",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Portrait_of_Rhazes_%28al-Razi%29_%28AD_865_-_925%29_Wellcome_L0005053.jpg/800px-Portrait_of_Rhazes_%28al-Razi%29_%28AD_865_-_925%29_Wellcome_L0005053.jpg"
          },
          {
            name: "Omar Khayyam",
            birth: "1048 AD",
            death: "1131 AD",
            placeOfBirth: "Nishapur, Iran",
            qualifications: "Mathematician, Astronomer, Poet, Philosopher",
            achievements: "Developed the Jalali calendar, improved methods for solving cubic equations.",
            contributions: "Authored treatises on algebra and geometry, contributed to astronomy and literature.",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Hakim_Omar_Khayam_-_panoramio.jpg/800px-Hakim_Omar_Khayam_-_panoramio.jpg"
          },
          {
            name: "Nasir al-Din al-Tusi",
            birth: "1201 AD",
            death: "1274 AD",
            placeOfBirth: "Tus, Iran",
            qualifications: "Astronomer, Mathematician, Philosopher",
            achievements: "Developed Tusi-couple (a mathematical device for modeling planetary motion).",
            contributions: "Significant contributions to trigonometry and astronomy, including 'Tadhkira fi Ilm al-Hay’a' (Memoir on Astronomy).",
            image: "https://upload.wikimedia.org/wikipedia/commons/7/75/Nasir_al-Din_Tusi.jpg"
          },
          {
            name: "Al-Biruni",
            birth: "973 AD",
            death: "1048 AD",
            placeOfBirth: "Kath, Khwarazm (modern-day Uzbekistan)",
            qualifications: "Mathematician, Astronomer, Geographer, Historian",
            achievements: "Pioneer in geodesy and comparative religion.",
            contributions: "Authored 'Kitab fi Tahqiq ma li'l-Hind' (Book on India) and significant works on astronomy.",
            image: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Biruni-russian.jpg"
          },
          {
            name: "Al-Farabi",
            birth: "c. 872 AD",
            death: "950 AD",
            placeOfBirth: "Faryab, Khorasan (modern-day Afghanistan)",
            qualifications: "Philosopher, Musician, Scientist",
            achievements: "Known as 'Second Teacher' after Aristotle, laid groundwork for Islamic philosophy.",
            contributions: "Authored 'Kitab al-Musiqa' (Book of Music), contributed to logic, metaphysics, and political philosophy.",
            image: "https://upload.wikimedia.org/wikipedia/commons/3/33/Iranian_Farabi.jpg"
          },
          {
            name: "Ibn Battuta",
            birth: "1304 AD",
            death: "1369 AD",
            placeOfBirth: "Tangier, Morocco",
            qualifications: "Geographer, Explorer, Scholar",
            achievements: "Renowned for his extensive travels across the Islamic world and beyond.",
            contributions: "Documented his journeys in 'Rihla' (The Journey), providing valuable historical and cultural insights.",
            image: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Handmade_oil_painting_reproduction_of_Ibn_Battuta_in_Egypt%2C_a_painting_by_Hippolyte_Leon_Benett..jpg"
          },
          {
            name: "Al-Zahrawi (Abulcasis)",
            birth: "936 AD",
            death: "1013 AD",
            placeOfBirth: "Medina Azahara, near Córdoba, Spain",
            qualifications: "Physician, Surgeon",
            achievements: "Father of Modern Surgery.",
            contributions: "Authored 'Kitab al-Tasrif', a 30-volume medical encyclopedia, pioneering surgical techniques and instruments.",
            image: "https://upload.wikimedia.org/wikipedia/commons/7/77/Al-Zahrawi_Portrait.jpg"
          },
          {
            name: "Ibn Khaldun",
            birth: "1332 AD",
            death: "1406 AD",
            placeOfBirth: "Tunis, Tunisia",
            qualifications: "Historian, Sociologist, Philosopher, Economist",
            achievements: "Pioneer in historiography and sociology.",
            contributions: "Authored 'Muqaddimah', analyzing social, economic, and political factors in historical development.",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Bust_of_Ibn_Khaldun_%28Casbah_of_Bejaia%2C_Algeria%29.jpg/800px-Bust_of_Ibn_Khaldun_%28Casbah_of_Bejaia%2C_Algeria%29.jpg"
          },
          {
            name: "Taqi al-Din",
            birth: "1526 AD",
            death: "1585 AD",
            placeOfBirth: "Damascus, Syria",
            qualifications: "Astronomer, Engineer, Mathematician",
            achievements: "Built one of the most advanced observatories of his time in Istanbul.",
            contributions: "Developed a mechanical astronomical clock, made significant advancements in optics and mechanics.",
            image: "https://i0.wp.com/www.thesciencefaith.com/wp-content/uploads/2019/01/Taqi-Al-Din.jpg?w=309&ssl=1"
          },
          {
            name: "Al-Majriti",
            birth: "c. 950 AD",
            death: "1007 AD",
            placeOfBirth: "Majrit (modern-day Madrid, Spain)",
            qualifications: "Alchemist, Astronomer, Mathematician",
            achievements: "Influential figure in the development of Alchemy and the introduction of Greek and Roman knowledge into the Islamic world.",
            contributions: "Authored works on astrology and alchemy, translated important Greek texts.",
            image: "https://funci.org/wp-content/uploads/2019/06/Maslama.jpg"
          },
          {
            name: "Al-Kindi",
            birth: "c. 801 AD",
            death: "873 AD",
            placeOfBirth: "Kufa, Iraq",
            qualifications: "Philosopher, Mathematician, Scientist",
            achievements: "One of the first to integrate Greek philosophy with Islamic thought.",
            contributions: "Authored over 270 works on a range of subjects including astronomy, logic, medicine, and mathematics.",
            image: "https://upload.wikimedia.org/wikipedia/commons/7/7d/Stamp_IQ_1962_6f.jpg"
          },
          {
            name: "Ibn al-Nafis",
            birth: "1213 AD",
            death: "1288 AD",
            placeOfBirth: "Damascus, Syria",
            qualifications: "Physician, Philosopher",
            achievements: "First to describe the pulmonary circulation of the blood.",
            contributions: "Authored 'Al-Shamil fi al-Tibb', a comprehensive medical encyclopedia, and contributed to the understanding of human anatomy and medicine.",
            image: "https://upload.wikimedia.org/wikipedia/commons/d/da/Ibn_Al_Nafis_statue.jpg"
          },
        //   {
        //     name: "Al-Sufi",
        //     birth: "903 AD",
        //     death: "986 AD",
        //     placeOfBirth: "Ray, Iran",
        //     qualifications: "Astronomer, Mathematician",
        //     achievements: "Contributed significantly to the development of star catalogues and astronomy.",
        //     contributions: "His work, 'Book of Fixed Stars', contained detailed descriptions of stars and their positions in the sky.",
        //     image: ""
        //   },
          {
            name: "Al-Battani",
            birth: "858 AD",
            death: "929 AD",
            placeOfBirth: "Harran, Syria",
            qualifications: "Astronomer, Mathematician",
            achievements: "Refined Ptolemaic astronomy and improved the accuracy of astronomical tables.",
            contributions: "His 'Kitab al-Zij' became one of the most influential astronomical works in the Islamic world.",
            image: "https://mathshistory.st-andrews.ac.uk/Biographies/Al-Battani/al_battani_1.jpg"
          },
          {
            name: "Muhammad al-Fasi",
            birth: "1270 AD",
            death: "1331 AD",
            placeOfBirth: "Fes, Morocco",
            qualifications: "Mathematician, Astronomer",
            achievements: "Significant contributions to the study of astronomy and mathematics.",
            contributions: "His works on astronomy were highly regarded in both the Islamic and Western worlds.",
            image: "https://www.creativefabrica.com/wp-content/uploads/2023/04/05/Astronomy-Icon-Outline-Solar-System-Graphics-66266992-1-1-580x387.jpg"
          },
          {
            name: "Al-Rahman al-Sufi",
            birth: "903 AD",
            death: "986 AD",
            placeOfBirth: "Ray, Iran",
            qualifications: "Astronomer, Mathematician",
            achievements: "Contributed to star cataloging and the advancement of astronomy.",
            contributions: "Authored 'Book of Fixed Stars', offering detailed observations of the stars and their positions.",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Al-Sufi_depicted_in_Albrecht_D%C3%BCrers_%27The_Northern_Celestial_Hemisphere%27_%281515%29.jpg/800px-Al-Sufi_depicted_in_Albrecht_D%C3%BCrers_%27The_Northern_Celestial_Hemisphere%27_%281515%29.jpg"
          },
          {
            name: "Ibn Zuhr (Avenzoar)",
            birth: "1091 AD",
            death: "1161 AD",
            placeOfBirth: "Seville, Spain",
            qualifications: "Physician, Surgeon, Philosopher",
            achievements: "Pioneering figure in medicine, especially in surgery and pharmacology.",
            contributions: "Authored 'Kitab al-Taisir' (The Book of Simplification), a significant medical work.",
            image: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Ibn_Zuhr%2C_Sayr_mulhimah_min_al-Sharq_wa-al-Gharb.png"
          },
          {
            name: "Al-Karaji",
            birth: "c. 953 AD",
            death: "1029 AD",
            placeOfBirth: "Karaj, Iran",
            qualifications: "Mathematician, Engineer",
            achievements: "Made significant contributions to algebra and civil engineering.",
            contributions: "Pioneered the use of the decimal system in algebra, and authored 'Al-Fakhri' (The Book of Perfection).",
            image: "https://mathshistory.st-andrews.ac.uk/Biographies/Al-Karaji/al-karaji_1.jpg"
          },
          {
            name: "Ibn al-Jazari",
            birth: "1136 AD",
            death: "1206 AD",
            placeOfBirth: "Al-Jazira, Mesopotamia",
            qualifications: "Engineer, Mathematician, Inventor",
            achievements: "Invented several mechanical devices and is known for his work on automata.",
            contributions: "Authored 'The Book of Knowledge of Ingenious Mechanical Devices' which described a variety of mechanical devices.",
            image: "https://science4fun.info/wp-content/uploads/2020/02/Al-Jazari.jpg"
          },
          {
            name: "Al-Idrisi",
            birth: "1100 AD",
            death: "1165 AD",
            placeOfBirth: "Ceuta, Spain",
            qualifications: "Geographer, Cartographer",
            achievements: "Created one of the most advanced world maps of the medieval period.",
            contributions: "Authored 'Nuzhat al-Mushtaq fi Ikhtiraq al-Afaq' (Pleasure of Him Who Longs to Traverse the Horizons), an influential geography book.",
            image: "https://pbs.twimg.com/media/Eww_ojwXEAYc936.jpg"
          },
          {
            name: "Ibn Duraid",
            birth: "c. 836 AD",
            death: "912 AD",
            placeOfBirth: "Basra, Iraq",
            qualifications: "Linguist, Grammarian, Poet",
            achievements: "Contributed significantly to the study of Arabic grammar and lexicography.",
            contributions: "Authored 'Al-Jamhara', a dictionary of the Arabic language.",
            image: "https://tinyurl.com/Duraidassa"
          },
          {
            name: "Rumi",
            birth: "1207 AD",
            death: "1273 AD",
            placeOfBirth: "Balkh, Afghanistan",
            qualifications: "Poet, Theologian, Philosopher",
            achievements: "One of the most influential figures in Sufism, known for his spiritual poetry.",
            contributions: "Authored 'Mathnawi', a six-volume poetic work exploring themes of love, divine unity, and mysticism.",
            image: "https://tinyurl.com/rumipicd"
          },
          {
            name: "Al-Mu'tazila",
            birth: "c. 8th century AD",
            death: "Unknown",
            placeOfBirth: "Basra, Iraq",
            qualifications: "Philosopher, Theologian",
            achievements: "A group of scholars who promoted rationalism and independent reasoning in Islamic theology.",
            contributions: "They contributed to Islamic philosophy, particularly through the development of the school of thought called 'Mu'tazilism'.",
            image: "https://almuslih.org/wp-content/uploads/2023/08/Scholar.jpeg"
          },
          {
            name: "Al-Bukhari",
            birth: "810 AD",
            death: "870 AD",
            placeOfBirth: "Bukhara, Uzbekistan",
            qualifications: "Hadith Scholar",
            achievements: "Compiler of one of the most authentic collections of Hadith (sayings of the Prophet Muhammad).",
            contributions: "Authored 'Sahih al-Bukhari', regarded as the most reliable collection of Hadith in Sunni Islam.",
            image: "https://scontent.fbbi9-1.fna.fbcdn.net/v/t39.30808-6/463867569_8231038097006849_2265917284755854807_n.jpg?_nc_cat=100&ccb=1-7&_nc_sid=0b6b33&_nc_ohc=mqdKhR9TV_oQ7kNvgFcFuEu&_nc_zt=23&_nc_ht=scontent.fbbi9-1.fna&_nc_gid=AL6c2HzmGYYhAp1DL1XRyWS&oh=00_AYDf0bmv6q-fJTgULGLD0WheXqNJl-c6NsRn7tlFDtgMTw&oe=6751FF4D"
          },
          {
            name: "Ibn Arabi",
            birth: "1165 AD",
            death: "1240 AD",
            placeOfBirth: "Murcia, Spain",
            qualifications: "Philosopher, Mystic, Poet",
            achievements: "Influential figure in Sufism, developed theories on divine unity and the nature of existence.",
            contributions: "Authored numerous mystical works, including 'Fusus al-Hikam' (The Bezels of Wisdom).",
            image: "https://upload.wikimedia.org/wikipedia/commons/e/e9/Ibn_Arabi.jpg"
          },
          {
            name: "Abu Mansur al-Baghdadi",
            birth: "c. 985 AD",
            death: "1037 AD",
            placeOfBirth: "Baghdad, Iraq",
            qualifications: "Mathematician, Astronomer, Engineer",
            achievements: "Pioneer in optics and mathematics, known for his work on geometrical optics.",
            contributions: "Contributed to the development of the science of light and vision, and wrote on mathematical topics.",
            image: "https://thumbs.dreamstime.com/b/math-symbol-compass-line-icon-geometry-sign-isolated-white-background-337568806.jpg"
          },
        //   {
        //     name: "Al-Tusi",
        //     birth: "1201 AD",
        //     death: "1274 AD",
        //     placeOfBirth: "Tus, Iran",
        //     qualifications: "Astronomer, Mathematician, Philosopher",
        //     achievements: "Made significant contributions to astronomy, mathematics, and logic.",
        //     contributions: "Developed the Tusi-couple to explain planetary motion and worked on trigonometric functions.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Nasir_al-Din_al-Tusi.jpg/220px-Nasir_al-Din_al-Tusi.jpg"
        //   },
        //   {
        //     name: "Ibn Jareer al-Tabari",
        //     birth: "838 AD",
        //     death: "923 AD",
        //     placeOfBirth: "Amol, Iran",
        //     qualifications: "Historian, Jurist, Scholar",
        //     achievements: "One of the earliest and most renowned historians in the Islamic world.",
        //     contributions: "Authored the 'History of the Prophets and Kings', one of the most important historical texts in Islamic literature.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Ibn_Jareer.jpg/220px-Ibn_Jareer.jpg"
        //   },
        //   {
        //     name: "Al-Farghani",
        //     birth: "c. 805 AD",
        //     death: "880 AD",
        //     placeOfBirth: "Farghanah, Central Asia",
        //     qualifications: "Astronomer, Mathematician",
        //     achievements: "Made substantial contributions to astronomy, particularly in the field of planetary motion.",
        //     contributions: "His work 'Elements of Astronomy' became a key reference in both the Islamic world and later in Europe.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Al_Farghani.jpg/220px-Al_Farghani.jpg"
        //   },
        //   {
        //     name: "Al-Khushani",
        //     birth: "c. 1000 AD",
        //     death: "1077 AD",
        //     placeOfBirth: "Bukhara, Uzbekistan",
        //     qualifications: "Mathematician, Engineer",
        //     achievements: "Contributed to mathematical theory and engineering.",
        //     contributions: "Known for his work on geometry and for writing about the construction of bridges and other civil engineering projects.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Al-Khushani.jpg/220px-Al-Khushani.jpg"
        //   },
        //   {
        //     name: "Ibn Hajar al-Asqalani",
        //     birth: "1372 AD",
        //     death: "1449 AD",
        //     placeOfBirth: "Asqalan, Palestine",
        //     qualifications: "Hadith Scholar, Historian",
        //     achievements: "One of the most famous scholars in Islamic history for his work in Hadith and Islamic jurisprudence.",
        //     contributions: "Authored 'Fath al-Bari', a detailed commentary on Sahih al-Bukhari.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Ibn_Hajar_al-Asqalani.jpg/220px-Ibn_Hajar_al-Asqalani.jpg"
        //   },
        //   {
        //     name: "Al-Mansur",
        //     birth: "714 AD",
        //     death: "775 AD",
        //     placeOfBirth: "Harran, Iraq",
        //     qualifications: "Caliph, Patron of Sciences",
        //     achievements: "Patronized scientific research and scholarship, making Baghdad a center of learning.",
        //     contributions: "Contributed to the establishment of the House of Wisdom in Baghdad, a major intellectual center in the Islamic Golden Age.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Al-Mansur.jpg/220px-Al-Mansur.jpg"
        //   },
        //   {
        //     name: "Ibn Tufayl",
        //     birth: "c. 1105 AD",
        //     death: "1185 AD",
        //     placeOfBirth: "Ghazula, Morocco",
        //     qualifications: "Philosopher, Physician",
        //     achievements: "Known for his philosophical novel 'Hayy ibn Yaqzan', which explored the relationship between philosophy and religion.",
        //     contributions: "His ideas influenced both Islamic and European thought, especially in the areas of epistemology and the philosophy of nature.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1d/Ibn_Tufail.jpg/220px-Ibn_Tufail.jpg"
        //   },
        //   {
        //     name: "Ibn al-Shatir",
        //     birth: "1304 AD",
        //     death: "1375 AD",
        //     placeOfBirth: "Damascus, Syria",
        //     qualifications: "Astronomer, Mathematician",
        //     achievements: "Improved Ptolemaic astronomy, and made significant contributions to the field of planetary motion.",
        //     contributions: "His work influenced later European astronomers, including Copernicus, especially with his heliocentric models.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Ibn_al-Shatir.jpg/220px-Ibn_al-Shatir.jpg"
        //   },
        //   {
        //     name: "Ibn Qayyim al-Jawziyya",
        //     birth: "1292 AD",
        //     death: "1350 AD",
        //     placeOfBirth: "Damascus, Syria",
        //     qualifications: "Theologian, Jurist, Philosopher",
        //     achievements: "One of the greatest Islamic scholars, renowned for his writings on theology and Islamic law.",
        //     contributions: "Authored numerous works on Islamic law, ethics, and medicine, including 'Zad al-Ma'ad' on prophetic medicine.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Ibn_Qayyim.jpg/220px-Ibn_Qayyim.jpg"
        //   },
        //   {
        //     name: "Al-Nasir al-Din al-Tusi",
        //     birth: "1201 AD",
        //     death: "1274 AD",
        //     placeOfBirth: "Tus, Iran",
        //     qualifications: "Astronomer, Mathematician, Philosopher",
        //     achievements: "Developed the Tusi-couple and contributed to the development of trigonometry and planetary models.",
        //     contributions: "His astronomical and mathematical work significantly influenced both Islamic and European scholars.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Nasir_al-Din_al-Tusi.jpg/220px-Nasir_al-Din_al-Tusi.jpg"
        //   },
        //   {
        //     name: "Abu Ma'shar",
        //     birth: "c. 787 AD",
        //     death: "886 AD",
        //     placeOfBirth: "Balkh, Afghanistan",
        //     qualifications: "Astrologer, Mathematician",
        //     achievements: "Known as the 'father of Islamic astrology', he made significant contributions to astronomy and astrology.",
        //     contributions: "His work on astrology greatly influenced both Islamic and European scholars during the medieval period.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/73/Abu_Mashar.jpg/220px-Abu_Mashar.jpg"
        //   },
        //   {
        //     name: "Al-Farabi",
        //     birth: "c. 872 AD",
        //     death: "950 AD",
        //     placeOfBirth: "Farab, Kazakhstan",
        //     qualifications: "Philosopher, Mathematician",
        //     achievements: "A renowned figure in Islamic philosophy and one of the greatest scholars of the medieval period.",
        //     contributions: "Contributed to logic, ethics, and metaphysics, and wrote commentaries on Aristotle and Plato.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Al-Farabi.jpg/220px-Al-Farabi.jpg"
        //   },
        //   {
        //     name: "Ibn Khaldun",
        //     birth: "1332 AD",
        //     death: "1406 AD",
        //     placeOfBirth: "Tunis, Tunisia",
        //     qualifications: "Historian, Sociologist, Economist",
        //     achievements: "Founder of the science of sociology and historiography.",
        //     contributions: "Authored 'Muqaddimah', a groundbreaking work that laid the foundation for modern sociology and the study of history.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Ibn_Khaldun.jpg/220px-Ibn_Khaldun.jpg"
        //   },
        //   {
        //     name: "Al-Dinawari",
        //     birth: "828 AD",
        //     death: "896 AD",
        //     placeOfBirth: "Dinawar, Iraq",
        //     qualifications: "Botanist, Historian, Mathematician",
        //     achievements: "One of the first Muslim scientists to study plant biology systematically.",
        //     contributions: "Authored 'Kitab al-Nabat' (Book of Plants), which provided valuable insights into botany.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/23/Al-Dinawari.jpg/220px-Al-Dinawari.jpg"
        //   },
        //   {
        //     name: "Al-Razi (Rhazes)",
        //     birth: "865 AD",
        //     death: "925 AD",
        //     placeOfBirth: "Ray, Iran",
        //     qualifications: "Physician, Chemist",
        //     achievements: "Made contributions to medicine, chemistry, and pharmacology.",
        //     contributions: "His work 'Kitab al-Hawi' was one of the most comprehensive medical texts in the medieval world, and he is credited with the discovery of sulfuric acid.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Al-Razi_3.jpg/220px-Al-Razi_3.jpg"
        //   },
        //   {
        //     name: "Al-Mu’ayyad al-Shirazi",
        //     birth: "c. 1000 AD",
        //     death: "1077 AD",
        //     placeOfBirth: "Shiraz, Iran",
        //     qualifications: "Mathematician, Philosopher, Astronomer",
        //     achievements: "Significant contributions to mathematics, astronomy, and philosophy.",
        //     contributions: "Known for his writings on astronomy and his development of geometric and mathematical theories.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Al-Mu%27ayyad_al-Shirazi.jpg/220px-Al-Mu%27ayyad_al-Shirazi.jpg"
        //   },
        //   {
        //     name: "Ibn Al-Baitar",
        //     birth: "1197 AD",
        //     death: "1248 AD",
        //     placeOfBirth: "Malaga, Spain",
        //     qualifications: "Botanist, Pharmacologist",
        //     achievements: "One of the greatest botanists of the medieval Islamic world.",
        //     contributions: "Authored 'Kitab al-Jami' which cataloged over 1,400 plants and medicinal substances.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Ibn_Al-Baitar.jpg/220px-Ibn_Al-Baitar.jpg"
        //   },
        //   {
        //     name: "Al-Kindi",
        //     birth: "c. 801 AD",
        //     death: "873 AD",
        //     placeOfBirth: "Kufa, Iraq",
        //     qualifications: "Philosopher, Mathematician, Scientist",
        //     achievements: "Known as the 'Philosopher of the Arabs', he made important contributions to logic and philosophy.",
        //     contributions: "Wrote on various topics such as optics, geometry, astronomy, and medicine, and sought to integrate Greek philosophy with Islamic thought.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/28/Al_Kindi.jpg/220px-Al_Kindi.jpg"
        //   },
        //   {
        //     name: "Al-Battani",
        //     birth: "858 AD",
        //     death: "929 AD",
        //     placeOfBirth: "Harran, Syria",
        //     qualifications: "Astronomer, Mathematician",
        //     achievements: "Refined the Ptolemaic system of astronomy and made precise calculations regarding the length of the year.",
        //     contributions: "His astronomical tables were used by both Islamic and European astronomers.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Al-Battani.jpg/220px-Al-Battani.jpg"
        //   },
        //   {
        //     name: "Ibn Abi Usaybi'a",
        //     birth: "1203 AD",
        //     death: "1270 AD",
        //     placeOfBirth: "Damascus, Syria",
        //     qualifications: "Physician, Historian",
        //     achievements: "Famous for his writings on the history of medicine and physicians.",
        //     contributions: "His work 'Uyun al-Anba fi Tabaqat al-Atibba' is a valuable historical source on the lives of physicians.",
        //     image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Ibn_Abi_Usaybia.jpg/220px-Ibn_Abi_Usaybia.jpg"
        //   },
        // Add more scientists as needed...
      ];
      setScientists(scientistsData);
    };

    fetchScientists();
  }, []);

  const handleScientistClick = (scientist) => {
    setSelectedScientist(scientist);
  };

  const closeScientistPopup = () => {
    setSelectedScientist(null);
  };

  return (
    <div className="scientists-container">
      <h2>Notable Muslim Scientists</h2>
      <div className="scientist-list">
        {scientists.map((scientist, index) => (
          <div
            key={index}
            className="scientist-item"
            onClick={() => handleScientistClick(scientist)}
          >
            <img
              src={scientist.image}
              className="scientist-image"
            />

            <h3>{scientist.name}</h3>
            
            <p>{scientist.qualifications}</p>
          </div>
        ))}
      </div>

      {selectedScientist && (
        <div className="popup">
          <div className="popup-content">
            <h3>{selectedScientist.name}</h3>
            
            <p><strong>Date of Birth:</strong> {selectedScientist.birth}</p>
            <p><strong>Place of Birth:</strong> {selectedScientist.placeOfBirth}</p>
            <p><strong>Date of Death:</strong> {selectedScientist.death}</p>
            <p><strong>Qualifications:</strong> {selectedScientist.qualifications}</p>
            <p><strong>Achievements:</strong> {selectedScientist.achievements}</p>
            <p><strong>Contributions:</strong> {selectedScientist.contributions}</p>
            <button className="close-button" onClick={closeScientistPopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MuslimScientists;
