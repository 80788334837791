// import React from 'react';
import React, { useState } from 'react';
import './IslamicBabyNames.css'; // Ensure this CSS file is created

const babyNames = [
  { name: 'Aariz', urduName: 'عارض', meaning: 'Respectable man, leader', gender: 'Male', origin: 'Arabic' },
  { name: 'Aasma', urduName: 'آسمہ', meaning: 'Sky, exalted', gender: 'Female', origin: 'Arabic' },
  { name: 'Aayan', urduName: 'آیان', meaning: 'Gift of God', gender: 'Male', origin: 'Arabic' },
  { name: 'Abeer', urduName: 'عبیر', meaning: 'Fragrance', gender: 'Female', origin: 'Arabic' },
  { name: 'Abid', urduName: 'عابد', meaning: 'Devout worshipper', gender: 'Male', origin: 'Arabic' },
  { name: 'Adeeba', urduName: 'ادیبہ', meaning: 'Polite, cultured', gender: 'Female', origin: 'Arabic' },
  { name: 'Adil', urduName: 'عادل', meaning: 'Fair, honest', gender: 'Male', origin: 'Arabic' },
  { name: 'Afifa', urduName: 'عفیفہ', meaning: 'Chaste, modest', gender: 'Female', origin: 'Arabic' },
  { name: 'Afzal', urduName: 'افضل', meaning: 'Better, superior', gender: 'Male', origin: 'Arabic' },
  { name: 'Ahad', urduName: 'احد', meaning: 'One, unique', gender: 'Male', origin: 'Arabic' },
  { name: 'Aiman', urduName: 'ایمن', meaning: 'Blessed, fearless', gender: 'Female', origin: 'Arabic' },
  { name: 'Aisha', urduName: 'عائشہ', meaning: 'Living, prosperous', gender: 'Female', origin: 'Arabic' },
  { name: 'Ameer', urduName: 'امیر', meaning: 'Prince, leader', gender: 'Male', origin: 'Arabic' },
  { name: 'Ameen', urduName: 'امین', meaning: 'Faithful, trustworthy', gender: 'Male', origin: 'Arabic' },
  { name: 'Amira', urduName: 'امیرہ', meaning: 'Princess', gender: 'Female', origin: 'Arabic' },
  { name: 'Anabia', urduName: 'عنابیہ', meaning: 'Turn towards Allah', gender: 'Female', origin: 'Arabic' },
  { name: 'Anas', urduName: 'انس', meaning: 'Friendliness', gender: 'Male', origin: 'Arabic' },
  { name: 'Anaya', urduName: 'عنایہ', meaning: 'Caring', gender: 'Female', origin: 'Arabic' },
  { name: 'Areeb', urduName: 'اریب', meaning: 'Skillful, wise', gender: 'Male', origin: 'Arabic' },
  { name: 'Arisha', urduName: 'عریشہ', meaning: 'Highness', gender: 'Female', origin: 'Arabic' },
  { name: 'Arman', urduName: 'ارمان', meaning: 'Desire, hope', gender: 'Male', origin: 'Persian' },
  { name: 'Asad', urduName: 'اسد', meaning: 'Lion', gender: 'Male', origin: 'Arabic' },
  { name: 'Asifa', urduName: 'آصفہ', meaning: 'Forgiveness', gender: 'Female', origin: 'Arabic' },
  { name: 'Asim', urduName: 'عاصم', meaning: 'Protector', gender: 'Male', origin: 'Arabic' },
  { name: 'Asma', urduName: 'اسماء', meaning: 'Excellent, lofty', gender: 'Female', origin: 'Arabic' },
  { name: 'Awais', urduName: 'اويس', meaning: 'Gifted', gender: 'Male', origin: 'Arabic' },
  { name: 'Azan', urduName: 'اذان', meaning: 'Call to prayer', gender: 'Male', origin: 'Arabic' },
  { name: 'Azhar', urduName: 'اظہر', meaning: 'Shining, luminous', gender: 'Male', origin: 'Arabic' },
  { name: 'Aziza', urduName: 'عزیزہ', meaning: 'Precious', gender: 'Female', origin: 'Arabic' },
  { name: 'Azlan', urduName: 'اذلان', meaning: 'Lion', gender: 'Male', origin: 'Persian' },
  { name: 'Aabroo', urduName: 'آبرو', meaning: 'Honor, prestige', gender: 'Female', origin: 'Persian' },
  { name: 'Aatika', urduName: 'عاطقہ', meaning: 'Generous, kind-hearted', gender: 'Female', origin: 'Arabic' },
  { name: 'Aqeel', urduName: 'عقيل', meaning: 'Wise, intelligent', gender: 'Male', origin: 'Arabic' },
  { name: 'Areesha', urduName: 'عریشہ', meaning: 'Under an umbrella, roof', gender: 'Female', origin: 'Arabic' },
  { name: 'Azhaan', urduName: 'اذہان', meaning: 'Intelligence, wit', gender: 'Male', origin: 'Arabic' },
  { name: 'Ammar', urduName: 'عمار', meaning: 'Builder, constructor', gender: 'Male', origin: 'Arabic' },
  { name: 'Aqsa', urduName: 'اقصی', meaning: 'Farthest, ultimate', gender: 'Female', origin: 'Arabic' },
  { name: 'Ayat', urduName: 'آیت', meaning: 'Sign, miracle', gender: 'Female', origin: 'Arabic' },
  { name: 'Anwar', urduName: 'انوار', meaning: 'Radiant, luminous', gender: 'Male', origin: 'Arabic' },
  { name: 'Aysha', urduName: 'عائشہ', meaning: 'Living, prosperous', gender: 'Female', origin: 'Arabic' },  
{ name: 'Azra', urduName: 'عذرا', meaning: 'Virgin, pure', gender: 'Female', origin: 'Arabic' },
{ name: 'Ayla', urduName: 'آیلا', meaning: 'Halo of light around the moon', gender: 'Female', origin: 'Turkish' },
{ name: 'Ayub', urduName: 'ایوب', meaning: 'A Prophet’s name (Job)', gender: 'Male', origin: 'Arabic' },
{ name: 'Ayman', urduName: 'ایمن', meaning: 'Blessed, lucky', gender: 'Male', origin: 'Arabic' },
{ name: 'Areefa', urduName: 'عارفہ', meaning: 'Wise, intelligent', gender: 'Female', origin: 'Arabic' },
{ name: 'Arham', urduName: 'ارحم', meaning: 'Merciful, kind', gender: 'Male', origin: 'Arabic' },
{ name: 'Atif', urduName: 'عاطف', meaning: 'Compassionate, affectionate', gender: 'Male', origin: 'Arabic' },
{ name: 'Atiya', urduName: 'عطیہ', meaning: 'Gift, present', gender: 'Female', origin: 'Arabic' },
{ name: 'Ayyan', urduName: 'ایان', meaning: 'God’s gift', gender: 'Male', origin: 'Arabic' },
{ name: 'Anwar', urduName: 'انور', meaning: 'Radiant, luminous', gender: 'Male', origin: 'Arabic' },
{ name: 'Asadullah', urduName: 'اسد اللہ', meaning: 'Lion of Allah', gender: 'Male', origin: 'Arabic' },
{ name: 'Aysha', urduName: 'عائشہ', meaning: 'Living, prosperous', gender: 'Female', origin: 'Arabic' },
{ name: 'Adeel', urduName: 'عدیل', meaning: 'Justice, fairness', gender: 'Male', origin: 'Arabic' },
{ name: 'Ashar', urduName: 'اشعر', meaning: 'Wise, knowledgeable', gender: 'Male', origin: 'Arabic' },
{ name: 'Aabha', urduName: 'آبھا', meaning: 'Glow, light', gender: 'Female', origin: 'Sanskrit' },
{ name: 'Aabroo', urduName: 'آبرو', meaning: 'Honor, prestige', gender: 'Female', origin: 'Persian' },
{ name: 'Aatika', urduName: 'عاطقہ', meaning: 'Generous, kind-hearted', gender: 'Female', origin: 'Arabic' },
{ name: 'Aqeel', urduName: 'عقيل', meaning: 'Wise, intelligent', gender: 'Male', origin: 'Arabic' },
{ name: 'Areesha', urduName: 'عریشہ', meaning: 'Under an umbrella, roof', gender: 'Female', origin: 'Arabic' },
{ name: 'Azhaan', urduName: 'اذہان', meaning: 'Intelligence, wit', gender: 'Male', origin: 'Arabic' },
{ name: 'Ammar', urduName: 'عمار', meaning: 'Builder, constructor', gender: 'Male', origin: 'Arabic' },
{ name: 'Ayesha', urduName: 'عائشہ', meaning: 'Lively, woman life', gender: 'Female', origin: 'Arabic' },
{ name: 'Aqsa', urduName: 'اقصی', meaning: 'Farthest, ultimate', gender: 'Female', origin: 'Arabic' },
{ name: 'Ayat', urduName: 'آیت', meaning: 'Sign, miracle', gender: 'Female', origin: 'Arabic' },
{ name: 'Azaan', urduName: 'اذان', meaning: 'Call to prayer', gender: 'Male', origin: 'Arabic' },
{ name: 'Abeer', urduName: 'عبیر', meaning: 'Perfume, fragrance', gender: 'Female', origin: 'Arabic' },
{ name: 'Aneeq', urduName: 'انیق', meaning: 'Unique, elegant', gender: 'Male', origin: 'Arabic' },
{ name: 'Ansha', urduName: 'انشاء', meaning: 'She created', gender: 'Female', origin: 'Arabic' },
{ name: 'Aatifa', urduName: 'عاطفہ', meaning: 'Affection, kindness', gender: 'Female', origin: 'Arabic' },
{ name: 'Arwa', urduName: 'اروی', meaning: 'Satisfied, pleasant', gender: 'Female', origin: 'Arabic' },
{ name: 'Azfar', urduName: 'اظفر', meaning: 'Victorious, triumphant', gender: 'Male', origin: 'Arabic' },
{ name: 'Anwarul', urduName: 'انوار', meaning: 'Lights, radiance', gender: 'Male', origin: 'Arabic' },
{ name: 'Aabirah', urduName: 'عابره', meaning: 'Fleeting, ephemeral', gender: 'Female', origin: 'Arabic' },
{ name: 'Aalimah', urduName: 'عالمہ', meaning: 'Learned, scholar', gender: 'Female', origin: 'Arabic' },
{ name: 'Aarib', urduName: 'عارب', meaning: 'Handsome, healthy', gender: 'Male', origin: 'Arabic' },
{ name: 'Aatif', urduName: 'عاطف', meaning: 'Kind, affectionate', gender: 'Male', origin: 'Arabic' },
{ name: 'Aabirah', urduName: 'عابرہ', meaning: 'Fleeting, transitory', gender: 'Female', origin: 'Arabic' },
{ name: 'Azim', urduName: 'عظیم', meaning: 'Great, magnificent', gender: 'Male', origin: 'Arabic' },
{ name: 'Aziza', urduName: 'عزیزہ', meaning: 'Cherished, beloved', gender: 'Female', origin: 'Arabic' },

    { name: 'Aaban', urduName: 'ابان', meaning: 'Clear, lucid', gender: 'Male', origin: 'Arabic' },
    { name: 'Aabdar', urduName: 'آبدار', meaning: 'Moonlight, early', gender: 'Male', origin: 'Persian' },
    { name: 'Aabid', urduName: 'عابد', meaning: 'Worshipper', gender: 'Male', origin: 'Arabic' },
    { name: 'Aadab', urduName: 'آداب', meaning: 'Hope, need', gender: 'Female', origin: 'Arabic' },
    { name: 'Aadil', urduName: 'عادل', meaning: 'Just, upright', gender: 'Male', origin: 'Arabic' },
    { name: 'Aafiya', urduName: 'عافیہ', meaning: 'Health, free from illness', gender: 'Female', origin: 'Arabic' },
    { name: 'Aahil', urduName: 'آہل', meaning: 'Prince', gender: 'Male', origin: 'Arabic' },
    { name: 'Aaila', urduName: 'عائلہ', meaning: 'Beautiful, family', gender: 'Female', origin: 'Arabic' },
    { name: 'Aalim', urduName: 'عالم', meaning: 'Scholar', gender: 'Male', origin: 'Arabic' },
    { name: 'Aariz', urduName: 'عارض', meaning: 'Respectable man', gender: 'Male', origin: 'Arabic' },
    { name: 'Aasma', urduName: 'آسمہ', meaning: 'Sky, exalted', gender: 'Female', origin: 'Arabic' },
    { name: 'Aayan', urduName: 'آیان', meaning: 'Gift of God', gender: 'Male', origin: 'Arabic' },
    { name: 'Abeer', urduName: 'عبیر', meaning: 'Fragrance', gender: 'Female', origin: 'Arabic' },
    { name: 'Abid', urduName: 'عابد', meaning: 'Devout worshipper', gender: 'Male', origin: 'Arabic' },
    { name: 'Adeeba', urduName: 'ادیبہ', meaning: 'Polite, cultured', gender: 'Female', origin: 'Arabic' },
    { name: 'Adil', urduName: 'عادل', meaning: 'Fair, honest', gender: 'Male', origin: 'Arabic' },
    { name: 'Afifa', urduName: 'عفیفہ', meaning: 'Chaste, modest', gender: 'Female', origin: 'Arabic' },
    { name: 'Afzal', urduName: 'افضل', meaning: 'Better, superior', gender: 'Male', origin: 'Arabic' },
    { name: 'Ahad', urduName: 'احد', meaning: 'One, unique', gender: 'Male', origin: 'Arabic' },
    { name: 'Aiman', urduName: 'ایمن', meaning: 'Blessed, fearless', gender: 'Female', origin: 'Arabic' },
    { name: 'Aisha', urduName: 'عائشہ', meaning: 'Living, prosperous', gender: 'Female', origin: 'Arabic' },
    { name: 'Ameer', urduName: 'امیر', meaning: 'Prince, leader', gender: 'Male', origin: 'Arabic' },
    { name: 'Ameen', urduName: 'امین', meaning: 'Faithful, trustworthy', gender: 'Male', origin: 'Arabic' },
    { name: 'Amira', urduName: 'امیرہ', meaning: 'Princess', gender: 'Female', origin: 'Arabic' },
    { name: 'Anabia', urduName: 'عنابیہ', meaning: 'Turn towards Allah', gender: 'Female', origin: 'Arabic' },
    { name: 'Anas', urduName: 'انس', meaning: 'Friendliness', gender: 'Male', origin: 'Arabic' },
    { name: 'Anaya', urduName: 'عنایہ', meaning: 'Caring', gender: 'Female', origin: 'Arabic' },
    { name: 'Areeb', urduName: 'اریب', meaning: 'Skillful, wise', gender: 'Male', origin: 'Arabic' },
    { name: 'Arisha', urduName: 'عریشہ', meaning: 'Highness', gender: 'Female', origin: 'Arabic' },
    { name: 'Arman', urduName: 'ارمان', meaning: 'Desire, hope', gender: 'Male', origin: 'Persian' },
    { name: 'Asad', urduName: 'اسد', meaning: 'Lion', gender: 'Male', origin: 'Arabic' },
    { name: 'Asifa', urduName: 'آصفہ', meaning: 'Forgiveness', gender: 'Female', origin: 'Arabic' },
    { name: 'Asim', urduName: 'عاصم', meaning: 'Protector', gender: 'Male', origin: 'Arabic' },
    { name: 'Asma', urduName: 'اسماء', meaning: 'Excellent, lofty', gender: 'Female', origin: 'Arabic' },
    { name: 'Awais', urduName: 'اويس', meaning: 'Gifted', gender: 'Male', origin: 'Arabic' },
    { name: 'Azan', urduName: 'اذان', meaning: 'Call to prayer', gender: 'Male', origin: 'Arabic' },
    { name: 'Azhar', urduName: 'اظہر', meaning: 'Shining, luminous', gender: 'Male', origin: 'Arabic' },
    { name: 'Aziza', urduName: 'عزیزہ', meaning: 'Precious', gender: 'Female', origin: 'Arabic' },
    { name: 'Azlan', urduName: 'اذلان', meaning: 'Lion', gender: 'Male', origin: 'Persian' },  
    { name: 'Aaliyah', urduName: 'عالیہ', meaning: 'Exalted, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Aamir', urduName: 'عامر', meaning: 'Prosperous, full of life', gender: 'Male', origin: 'Arabic' },
    { name: 'Amina', urduName: 'امینہ', meaning: 'Trustworthy, faithful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ali', urduName: 'علی', meaning: 'Exalted, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Amir', urduName: 'امیر', meaning: 'Prince, leader', gender: 'Male', origin: 'Arabic' },
    { name: 'Bilal', urduName: 'بلال', meaning: 'Moisture; the name of the Prophet’s muezzin', gender: 'Male', origin: 'Arabic' },
    { name: 'Dalia', urduName: 'دالیہ', meaning: 'Gentle, slender', gender: 'Female', origin: 'Arabic' },
    { name: 'Fatima', urduName: 'فاطمہ', meaning: 'Captivating, one who abstains', gender: 'Female', origin: 'Arabic' },
    { name: 'Farah', urduName: 'فرح', meaning: 'Joy, happiness', gender: 'Female', origin: 'Arabic' },
    { name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
    { name: 'Hadi', urduName: 'ہادی', meaning: 'Guide to the right path', gender: 'Male', origin: 'Arabic' },
    { name: 'Hana', urduName: 'ہنہ', meaning: 'Happiness, bliss', gender: 'Female', origin: 'Arabic' },
    { name: 'Ibrahim', urduName: 'ابراہم', meaning: 'Father of many', gender: 'Male', origin: 'Hebrew' },
    { name: 'Jasmine', urduName: 'یاسمین', meaning: 'A fragrant flower', gender: 'Female', origin: 'Persian' },
    { name: 'Khadija', urduName: 'خدیجہ', meaning: 'Early baby, daughter of the Prophet', gender: 'Female', origin: 'Arabic' },
    { name: 'Khalid', urduName: 'خالد', meaning: 'Eternal, everlasting', gender: 'Male', origin: 'Arabic' },
    { name: 'Laila', urduName: 'لیلی', meaning: 'Night; dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Layla', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Lina', urduName: 'لینا', meaning: 'Tender, delicate', gender: 'Female', origin: 'Arabic' },
    { name: 'Mansoor', urduName: 'منصور', meaning: 'Victorious', gender: 'Male', origin: 'Arabic' },
    { name: 'Mariam', urduName: 'مریم', meaning: 'Beloved, a variation of Mary', gender: 'Female', origin: 'Arabic' },
    { name: 'Najma', urduName: 'نجما', meaning: 'Star', gender: 'Female', origin: 'Arabic' },
    { name: 'Nadia', urduName: 'نادیا', meaning: 'Hope, tender', gender: 'Female', origin: 'Slavic' },
    { name: 'Noor', urduName: 'نور', meaning: 'Light, radiance', gender: 'Unisex', origin: 'Arabic' },
    { name: 'Omar', urduName: 'عمر', meaning: 'Flourishing, long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Rami', urduName: 'رامی', meaning: 'Archer', gender: 'Male', origin: 'Arabic' },
    { name: 'Rania', urduName: 'رانیا', meaning: 'Queen, gazing', gender: 'Female', origin: 'Arabic' },
    { name: 'Reem', urduName: 'ریم', meaning: 'White antelope', gender: 'Female', origin: 'Arabic' },
    { name: 'Salim', urduName: 'سلیم', meaning: 'Safe, sound', gender: 'Male', origin: 'Arabic' },
    { name: 'Samira', urduName: 'سمیرا', meaning: 'Companion in evening talk', gender: 'Female', origin: 'Arabic' },
    { name: 'Sami', urduName: 'سامی', meaning: 'Elevated, sublime', gender: 'Male', origin: 'Arabic' },
    { name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Sofia', urduName: 'سوفیہ', meaning: 'Wisdom', gender: 'Female', origin: 'Greek' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, he who knocks at the door', gender: 'Male', origin: 'Arabic' },
    { name: 'Yara', urduName: 'یارا', meaning: 'Small butterfly; to flow', gender: 'Female', origin: 'Arabic' },
    { name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
    { name: 'Zain', urduName: 'زین', meaning: 'Beauty, grace', gender: 'Male', origin: 'Arabic' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Father’s precious jewel', gender: 'Female', origin: 'Arabic' },
    { name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Asad', urduName: 'اسد', meaning: 'Lion', gender: 'Male', origin: 'Arabic' },
    { name: 'Amna', urduName: 'امنا', meaning: 'Peace, safety', gender: 'Female', origin: 'Arabic' },
    { name: 'Zahra', urduName: 'زہرا', meaning: 'Flower, bright', gender: 'Female', origin: 'Arabic' },
    { name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases', gender: 'Male', origin: 'Hebrew' },
    { name: 'Rehana', urduName: 'ریحانہ', meaning: 'Fragrance, sweet basil', gender: 'Female', origin: 'Arabic' },
    { name: 'Nour', urduName: 'نور', meaning: 'Light, illumination', gender: 'Unisex', origin: 'Arabic' },
    { name: 'Anisa', urduName: 'انیسہ', meaning: 'Friendly, companion', gender: 'Female', origin: 'Arabic' },
    { name: 'Samia', urduName: 'سما', meaning: 'Elevated, exalted', gender: 'Female', origin: 'Arabic' },
    { name: 'Shirin', urduName: 'شیرین', meaning: 'Sweet, pleasant', gender: 'Female', origin: 'Persian' },
    { name: 'Usama', urduName: 'اسامہ', meaning: 'Lion', gender: 'Male', origin: 'Arabic' },
    { name: 'Areeba', urduName: 'اریبہ', meaning: 'Intelligent, wise', gender: 'Female', origin: 'Arabic' },
    { name: 'Humaira', urduName: 'ہمیرہ', meaning: 'Red, rosy', gender: 'Female', origin: 'Arabic' },
    { name: 'Amal', urduName: 'عمل', meaning: 'Hope, aspiration', gender: 'Female', origin: 'Arabic' },
    { name: 'Ayman', urduName: 'ایمن', meaning: 'Blessed, fortunate', gender: 'Male', origin: 'Arabic' },
    { name: 'Basim', urduName: 'باسم', meaning: 'Smiling', gender: 'Male', origin: 'Arabic' },
    { name: 'Daniya', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
    { name: 'Hiba', urduName: 'ہبہ', meaning: 'Gift', gender: 'Female', origin: 'Arabic' },
    { name: 'Ismail', urduName: 'اسماعیل', meaning: 'God will hear', gender: 'Male', origin: 'Hebrew' },
    { name: 'Kareem', urduName: 'کریم', meaning: 'Generous, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Maya', urduName: 'مایا', meaning: 'Illusion, dream', gender: 'Female', origin: 'Persian' },
    { name: 'Naseem', urduName: 'نسیم', meaning: 'Breeze, soft wind', gender: 'Female', origin: 'Arabic' },
    { name: 'Rania', urduName: 'رانیا', meaning: 'Gazing, looking at', gender: 'Female', origin: 'Arabic' },
    { name: 'Sadiq', urduName: 'صادق', meaning: 'Truthful, honest', gender: 'Male', origin: 'Arabic' },
    { name: 'Sukaina', urduName: 'سکینہ', meaning: 'Calm, peaceful', gender: 'Female', origin: 'Arabic' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star', gender: 'Male', origin: 'Arabic' },
    { name: 'Zaina', urduName: 'زینت', meaning: 'Beauty, adornment', gender: 'Female', origin: 'Arabic' },
    { name: 'Zayd', urduName: 'زید', meaning: 'Growth, abundance', gender: 'Male', origin: 'Arabic' },
    { name: 'Fatima', urduName: 'فاطمہ', meaning: 'One who abstains', gender: 'Female', origin: 'Arabic' },
    { name: 'Bilqis', urduName: 'بلقیس', meaning: 'Queen of Sheba', gender: 'Female', origin: 'Arabic' },
    { name: 'Anwar', urduName: 'انور', meaning: 'Radiant, luminous', gender: 'Male', origin: 'Arabic' },
    { name: 'Rashid', urduName: 'رشید', meaning: 'Rightly guided', gender: 'Male', origin: 'Arabic' },
    { name: 'Aalia', urduName: 'عالیہ', meaning: 'Exalted, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Adnan', urduName: 'عدنان', meaning: 'Settler, one who is well-established', gender: 'Male', origin: 'Arabic' },
    { name: 'Afnan', urduName: 'افنان', meaning: 'Branches, flourishing', gender: 'Female', origin: 'Arabic' },
    { name: 'Alia', urduName: 'عالیہ', meaning: 'Noble, exalted', gender: 'Female', origin: 'Arabic' },
    { name: 'Arwa', urduName: 'اروا', meaning: 'Mountain goat; fresh', gender: 'Female', origin: 'Arabic' },
    { name: 'Danish', urduName: 'دانش', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Persian' },
    { name: 'Farhan', urduName: 'فرحان', meaning: 'Happy, joyful', gender: 'Male', origin: 'Arabic' },
    { name: 'Fatimah', urduName: 'فاطمہ', meaning: 'One who abstains', gender: 'Female', origin: 'Arabic' },
    { name: 'Junaid', urduName: 'جنید', meaning: 'Soldier', gender: 'Male', origin: 'Arabic' },
    { name: 'Lamia', urduName: 'لامیہ', meaning: 'Shining, radiant', gender: 'Female', origin: 'Arabic' },
    { name: 'Malik', urduName: 'مالک', meaning: 'King, master', gender: 'Male', origin: 'Arabic' },
    { name: 'Mansoorah', urduName: 'منصورہ', meaning: 'Victorious', gender: 'Female', origin: 'Arabic' },
    { name: 'Muneeb', urduName: 'منیب', meaning: 'One who turns to God', gender: 'Male', origin: 'Arabic' },
    { name: 'Nida', urduName: 'ندا', meaning: 'Call, voice', gender: 'Female', origin: 'Arabic' },
    { name: 'Rehan', urduName: 'ریحان', meaning: 'Sweet basil; a fragrant herb', gender: 'Male', origin: 'Arabic' },
    { name: 'Sajid', urduName: 'ساجد', meaning: 'One who prostrates', gender: 'Male', origin: 'Arabic' },
    { name: 'Suhana', urduName: 'سہانا', meaning: 'Beautiful, pleasant', gender: 'Female', origin: 'Arabic' },
    { name: 'Talha', urduName: 'طلحہ', meaning: 'A tree; a companion of the Prophet', gender: 'Male', origin: 'Arabic' },
    { name: 'Zahira', urduName: 'زاہرہ', meaning: 'Radiant, blossoming', gender: 'Female', origin: 'Arabic' },
    { name: 'Zaroon', urduName: 'زروان', meaning: 'Visitor, guest', gender: 'Male', origin: 'Arabic' },
    { name: 'Yumna', urduName: 'یمنہ', meaning: 'Lucky, blessed', gender: 'Female', origin: 'Arabic' },
    { name: 'Zoya', urduName: 'زویہ', meaning: 'Alive, prosperous', gender: 'Female', origin: 'Arabic' },
    { name: 'Aariz', urduName: 'عارض', meaning: 'Respectable, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Aayla', urduName: 'آئلہ', meaning: 'Moonlight', gender: 'Female', origin: 'Arabic' },
    { name: 'Azaan', urduName: 'اذان', meaning: 'Call to prayer', gender: 'Male', origin: 'Arabic' },
    { name: 'Bilqis', urduName: 'بلقیس', meaning: 'Queen of Sheba', gender: 'Female', origin: 'Arabic' },
    { name: 'Dania', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
    { name: 'Emran', urduName: 'امران', meaning: 'Prosperity', gender: 'Male', origin: 'Arabic' },
    { name: 'Fariha', urduName: 'فرحہ', meaning: 'Happy, joyful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ghazal', urduName: 'غزل', meaning: 'Poetry, song', gender: 'Female', origin: 'Persian' },
    { name: 'Hiba', urduName: 'ہبہ', meaning: 'Gift, present', gender: 'Female', origin: 'Arabic' },
    { name: 'Imran', urduName: 'عمران', meaning: 'Prosperity', gender: 'Male', origin: 'Arabic' },
    { name: 'Jamal', urduName: 'جمال', meaning: 'Beauty', gender: 'Male', origin: 'Arabic' },
    { name: 'Kainat', urduName: 'کائنات', meaning: 'Universe', gender: 'Female', origin: 'Arabic' },
    { name: 'Mahira', urduName: 'ماہرہ', meaning: 'Skilled, expert', gender: 'Female', origin: 'Arabic' },
    { name: 'Naima', urduName: 'نعیمہ', meaning: 'Comfort, tranquility', gender: 'Female', origin: 'Arabic' },
    { name: 'Owais', urduName: 'اوئیس', meaning: 'A companion of the Prophet', gender: 'Male', origin: 'Arabic' },
    { name: 'Parveen', urduName: 'پروین', meaning: 'The Pleiades; a group of stars', gender: 'Female', origin: 'Persian' },
    { name: 'Rayyan', urduName: 'ریان', meaning: 'Gates of Heaven', gender: 'Male', origin: 'Arabic' },
    { name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Tania', urduName: 'تانیہ', meaning: 'Fairy queen', gender: 'Female', origin: 'Persian' },
    { name: 'Vania', urduName: 'وینیا', meaning: 'God is gracious', gender: 'Female', origin: 'Hebrew' },
    { name: 'Wasiq', urduName: 'واسط', meaning: 'Confident', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasira', urduName: 'یاسرہ', meaning: 'Easy-going', gender: 'Female', origin: 'Arabic' },
    { name: 'Zakir', urduName: 'ذاکر', meaning: 'One who remembers Allah', gender: 'Male', origin: 'Arabic' },
    { name: 'Zainul', urduName: 'زین العابدین', meaning: 'Adornment of the worshippers', gender: 'Male', origin: 'Arabic' },
    { name: 'Zayna', urduName: 'زینت', meaning: 'Beauty, adornment', gender: 'Female', origin: 'Arabic' },
    { name: 'Adnan', urduName: 'عدنان', meaning: 'Settler, one who is a refuge', gender: 'Male', origin: 'Arabic' },
    { name: 'Aira', urduName: 'ایرا', meaning: 'Noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Asiya', urduName: 'آسیہ', meaning: 'One who tends to the weak', gender: 'Female', origin: 'Arabic' },
    { name: 'Danish', urduName: 'دانش', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Persian' },
    { name: 'Dua', urduName: 'دعا', meaning: 'Prayer', gender: 'Female', origin: 'Arabic' },
    { name: 'Ehsan', urduName: 'احسان', meaning: 'Benevolence, charity', gender: 'Male', origin: 'Arabic' },
    { name: 'Hanan', urduName: 'حنان', meaning: 'Compassion, kindness', gender: 'Female', origin: 'Arabic' },
    { name: 'Hisham', urduName: 'ہشام', meaning: 'Generous', gender: 'Male', origin: 'Arabic' },
    { name: 'Inaya', urduName: 'عنایہ', meaning: 'Care, concern', gender: 'Female', origin: 'Arabic' },
    { name: 'Khalilah', urduName: 'خلیلہ', meaning: 'Friend, companion', gender: 'Female', origin: 'Arabic' },
    { name: 'Mufeed', urduName: 'مفید', meaning: 'Beneficial', gender: 'Male', origin: 'Arabic' },
    { name: 'Nida', urduName: 'ندا', meaning: 'Call, voice', gender: 'Female', origin: 'Arabic' },
    { name: 'Rayya', urduName: 'ریحا', meaning: 'Flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Raza', urduName: 'رضا', meaning: 'Contentment', gender: 'Male', origin: 'Arabic' },
    { name: 'Sabah', urduName: 'صباح', meaning: 'Morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Salma', urduName: 'سلمیٰ', meaning: 'Peaceful, safe', gender: 'Female', origin: 'Arabic' },
    { name: 'Sultan', urduName: 'سلطان', meaning: 'King, ruler', gender: 'Male', origin: 'Arabic' },
    { name: 'Tahira', urduName: 'طاہرہ', meaning: 'Pure, virtuous', gender: 'Female', origin: 'Arabic' },
    { name: 'Yasir', urduName: 'یاسر', meaning: 'Wealthy, prosperous', gender: 'Male', origin: 'Arabic' },
    { name: 'Zaki', urduName: 'ذکی', meaning: 'Intelligent, pure', gender: 'Male', origin: 'Arabic' },
    { name: 'Zareen', urduName: 'زرین', meaning: 'Golden, precious', gender: 'Female', origin: 'Persian' },
    { name: 'Zunaira', urduName: 'زونیرا', meaning: 'A flower, a type of plant', gender: 'Female', origin: 'Arabic' },
    { name: 'Afnan', urduName: 'افنان', meaning: 'Branches, fronds', gender: 'Male', origin: 'Arabic' },
    { name: 'Alayna', urduName: 'الینا', meaning: 'Sky, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Bilqis', urduName: 'بلقیس', meaning: 'Queen of Sheba', gender: 'Female', origin: 'Arabic' },
    { name: 'Daud', urduName: 'داود', meaning: 'Beloved', gender: 'Male', origin: 'Hebrew' },
    { name: 'Fariha', urduName: 'فرح', meaning: 'Happy, joyful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ghazal', urduName: 'غزل', meaning: 'Poetic love, deer', gender: 'Female', origin: 'Arabic' },
    { name: 'Hamza', urduName: 'حمزہ', meaning: 'Lion, strong', gender: 'Male', origin: 'Arabic' },
    { name: 'Hooriya', urduName: 'حوریا', meaning: 'A fairy, a beautiful woman', gender: 'Female', origin: 'Arabic' },
    { name: 'Ibtisam', urduName: 'ابتسام', meaning: 'Smile', gender: 'Female', origin: 'Arabic' },
    { name: 'Jibril', urduName: 'جبریل', meaning: 'The angel Gabriel', gender: 'Male', origin: 'Arabic' },
    { name: 'Kareem', urduName: 'کریم', meaning: 'Generous, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Malik', urduName: 'مالک', meaning: 'King, ruler', gender: 'Male', origin: 'Arabic' },
    { name: 'Nafisa', urduName: 'نفیسہ', meaning: 'Precious', gender: 'Female', origin: 'Arabic' },
    { name: 'Qadir', urduName: 'قادِر', meaning: 'Powerful', gender: 'Male', origin: 'Arabic' },
    { name: 'Rashid', urduName: 'راشد', meaning: 'Rightly guided', gender: 'Male', origin: 'Arabic' },
    { name: 'Sadiya', urduName: 'سادیہ', meaning: 'Lucky, fortunate', gender: 'Female', origin: 'Arabic' },
    { name: 'Shahzad', urduName: 'شہزاد', meaning: 'Prince', gender: 'Male', origin: 'Persian' },
    { name: 'Tamara', urduName: 'تمارا', meaning: 'Date palm', gender: 'Female', origin: 'Arabic' },
    { name: 'Ummar', urduName: 'عمار', meaning: 'Long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasira', urduName: 'یاسرہ', meaning: 'Easy, prosperous', gender: 'Female', origin: 'Arabic' },
    { name: 'Zabir', urduName: 'ذابر', meaning: 'One who is aware', gender: 'Male', origin: 'Arabic' },
    { name: 'Zinah', urduName: 'زینت', meaning: 'Beauty, adornment', gender: 'Female', origin: 'Arabic' },
    { name: 'Abeer', urduName: 'عبیر', meaning: 'Fragrance, perfume', gender: 'Unisex', origin: 'Arabic' },
    { name: 'Adil', urduName: 'عادل', meaning: 'Just, fair', gender: 'Male', origin: 'Arabic' },
    { name: 'Afra', urduName: 'عفراء', meaning: 'White, pure, dust-colored', gender: 'Female', origin: 'Arabic' },
    { name: 'Aliza', urduName: 'علیزا', meaning: 'Joyful, cheerful', gender: 'Female', origin: 'Hebrew' },
    { name: 'Anan', urduName: 'عنن', meaning: 'Clouds, vision', gender: 'Male', origin: 'Arabic' },
    { name: 'Areej', urduName: 'اریج', meaning: 'Fragrance, sweet smell', gender: 'Female', origin: 'Arabic' },
    { name: 'Ashar', urduName: 'اشر', meaning: 'Wise, prudent', gender: 'Male', origin: 'Arabic' },
    { name: 'Azhar', urduName: 'ازہر', meaning: 'Radiant, luminous', gender: 'Male', origin: 'Arabic' },
    { name: 'Bayan', urduName: 'بیان', meaning: 'Clear, eloquent', gender: 'Female', origin: 'Arabic' },
    { name: 'Dawood', urduName: 'داود', meaning: 'Beloved, the Prophet David', gender: 'Male', origin: 'Arabic' },
    { name: 'Ehsan', urduName: 'احسان', meaning: 'Excellence, kindness', gender: 'Male', origin: 'Arabic' },
    { name: 'Eman', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Female', origin: 'Arabic' },
    { name: 'Feroza', urduName: 'فیروزا', meaning: 'Turquoise (gemstone)', gender: 'Female', origin: 'Persian' },
    { name: 'Gulzar', urduName: 'گلزار', meaning: 'Garden, blooming', gender: 'Male', origin: 'Persian' },
    { name: 'Habib', urduName: 'حبیب', meaning: 'Beloved, dear', gender: 'Male', origin: 'Arabic' },
    { name: 'Halima', urduName: 'حلیمہ', meaning: 'Gentle, patient', gender: 'Female', origin: 'Arabic' },
    { name: 'Jibril', urduName: 'جبریل', meaning: 'Archangel Gabriel', gender: 'Male', origin: 'Arabic' },
    { name: 'Kamil', urduName: 'کامل', meaning: 'Perfect, complete', gender: 'Male', origin: 'Arabic' },
    { name: 'Lubna', urduName: 'لبنی', meaning: 'Tree with fragrant resin', gender: 'Female', origin: 'Arabic' },
    { name: 'Mahira', urduName: 'ماہیرہ', meaning: 'Skilled, talented', gender: 'Female', origin: 'Arabic' },
    { name: 'Nayla', urduName: 'نائلہ', meaning: 'Acquirer, achiever', gender: 'Female', origin: 'Arabic' },
    { name: 'Rafi', urduName: 'رفیع', meaning: 'Exalted, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Saniya', urduName: 'ثانیہ', meaning: 'Brilliant, radiant', gender: 'Female', origin: 'Arabic' },
    { name: 'Shahbaz', urduName: 'شاہباز', meaning: 'Royal falcon', gender: 'Male', origin: 'Persian' },
    { name: 'Taha', urduName: 'طہ', meaning: 'Pure, name of a Quranic chapter', gender: 'Male', origin: 'Arabic' },
    { name: 'Wafa', urduName: 'وفا', meaning: 'Faithfulness, loyalty', gender: 'Female', origin: 'Arabic' },
    { name: 'Yumna', urduName: 'یمنہ', meaning: 'Blessed, right-hand side', gender: 'Female', origin: 'Arabic' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Fragrant flower, father’s ornament', gender: 'Female', origin: 'Arabic' },
    { name: 'Zeeshan', urduName: 'ذیشان', meaning: 'Renowned, magnificent', gender: 'Male', origin: 'Arabic' },
    { name: 'Zulekha', urduName: 'زلیخا', meaning: 'Brilliant beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Aqeel', urduName: 'عقیل', meaning: 'Intelligent, wise', gender: 'Male', origin: 'Arabic' },
    { name: 'Arib', urduName: 'اریب', meaning: 'Skillful, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Asiya', urduName: 'آسیہ', meaning: 'Pious, a woman who nurses the weak', gender: 'Female', origin: 'Arabic' },
    { name: 'Burhan', urduName: 'برہان', meaning: 'Proof, evidence', gender: 'Male', origin: 'Arabic' },
    { name: 'Cahya', urduName: 'چاہیا', meaning: 'Light, radiance', gender: 'Unisex', origin: 'Malay/Indonesian' },
    { name: 'Dania', urduName: 'دانیا', meaning: 'Near, close', gender: 'Female', origin: 'Arabic' },
    { name: 'Eraj', urduName: 'ایرج', meaning: 'Morning light', gender: 'Female', origin: 'Persian' },
    { name: 'Fahim', urduName: 'فہیم', meaning: 'Intelligent, judicious', gender: 'Male', origin: 'Arabic' },
    { name: 'Ghazal', urduName: 'غزل', meaning: 'Poetry, lyric', gender: 'Female', origin: 'Arabic' },
    { name: 'Haniya', urduName: 'ہانیہ', meaning: 'Happy, delighted', gender: 'Female', origin: 'Arabic' },
    { name: 'Irfan', urduName: 'عرفان', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Arabic' },
    { name: 'Jameela', urduName: 'جمیلہ', meaning: 'Beautiful, lovely', gender: 'Female', origin: 'Arabic' },
    { name: 'Kaif', urduName: 'کیف', meaning: 'Delight, high spirits', gender: 'Unisex', origin: 'Arabic' },
    { name: 'Laiba', urduName: 'لائبہ', meaning: 'Angel of heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Maaz', urduName: 'معاذ', meaning: 'Shelter, protection', gender: 'Male', origin: 'Arabic' },
    { name: 'Nimra', urduName: 'نمرہ', meaning: 'Pure, soft, clean', gender: 'Female', origin: 'Arabic' },
    { name: 'Rameez', urduName: 'رمیز', meaning: 'Symbol, indication', gender: 'Male', origin: 'Arabic' },
    { name: 'Sabeeha', urduName: 'صبیحہ', meaning: 'Morning beauty, pretty', gender: 'Female', origin: 'Arabic' },
    { name: 'Tanzeel', urduName: 'تنزیل', meaning: 'Revelation, descent', gender: 'Male', origin: 'Arabic' },
    { name: 'Umair', urduName: 'عمیر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Wardah', urduName: 'وردہ', meaning: 'Rose, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Yasir', urduName: 'یاسر', meaning: 'Wealthy, rich', gender: 'Male', origin: 'Arabic' },
    { name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Zohair', urduName: 'زہیر', meaning: 'Bright, shining', gender: 'Male', origin: 'Arabic' },
    { name: 'Zunaira', urduName: 'زنیرہ', meaning: 'Flower of paradise', gender: 'Female', origin: 'Arabic' },
    { name: 'Aahil', urduName: 'آہل', meaning: 'Prince, great king', gender: 'Male', origin: 'Arabic' },
    { name: 'Aariz', urduName: 'عارض', meaning: 'Respectable man, leader', gender: 'Male', origin: 'Arabic' },
    { name: 'Aatif', urduName: 'عاطف', meaning: 'Kind, compassionate', gender: 'Male', origin: 'Arabic' },
    { name: 'Abqari', urduName: 'عبقری', meaning: 'Genius, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Adib', urduName: 'ادیب', meaning: 'Scholar, cultured', gender: 'Male', origin: 'Arabic' },
    { name: 'Ahnaf', urduName: 'احنف', meaning: 'Straight path', gender: 'Male', origin: 'Arabic' },
    { name: 'Anwaar', urduName: 'انوار', meaning: 'Lights, radiance', gender: 'Male', origin: 'Arabic' },
    { name: 'Arham', urduName: 'ارحم', meaning: 'Merciful, compassionate', gender: 'Male', origin: 'Arabic' },
    { name: 'Arib', urduName: 'اریب', meaning: 'Skillful, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Asif', urduName: 'آصف', meaning: 'Forgiveness, strong', gender: 'Male', origin: 'Arabic' },
    { name: 'Azlan', urduName: 'اذلان', meaning: 'Lion, brave', gender: 'Male', origin: 'Arabic' },
    { name: 'Baqar', urduName: 'باقر', meaning: 'Man of knowledge', gender: 'Male', origin: 'Arabic' },
    { name: 'Bashir', urduName: 'بشیر', meaning: 'Bringer of good news', gender: 'Male', origin: 'Arabic' },
    { name: 'Burhan', urduName: 'برہان', meaning: 'Proof, evidence', gender: 'Male', origin: 'Arabic' },
    { name: 'Daud', urduName: 'داود', meaning: 'Beloved, a Prophet', gender: 'Male', origin: 'Arabic' },
    { name: 'Dilawar', urduName: 'دلاور', meaning: 'Brave, bold', gender: 'Male', origin: 'Persian' },
    { name: 'Ehsan', urduName: 'احسان', meaning: 'Goodness, favor', gender: 'Male', origin: 'Arabic' },
    { name: 'Faizan', urduName: 'فیضان', meaning: 'Beneficence, blessings', gender: 'Male', origin: 'Arabic' },
    { name: 'Fawad', urduName: 'فواد', meaning: 'Heart, conscience', gender: 'Male', origin: 'Arabic' },
    { name: 'Ghaffar', urduName: 'غفار', meaning: 'Forgiving, merciful', gender: 'Male', origin: 'Arabic' },
    { name: 'Hamdan', urduName: 'حمدان', meaning: 'Praiser, one who thanks Allah', gender: 'Male', origin: 'Arabic' },
    { name: 'Haseeb', urduName: 'حسیب', meaning: 'Reckoner, respected', gender: 'Male', origin: 'Arabic' },
    { name: 'Huda', urduName: 'ہدی', meaning: 'Guidance', gender: 'Male', origin: 'Arabic' },
    { name: 'Ihsan', urduName: 'احسان', meaning: 'Excellence, generosity', gender: 'Male', origin: 'Arabic' },
    { name: 'Ikram', urduName: 'اکرام', meaning: 'Honor, respect', gender: 'Male', origin: 'Arabic' },
    { name: 'Irfan', urduName: 'عرفان', meaning: 'Knowledge, awareness', gender: 'Male', origin: 'Arabic' },
    { name: 'Ishaq', urduName: 'اسحاق', meaning: 'A Prophet, laughter', gender: 'Male', origin: 'Arabic' },
    { name: 'Jabir', urduName: 'جابر', meaning: 'Comforter, one who consoles', gender: 'Male', origin: 'Arabic' },
    { name: 'Jamal', urduName: 'جمال', meaning: 'Beauty, elegance', gender: 'Male', origin: 'Arabic' },
    { name: 'Kamil', urduName: 'کامل', meaning: 'Perfect, complete', gender: 'Male', origin: 'Arabic' },
    { name: 'Latif', urduName: 'لطیف', meaning: 'Kind, gentle', gender: 'Male', origin: 'Arabic' },
    { name: 'Mujahid', urduName: 'مجاہد', meaning: 'Warrior, struggler', gender: 'Male', origin: 'Arabic' },
    { name: 'Najeeb', urduName: 'نجیب', meaning: 'Noble, generous', gender: 'Male', origin: 'Arabic' },
    { name: 'Naseem', urduName: 'نسیم', meaning: 'Breeze, fresh air', gender: 'Male', origin: 'Arabic' },
    { name: 'Owais', urduName: 'اویس', meaning: 'Gifted, small wolf', gender: 'Male', origin: 'Arabic' },
    { name: 'Qadir', urduName: 'قادر', meaning: 'Powerful, able', gender: 'Male', origin: 'Arabic' },
    { name: 'Rafay', urduName: 'رافے', meaning: 'Exalted, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Rehan', urduName: 'ریحان', meaning: 'Fragrant plant, sweet basil', gender: 'Male', origin: 'Arabic' },
    { name: 'Sajjad', urduName: 'سجاد', meaning: 'One who prostrates often', gender: 'Male', origin: 'Arabic' },
    { name: 'Shayan', urduName: 'شایان', meaning: 'Worthy, deserving', gender: 'Male', origin: 'Persian' },
    { name: 'Talal', urduName: 'طلال', meaning: 'Nice, admirable', gender: 'Male', origin: 'Arabic' },
    { name: 'Usama', urduName: 'اسامہ', meaning: 'Lion, brave', gender: 'Male', origin: 'Arabic' },
    { name: 'Waleed', urduName: 'ولید', meaning: 'Newborn, baby', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasir', urduName: 'یاسر', meaning: 'Easy-going, wealthy', gender: 'Male', origin: 'Arabic' },
    { name: 'Zafar', urduName: 'ظفر', meaning: 'Victory, triumph', gender: 'Male', origin: 'Arabic' },
    { name: 'Zubair', urduName: 'زبیر', meaning: 'Strong, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Aadila', urduName: 'عادلہ', meaning: 'Just, fair', gender: 'Female', origin: 'Arabic' },
    { name: 'Abida', urduName: 'عابدہ', meaning: 'Worshipper', gender: 'Female', origin: 'Arabic' },
    { name: 'Afra', urduName: 'عفراء', meaning: 'White, innocent', gender: 'Female', origin: 'Arabic' },
    { name: 'Aisha', urduName: 'عائشہ', meaning: 'Alive, living, prosperous', gender: 'Female', origin: 'Arabic' },
    { name: 'Aleena', urduName: 'علینہ', meaning: 'Soft, delicate', gender: 'Female', origin: 'Arabic' },
    { name: 'Amna', urduName: 'آمنہ', meaning: 'Peaceful, kind', gender: 'Female', origin: 'Arabic' },
    { name: 'Anabia', urduName: 'انابیہ', meaning: 'Turn towards Allah, fragrance', gender: 'Female', origin: 'Arabic' },
    { name: 'Asma', urduName: 'اسماء', meaning: 'Exalted, high status', gender: 'Female', origin: 'Arabic' },
    { name: 'Ayat', urduName: 'آیت', meaning: 'Sign, miracle', gender: 'Female', origin: 'Arabic' },
    { name: 'Azra', urduName: 'عذرا', meaning: 'Virgin, maiden', gender: 'Female', origin: 'Arabic' },
    { name: 'Bisma', urduName: 'بسمہ', meaning: 'Smile, politeness', gender: 'Female', origin: 'Arabic' },
    { name: 'Dania', urduName: 'دانیا', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
    { name: 'Emaan', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Female', origin: 'Arabic' },
    { name: 'Farah', urduName: 'فرح', meaning: 'Joy, happiness', gender: 'Female', origin: 'Arabic' },
    { name: 'Fareeha', urduName: 'فریحہ', meaning: 'Happy, cheerful', gender: 'Female', origin: 'Arabic' },
    { name: 'Hafsa', urduName: 'حفصہ', meaning: 'Cub, young lioness', gender: 'Female', origin: 'Arabic' },
    { name: 'Hiba', urduName: 'ہبہ', meaning: 'Gift, blessing', gender: 'Female', origin: 'Arabic' },
    { name: 'Humaira', urduName: 'حمیرا', meaning: 'Reddish, rose-colored', gender: 'Female', origin: 'Arabic' },
    { name: 'Iqra', urduName: 'اقرا', meaning: 'Read, recite', gender: 'Female', origin: 'Arabic' },
    { name: 'Jannat', urduName: 'جنت', meaning: 'Paradise, heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Khadija', urduName: 'خدیجہ', meaning: 'First wife of Prophet Muhammad (PBUH)', gender: 'Female', origin: 'Arabic' },
    { name: 'Komal', urduName: 'کومل', meaning: 'Soft, gentle', gender: 'Female', origin: 'Urdu' },
    { name: 'Laiba', urduName: 'لائبہ', meaning: 'Angel of heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Lubna', urduName: 'لبنیٰ', meaning: 'Tree with good fragrance', gender: 'Female', origin: 'Arabic' },
    { name: 'Mahnoor', urduName: 'ماہنور', meaning: 'Moonlight', gender: 'Female', origin: 'Urdu' },
    { name: 'Maira', urduName: 'مائرہ', meaning: 'Light, swift', gender: 'Female', origin: 'Arabic' },
    { name: 'Malika', urduName: 'ملکہ', meaning: 'Queen, owner', gender: 'Female', origin: 'Arabic' },
    { name: 'Mehar', urduName: 'مہر', meaning: 'Kindness, sunlight', gender: 'Female', origin: 'Urdu' },
    { name: 'Nabiha', urduName: 'نبیہ', meaning: 'Noble, intelligent', gender: 'Female', origin: 'Arabic' },
    { name: 'Naila', urduName: 'نائلہ', meaning: 'Achiever, winner', gender: 'Female', origin: 'Arabic' },
    { name: 'Noor', urduName: 'نور', meaning: 'Light, radiance', gender: 'Female', origin: 'Arabic' },
    { name: 'Rabia', urduName: 'رابعہ', meaning: 'Spring, garden', gender: 'Female', origin: 'Arabic' },
    { name: 'Rafia', urduName: 'رفیعہ', meaning: 'Exalted, sublime', gender: 'Female', origin: 'Arabic' },
    { name: 'Rida', urduName: 'رضا', meaning: 'Contentment, satisfaction', gender: 'Female', origin: 'Arabic' },
    { name: 'Saima', urduName: 'صائمہ', meaning: 'Fasting woman', gender: 'Female', origin: 'Arabic' },
    { name: 'Sana', urduName: 'ثنا', meaning: 'Praise, exaltation', gender: 'Female', origin: 'Arabic' },
    { name: 'Shifa', urduName: 'شفا', meaning: 'Healing, cure', gender: 'Female', origin: 'Arabic' },
    { name: 'Tahira', urduName: 'طاہرہ', meaning: 'Pure, virtuous', gender: 'Female', origin: 'Arabic' },
    { name: 'Tuba', urduName: 'توبہ', meaning: 'Repentance, blessed', gender: 'Female', origin: 'Arabic' },
    { name: 'Umme', urduName: 'امے', meaning: 'Mother of', gender: 'Female', origin: 'Arabic' },
    { name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Generosity, fragrance', gender: 'Female', origin: 'Arabic' },
    { name: 'Zara', urduName: 'زارہ', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Zeenat', urduName: 'زینت', meaning: 'Adornment, beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Aadil', urduName: 'عادل', meaning: 'Just, fair', gender: 'Male', origin: 'Arabic' },
    { name: 'Aafiya', urduName: 'عافیہ', meaning: 'Health, free from illness', gender: 'Female', origin: 'Arabic' },
    { name: 'Aalim', urduName: 'عالم', meaning: 'Scholar, wise', gender: 'Male', origin: 'Arabic' },
    { name: 'Aarib', urduName: 'عارب', meaning: 'Handsome, healthy', gender: 'Male', origin: 'Arabic' },
    { name: 'Aban', urduName: 'ابان', meaning: 'Clear, eloquent', gender: 'Male', origin: 'Arabic' },
    { name: 'Abbas', urduName: 'عباس', meaning: 'Lion, stern', gender: 'Male', origin: 'Arabic' },
    { name: 'Abdullah', urduName: 'عبداللہ', meaning: 'Servant of Allah', gender: 'Male', origin: 'Arabic' },
    { name: 'Abeer', urduName: 'عبیر', meaning: 'Fragrance', gender: 'Female', origin: 'Arabic' },
    { name: 'Adeela', urduName: 'عدیلہ', meaning: 'Righteous, just', gender: 'Female', origin: 'Arabic' },
    { name: 'Afreen', urduName: 'آفرین', meaning: 'Praise, encouragement', gender: 'Female', origin: 'Persian' },
    { name: 'Ahmad', urduName: 'احمد', meaning: 'Highly praised', gender: 'Male', origin: 'Arabic' },
    { name: 'Aiman', urduName: 'ایمن', meaning: 'Blessed, fearless', gender: 'Female', origin: 'Arabic' },
    { name: 'Ali', urduName: 'علی', meaning: 'Exalted, noble', gender: 'Male', origin: 'Arabic' },
    { name: 'Aliza', urduName: 'علیزہ', meaning: 'Joy, happiness', gender: 'Female', origin: 'Arabic' },
    { name: 'Amir', urduName: 'امیر', meaning: 'Prince, leader', gender: 'Male', origin: 'Arabic' },
    { name: 'Anam', urduName: 'انعم', meaning: 'Blessing, gift from Allah', gender: 'Female', origin: 'Arabic' },
    { name: 'Arham', urduName: 'ارحم', meaning: 'Merciful, kind-hearted', gender: 'Male', origin: 'Arabic' },
    { name: 'Arisha', urduName: 'عریشہ', meaning: 'Throne, highness', gender: 'Female', origin: 'Arabic' },
    { name: 'Asad', urduName: 'اسد', meaning: 'Lion, bravery', gender: 'Male', origin: 'Arabic' },
    { name: 'Ashar', urduName: 'اشر', meaning: 'Wise, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Asif', urduName: 'آصف', meaning: 'Forgiveness, strong', gender: 'Male', origin: 'Arabic' },
    { name: 'Asra', urduName: 'اسراء', meaning: 'Travel by night, journey', gender: 'Female', origin: 'Arabic' },
    { name: 'Atif', urduName: 'عاطف', meaning: 'Kind, affectionate', gender: 'Male', origin: 'Arabic' },
    { name: 'Ayana', urduName: 'عیانہ', meaning: 'Mirror, reflection', gender: 'Female', origin: 'Arabic' },
    { name: 'Ayaan', urduName: 'ایان', meaning: 'Gift of God', gender: 'Male', origin: 'Arabic' },
    { name: 'Azan', urduName: 'اذان', meaning: 'Call for prayer', gender: 'Male', origin: 'Arabic' },
    { name: 'Azhaan', urduName: 'اذہان', meaning: 'Intellect, genius', gender: 'Male', origin: 'Arabic' },
    { name: 'Babar', urduName: 'ببر', meaning: 'Lion, courageous', gender: 'Male', origin: 'Persian' },
    { name: 'Bismah', urduName: 'بسمہ', meaning: 'Smile, politeness', gender: 'Female', origin: 'Arabic' },
    { name: 'Dua', urduName: 'دعا', meaning: 'Prayer, supplication', gender: 'Female', origin: 'Arabic' },
    { name: 'Ehsan', urduName: 'احسان', meaning: 'Kindness, favor', gender: 'Male', origin: 'Arabic' },
    { name: 'Fahad', urduName: 'فہد', meaning: 'Leopard, brave', gender: 'Male', origin: 'Arabic' },
    { name: 'Faiza', urduName: 'فائزہ', meaning: 'Victorious, successful', gender: 'Female', origin: 'Arabic' },
    { name: 'Firdous', urduName: 'فردوس', meaning: 'Paradise, heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
    { name: 'Hina', urduName: 'حنا', meaning: 'Fragrance, henna', gender: 'Female', origin: 'Arabic' },
    { name: 'Hussain', urduName: 'حسین', meaning: 'Handsome, beautiful', gender: 'Male', origin: 'Arabic' },
    { name: 'Inaya', urduName: 'عنایہ', meaning: 'Care, concern', gender: 'Female', origin: 'Arabic' },
    { name: 'Iqbal', urduName: 'اقبال', meaning: 'Prosperity, fortune', gender: 'Male', origin: 'Arabic' },
    { name: 'Irtiza', urduName: 'ارتضاء', meaning: 'Approval, satisfaction', gender: 'Male', origin: 'Arabic' },
    { name: 'Jawad', urduName: 'جواد', meaning: 'Generous, liberal', gender: 'Male', origin: 'Arabic' },
    { name: 'Jibran', urduName: 'جبران', meaning: 'Reward, compensation', gender: 'Male', origin: 'Arabic' },
    { name: 'Kashif', urduName: 'کاشف', meaning: 'Revealer, discoverer', gender: 'Male', origin: 'Arabic' },
    { name: 'Laila', urduName: 'لیلیٰ', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Maaz', urduName: 'معاذ', meaning: 'Protected, fortified', gender: 'Male', origin: 'Arabic' },
    { name: 'Maryam', urduName: 'مریم', meaning: 'Virgin, pious woman', gender: 'Female', origin: 'Arabic' },
    { name: 'Nimra', urduName: 'نمرہ', meaning: 'Pure, clean', gender: 'Female', origin: 'Arabic' },
    { name: 'Rameez', urduName: 'رمیز', meaning: 'Symbol, gesture', gender: 'Male', origin: 'Arabic' },
    { name: 'Sameer', urduName: 'سمیر', meaning: 'Companion, jovial', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasir', urduName: 'یاسر', meaning: 'Wealthy, prosperous', gender: 'Male', origin: 'Arabic' },
    { name: 'Zara', urduName: 'زارہ', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Zubair', urduName: 'زبیر', meaning: 'Strong, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Zoya', urduName: 'زویا', meaning: 'Alive, loving', gender: 'Female', origin: 'Persian' },
    { name: 'Zunaira', urduName: 'زنیرہ', meaning: 'Flower of paradise', gender: 'Female', origin: 'Arabic' },
    { name: 'Aariz', urduName: 'عارض', meaning: 'Respectable man', gender: 'Male', origin: 'Arabic' },
    { name: 'Aatiqa', urduName: 'عاتقہ', meaning: 'Generous, free', gender: 'Female', origin: 'Arabic' },
    { name: 'Abqar', urduName: 'عبقر', meaning: 'Brilliant, genius', gender: 'Male', origin: 'Arabic' },
    { name: 'Adeena', urduName: 'ادینہ', meaning: 'Pious, obedient', gender: 'Female', origin: 'Arabic' },
    { name: 'Adiy', urduName: 'عدی', meaning: 'Companion of the Prophet', gender: 'Male', origin: 'Arabic' },
    { name: 'Afaaq', urduName: 'آفاق', meaning: 'Horizons, the world', gender: 'Male', origin: 'Arabic' },
    { name: 'Ahnaf', urduName: 'احنف', meaning: 'Straight path, upright', gender: 'Male', origin: 'Arabic' },
    { name: 'Aimanah', urduName: 'ایمانہ', meaning: 'Faith, trust', gender: 'Female', origin: 'Arabic' },
    { name: 'Ajmal', urduName: 'اجمل', meaning: 'Most beautiful, handsome', gender: 'Male', origin: 'Arabic' },
    { name: 'Alishbah', urduName: 'علیشبہ', meaning: 'Pretty, radiant', gender: 'Female', origin: 'Arabic' },
    { name: 'Amana', urduName: 'امانہ', meaning: 'Faithful, trustworthy', gender: 'Female', origin: 'Arabic' },
    { name: 'Amjad', urduName: 'امجد', meaning: 'More glorious, illustrious', gender: 'Male', origin: 'Arabic' },
    { name: 'Anhar', urduName: 'انہار', meaning: 'Rivers, streams', gender: 'Male', origin: 'Arabic' },
    { name: 'Arsal', urduName: 'ارسل', meaning: 'Messenger, envoy', gender: 'Male', origin: 'Arabic' },
    { name: 'Aseefa', urduName: 'عصیفہ', meaning: 'Courageous, brave', gender: 'Female', origin: 'Arabic' },
    { name: 'Ashfaq', urduName: 'اشفاق', meaning: 'Compassion, affection', gender: 'Male', origin: 'Arabic' },
    { name: 'Asiya', urduName: 'آسیہ', meaning: 'Pious, one who tends to the weak', gender: 'Female', origin: 'Arabic' },
    { name: 'Atyab', urduName: 'اطیب', meaning: 'Very pure, good', gender: 'Male', origin: 'Arabic' },
    { name: 'Azima', urduName: 'عظیمہ', meaning: 'Great, determined', gender: 'Female', origin: 'Arabic' },
    { name: 'Azraq', urduName: 'ازرق', meaning: 'Blue-eyed, shining', gender: 'Male', origin: 'Arabic' },
    { name: 'Bahira', urduName: 'بحیرہ', meaning: 'Dazzling, brilliant', gender: 'Female', origin: 'Arabic' },
    { name: 'Basma', urduName: 'بسمہ', meaning: 'Smile, kindness', gender: 'Female', origin: 'Arabic' },
    { name: 'Bayan', urduName: 'بیان', meaning: 'Clarity, eloquence', gender: 'Male', origin: 'Arabic' },
    { name: 'Daniyah', urduName: 'دانیاہ', meaning: 'Kind-hearted, gentle', gender: 'Female', origin: 'Arabic' },
    { name: 'Dhiya', urduName: 'ضیاء', meaning: 'Light, radiance', gender: 'Male', origin: 'Arabic' },
    { name: 'Durrah', urduName: 'درہ', meaning: 'Pearl, precious', gender: 'Female', origin: 'Arabic' },
    { name: 'Ebrahim', urduName: 'ابراہیم', meaning: 'Father of nations', gender: 'Male', origin: 'Arabic' },
    { name: 'Ehsana', urduName: 'احسانہ', meaning: 'Virtue, benevolence', gender: 'Female', origin: 'Arabic' },
    { name: 'Fahima', urduName: 'فہیمہ', meaning: 'Perceptive, intelligent', gender: 'Female', origin: 'Arabic' },
    { name: 'Faiq', urduName: 'فائق', meaning: 'Outstanding, superior', gender: 'Male', origin: 'Arabic' },
    { name: 'Fizza', urduName: 'فضہ', meaning: 'Silver, radiance', gender: 'Female', origin: 'Arabic' },
    { name: 'Ghaith', urduName: 'غیث', meaning: 'Rain, comfort', gender: 'Male', origin: 'Arabic' },
    { name: 'Gulnaz', urduName: 'گلناز', meaning: 'Delicate, flower', gender: 'Female', origin: 'Persian' },
    { name: 'Hajra', urduName: 'حجرہ', meaning: 'Flight, migration', gender: 'Female', origin: 'Arabic' },
    { name: 'Haram', urduName: 'حرم', meaning: 'Sacred, sanctuary', gender: 'Female', origin: 'Arabic' },
    { name: 'Humam', urduName: 'ہمام', meaning: 'Courageous, generous', gender: 'Male', origin: 'Arabic' },
    { name: 'Ibtihaj', urduName: 'ابتهاج', meaning: 'Joy, happiness', gender: 'Male', origin: 'Arabic' },
    { name: 'Iffat', urduName: 'عفت', meaning: 'Purity, modesty', gender: 'Female', origin: 'Arabic' },
    { name: 'Ishraq', urduName: 'اشراق', meaning: 'Radiance, brilliance', gender: 'Male', origin: 'Arabic' },
    { name: 'Jamila', urduName: 'جميلہ', meaning: 'Beautiful, graceful', gender: 'Female', origin: 'Arabic' },
    { name: 'Kainat', urduName: 'کائنات', meaning: 'Universe, everything', gender: 'Female', origin: 'Arabic' },
    { name: 'Labeeb', urduName: 'لبیب', meaning: 'Sensible, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Mahnoor', urduName: 'ماہنور', meaning: 'Moonlight', gender: 'Female', origin: 'Urdu' },
    { name: 'Mehwish', urduName: 'مہوش', meaning: 'Moon-like, beauty', gender: 'Female', origin: 'Persian' },
    { name: 'Nayel', urduName: 'نائل', meaning: 'Achiever, successful', gender: 'Male', origin: 'Arabic' },
    { name: 'Nashit', urduName: 'نشیط', meaning: 'Energetic, lively', gender: 'Male', origin: 'Arabic' },
    { name: 'Rizwan', urduName: 'رضوان', meaning: 'Guardian of paradise', gender: 'Male', origin: 'Arabic' },
    { name: 'Sana', urduName: 'ثناء', meaning: 'Praise, exaltation', gender: 'Female', origin: 'Arabic' },
    { name: 'Shifa', urduName: 'شفاء', meaning: 'Cure, healing', gender: 'Female', origin: 'Arabic' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, conqueror', gender: 'Male', origin: 'Arabic' },
    { name: 'Uzair', urduName: 'عزیر', meaning: 'Precious, helper', gender: 'Male', origin: 'Arabic' },
    { name: 'Zimal', urduName: 'زیمل', meaning: 'Covering, beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Sarosh', urduName: 'سروش', meaning: 'Messenger, angel', gender: 'Male', origin: 'Persian' },
    { name: 'Nashmia', urduName: 'نشمیہ', meaning: 'Garden, fragrance', gender: 'Female', origin: 'Arabic' },
    { name: 'Arsalan', urduName: 'ارسلان', meaning: 'Lion, fearless', gender: 'Male', origin: 'Turkish' },
    { name: 'Shanzay', urduName: 'شنزے', meaning: 'Princess, dignity', gender: 'Female', origin: 'Persian' },
    { name: 'Murad', urduName: 'مراد', meaning: 'Wish, desire', gender: 'Male', origin: 'Arabic' },
    { name: 'Mahnoor', urduName: 'ماہنور', meaning: 'Moonlight', gender: 'Female', origin: 'Urdu' },
    { name: 'Feroz', urduName: 'فیروز', meaning: 'Victorious, successful', gender: 'Male', origin: 'Persian' },
    { name: 'Gulshan', urduName: 'گلشن', meaning: 'Garden of flowers', gender: 'Female', origin: 'Persian' },
    { name: 'Zaroon', urduName: 'زارون', meaning: 'Visitor, traveler', gender: 'Male', origin: 'Arabic' },
    { name: 'Sabeen', urduName: 'سبین', meaning: 'Cool breeze, morning wind', gender: 'Female', origin: 'Arabic' },
    { name: 'Rizwan', urduName: 'رضوان', meaning: 'Acceptance, satisfaction', gender: 'Male', origin: 'Arabic' },
    { name: 'Pariza', urduName: 'پریضا', meaning: 'Fairy-like, delicate', gender: 'Female', origin: 'Persian' },
    { name: 'Zavian', urduName: 'زاویان', meaning: 'Bright, radiant', gender: 'Male', origin: 'Arabic' },
    { name: 'Mehrunisa', urduName: 'مہرالنسا', meaning: 'Sun of women', gender: 'Female', origin: 'Persian' },
    { name: 'Ahsan', urduName: 'احسن', meaning: 'The best, most beautiful', gender: 'Male', origin: 'Arabic' },
    { name: 'Rameen', urduName: 'رامین', meaning: 'Successful, joyful', gender: 'Female', origin: 'Persian' },
    { name: 'Baazigar', urduName: 'بازگر', meaning: 'Juggler, performer', gender: 'Male', origin: 'Urdu' },
    { name: 'Zobia', urduName: 'ضوبیا', meaning: 'God’s gift', gender: 'Female', origin: 'Arabic' },
    { name: 'Kashif', urduName: 'کاشف', meaning: 'Revealer, discoverer', gender: 'Male', origin: 'Arabic' },
    { name: 'Sumbal', urduName: 'سنبل', meaning: 'Fragrant flower', gender: 'Female', origin: 'Persian' },
    { name: 'Fayyaz', urduName: 'فیاض', meaning: 'Generous, bountiful', gender: 'Male', origin: 'Arabic' },
    { name: 'Raqeeb', urduName: 'رقيب', meaning: 'Guardian, observer', gender: 'Male', origin: 'Arabic' },
    { name: 'Saqib', urduName: 'ثاقب', meaning: 'Bright, shining star', gender: 'Male', origin: 'Arabic' },
    { name: 'Tameem', urduName: 'تمیم', meaning: 'Complete, perfect', gender: 'Male', origin: 'Arabic' },
    { name: 'Areeb', urduName: 'اریب', meaning: 'Wise, intelligent', gender: 'Male', origin: 'Arabic' },
    { name: 'Nabeel', urduName: 'نبیل', meaning: 'Noble, generous', gender: 'Male', origin: 'Arabic' },
    { name: 'Zorawar', urduName: 'زوراور', meaning: 'Powerful, strong', gender: 'Male', origin: 'Persian' },
    { name: 'Zeeshan', urduName: 'ذیشان', meaning: 'Dignified, respected', gender: 'Male', origin: 'Arabic' },
    { name: 'Rayyan', urduName: 'ریّان', meaning: 'Full of life, door of Heaven', gender: 'Male', origin: 'Arabic' },
    { name: 'Nimrah', urduName: 'نمرہ', meaning: 'Pure, clean', gender: 'Female', origin: 'Arabic' },
    { name: 'Mahrukh', urduName: 'ماہ رخ', meaning: 'Face like the moon', gender: 'Female', origin: 'Persian' },
    { name: 'Anosha', urduName: 'انوشہ', meaning: 'Eternal, immortal', gender: 'Female', origin: 'Persian' },
    { name: 'Daryab', urduName: 'دریاب', meaning: 'Ocean-like, vast', gender: 'Male', origin: 'Persian' },
    { name: 'Navid', urduName: 'نوید', meaning: 'Good news, glad tidings', gender: 'Male', origin: 'Persian' },
    { name: 'Shahzain', urduName: 'شاہزین', meaning: 'Brave, intelligent', gender: 'Male', origin: 'Persian' },
    { name: 'Sanobar', urduName: 'صنوبر', meaning: 'Pine tree', gender: 'Female', origin: 'Persian' },
    { name: 'Farzan', urduName: 'فرزان', meaning: 'Wise, intelligent', gender: 'Male', origin: 'Persian' },
    { name: 'Tajwar', urduName: 'تاجور', meaning: 'Crowned, kingly', gender: 'Male', origin: 'Persian' },
    { name: 'Seher', urduName: 'سحر', meaning: 'Dawn, early morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Nazish', urduName: 'نازش', meaning: 'Pride, honor', gender: 'Female', origin: 'Persian' },
    { name: 'Khizar', urduName: 'خضر', meaning: 'Guide, leader', gender: 'Male', origin: 'Arabic' },
    { name: 'Eshaal', urduName: 'عیشال', meaning: 'Flower of Heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Haroon', urduName: 'ہارون', meaning: 'Chief, leader', gender: 'Male', origin: 'Hebrew' },
    { name: 'Areeha', urduName: 'اریحہ', meaning: 'Fragrant, pure', gender: 'Female', origin: 'Arabic' },
    { name: 'Zayan', urduName: 'زیان', meaning: 'Graceful, ornamented', gender: 'Male', origin: 'Arabic' },
    { name: 'Sarina', urduName: 'سرینا', meaning: 'Calm, serene', gender: 'Female', origin: 'Persian' },
    { name: 'Kaif', urduName: 'کیف', meaning: 'State of joy, ecstasy', gender: 'Male', origin: 'Arabic' },
    { name: 'Hoorain', urduName: 'حورین', meaning: 'Angels of Heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Zameer', urduName: 'ضمیر', meaning: 'Conscience, heart', gender: 'Male', origin: 'Arabic' },
    { name: 'Tuba', urduName: 'طوبیٰ', meaning: 'Blessedness, tree in Heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Jahanzeb', urduName: 'جہانزیب', meaning: 'Beautiful, handsome', gender: 'Male', origin: 'Persian' },
    { name: 'Mahira', urduName: 'ماہرہ', meaning: 'Skilled, expert', gender: 'Female', origin: 'Arabic' },
    { name: 'Shahmir', urduName: 'شاہ میر', meaning: 'King of kings', gender: 'Male', origin: 'Persian' },
    { name: 'Laila', urduName: 'لیلیٰ', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Kamil', urduName: 'کامل', meaning: 'Perfect, complete', gender: 'Male', origin: 'Arabic' },
    { name: 'Mehreen', urduName: 'مہرین', meaning: 'Loving, bright', gender: 'Female', origin: 'Persian' },
    { name: 'Zargham', urduName: 'زَرخَم', meaning: 'Lion, brave', gender: 'Male', origin: 'Arabic' },
    { name: 'Nausheen', urduName: 'نوشین', meaning: 'Sweet, pleasant', gender: 'Female', origin: 'Persian' },
    { name: 'Ashar', urduName: 'اشعر', meaning: 'Wise, observant', gender: 'Male', origin: 'Arabic' },
    { name: 'Nayab', urduName: 'نایاب', meaning: 'Rare, precious', gender: 'Female', origin: 'Persian' },
    { name: 'Saba', urduName: 'سبا', meaning: 'Morning breeze, soft wind', gender: 'Female', origin: 'Arabic' },
    { name: 'Hina', urduName: 'حنا', meaning: 'Fragrance, henna', gender: 'Female', origin: 'Arabic' },
    { name: 'Lina', urduName: 'لینا', meaning: 'Tender, delicate', gender: 'Female', origin: 'Arabic' },
    { name: 'Izza', urduName: 'عزہ', meaning: 'Honor, power', gender: 'Female', origin: 'Arabic' },
    { name: 'Ruba', urduName: 'ربا', meaning: 'Bliss, prosperous', gender: 'Female', origin: 'Arabic' },
    { name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Zoya', urduName: 'زویا', meaning: 'Alive, loving', gender: 'Female', origin: 'Persian' },
    { name: 'Nida', urduName: 'ندا', meaning: 'Call, voice', gender: 'Female', origin: 'Arabic' },
    { name: 'Erum', urduName: 'ارم', meaning: 'Heaven, garden', gender: 'Female', origin: 'Arabic' },
    { name: 'Hiba', urduName: 'ہبہ', meaning: 'Gift, blessing', gender: 'Female', origin: 'Arabic' },
    { name: 'Noor', urduName: 'نور', meaning: 'Light, radiance', gender: 'Female', origin: 'Arabic' },
    { name: 'Sana', urduName: 'ثنا', meaning: 'Praise, eulogy', gender: 'Female', origin: 'Arabic' },
    { name: 'Areeba', urduName: 'اریبہ', meaning: 'Wise, intelligent', gender: 'Female', origin: 'Arabic' },
    { name: 'Bushra', urduName: 'بشریٰ', meaning: 'Good news, glad tidings', gender: 'Female', origin: 'Arabic' },
    { name: 'Esha', urduName: 'عشا', meaning: 'Night prayer', gender: 'Female', origin: 'Arabic' },
    { name: 'Tooba', urduName: 'طوبیٰ', meaning: 'Blessedness, tree in Heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Fiza', urduName: 'فضا', meaning: 'Atmosphere, breeze', gender: 'Female', origin: 'Arabic' },
    { name: 'Shifa', urduName: 'شفا', meaning: 'Healing, cure', gender: 'Female', origin: 'Arabic' },
    { name: 'Rida', urduName: 'رضا', meaning: 'Contentment, satisfaction', gender: 'Female', origin: 'Arabic' },
    { name: 'Aiza', urduName: 'عائزہ', meaning: 'Noble, respectful', gender: 'Female', origin: 'Arabic' },
    { name: 'Amna', urduName: 'آمنہ', meaning: 'Safe, peaceful', gender: 'Female', origin: 'Arabic' },
    { name: 'Saima', urduName: 'صائمہ', meaning: 'Fasting, one who fasts', gender: 'Female', origin: 'Arabic' },
    { name: 'Nashit', urduName: 'نشیط', meaning: 'Energetic, lively', gender: 'Male', origin: 'Arabic' },
    { name: 'Rabia', urduName: 'رابعہ', meaning: 'Spring, breeze', gender: 'Female', origin: 'Arabic' },
    { name: 'Aqsa', urduName: 'اقصیٰ', meaning: 'Farthest, exalted', gender: 'Female', origin: 'Arabic' },
    { name: 'Rameen', urduName: 'رامین', meaning: 'Successful, joyous', gender: 'Female', origin: 'Persian' },
    { name: 'Aiman', urduName: 'ایمن', meaning: 'Blessed, fearless', gender: 'Female', origin: 'Arabic' },
    { name: 'Misha', urduName: 'میشا', meaning: 'Always smiling, eternal', gender: 'Female', origin: 'Hebrew' },
    { name: 'Afreen', urduName: 'آفرین', meaning: 'Praise, to create', gender: 'Female', origin: 'Persian' },
    { name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, early morning', gender: 'Female', origin: 'Arabic' },
    { name: 'Anam', urduName: 'انعم', meaning: 'Blessing, gift', gender: 'Female', origin: 'Arabic' },
    { name: 'Yusra', urduName: 'یسرٰی', meaning: 'Ease, prosperity', gender: 'Female', origin: 'Arabic' },
    { name: 'Sumaira', urduName: 'سمیرا', meaning: 'Brownish, evening star', gender: 'Female', origin: 'Arabic' },
    { name: 'Adeena', urduName: 'ادینہ', meaning: 'Pious, obedient', gender: 'Female', origin: 'Arabic' },
    { name: 'Samina', urduName: 'ثمینہ', meaning: 'Precious, valuable', gender: 'Female', origin: 'Arabic' },
    { name: 'Ishaal', urduName: 'عیشال', meaning: 'Heavenly flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Hoor', urduName: 'حور', meaning: 'Nymph of paradise', gender: 'Female', origin: 'Arabic' },
    { name: 'Eliza', urduName: 'الیزا', meaning: 'Unique, precious', gender: 'Female', origin: 'Hebrew' },
    { name: 'Sabina', urduName: 'سبینہ', meaning: 'Flower, beautiful', gender: 'Female', origin: 'Latin' },
    { name: 'Tania', urduName: 'تانیا', meaning: 'Fairy princess', gender: 'Female', origin: 'Slavic' },
    { name: 'Kiran', urduName: 'کرن', meaning: 'Ray of light', gender: 'Female', origin: 'Urdu' },
    { name: 'Aariz', urduName: 'آریز', meaning: 'Respectable man', gender: 'Male', origin: 'Arabic' },
    { name: 'Afifa', urduName: 'عفیفہ', meaning: 'Virtuous, pious', gender: 'Female', origin: 'Arabic' },
    { name: 'Bashir', urduName: 'بشیر', meaning: 'Bringer of good news', gender: 'Male', origin: 'Arabic' },
    { name: 'Dania', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
    { name: 'Ehsan', urduName: 'احسان', meaning: 'Compassion, kindness', gender: 'Male', origin: 'Arabic' },
    { name: 'Fadwa', urduName: 'فدوی', meaning: 'Self-sacrifice', gender: 'Female', origin: 'Arabic' },
    { name: 'Ghazal', urduName: 'غزال', meaning: 'Poem, love song', gender: 'Female', origin: 'Arabic' },
    { name: 'Hafsa', urduName: 'حفصہ', meaning: 'Prophet\'s wife, young lioness', gender: 'Female', origin: 'Arabic' },
    { name: 'Haroon', urduName: 'ہارون', meaning: 'Lofty, exalted', gender: 'Male', origin: 'Arabic' },
    { name: 'Ilyas', urduName: 'ایلیاس', meaning: 'Prophet\'s name', gender: 'Male', origin: 'Arabic' },
    { name: 'Jameela', urduName: 'جمیلہ', meaning: 'Beautiful', gender: 'Female', origin: 'Arabic' },
    { name: 'Kaif', urduName: 'کائف', meaning: 'Pleasure, joy', gender: 'Male', origin: 'Arabic' },
    { name: 'Layan', urduName: 'لایان', meaning: 'Softness, gentleness', gender: 'Female', origin: 'Arabic' },
    { name: 'Mahira', urduName: 'ماہِرا', meaning: 'Skilled, talented', gender: 'Female', origin: 'Arabic' },
    { name: 'Nashit', urduName: 'نشیط', meaning: 'Energetic, lively', gender: 'Male', origin: 'Arabic' },
    { name: 'Nimrah', urduName: 'نمراہ', meaning: 'Pure, clean', gender: 'Female', origin: 'Arabic' },
    { name: 'Omair', urduName: 'عمیر', meaning: 'Long-lived, life', gender: 'Male', origin: 'Arabic' },
    { name: 'Qasim', urduName: 'قاسم', meaning: 'One who distributes', gender: 'Male', origin: 'Arabic' },
    { name: 'Rameen', urduName: 'رامین', meaning: 'Successful, joyous', gender: 'Female', origin: 'Persian' },
    { name: 'Sadia', urduName: 'سادیہ', meaning: 'Lucky, blessed', gender: 'Female', origin: 'Arabic' },
    { name: 'Sohail', urduName: 'سہیل', meaning: 'Star, smooth', gender: 'Male', origin: 'Arabic' },
    { name: 'Taha', urduName: 'طٰہٰ', meaning: 'Name of Prophet Muhammad', gender: 'Male', origin: 'Arabic' },
    { name: 'Umaira', urduName: 'عمیرہ', meaning: 'Living a long life', gender: 'Female', origin: 'Arabic' },
    { name: 'Wajeeha', urduName: 'وجیہہ', meaning: 'Eminent, distinguished', gender: 'Female', origin: 'Arabic' },
    { name: 'Yumna', urduName: 'یُمنہ', meaning: 'Blessing, fortunate', gender: 'Female', origin: 'Arabic' },
    { name: 'Zaid', urduName: 'زید', meaning: 'Growth, abundance', gender: 'Male', origin: 'Arabic' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Prophet\'s daughter, fragrant flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Adeena', urduName: 'ادینہ', meaning: 'Pious, obedient', gender: 'Female', origin: 'Arabic' },
    { name: 'Arwa', urduName: 'ارویٰ', meaning: 'Mountain goat, gracefulness', gender: 'Female', origin: 'Arabic' },
    { name: 'Bariq', urduName: 'بارق', meaning: 'Shining, bright', gender: 'Male', origin: 'Arabic' },
    { name: 'Bushra', urduName: 'بشریٰ', meaning: 'Good news, glad tidings', gender: 'Female', origin: 'Arabic' },
    { name: 'Camilia', urduName: 'کامیلا', meaning: 'Perfect', gender: 'Female', origin: 'Latin' },
    { name: 'Duaa', urduName: 'دعا', meaning: 'Prayer, invocation', gender: 'Female', origin: 'Arabic' },
    { name: 'Eman', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Female', origin: 'Arabic' },
    { name: 'Faraz', urduName: 'فراز', meaning: 'Elevation, height', gender: 'Male', origin: 'Arabic' },
    { name: 'Gulzar', urduName: 'گلزار', meaning: 'Garden', gender: 'Male', origin: 'Persian' },
    { name: 'Hamza', urduName: 'حمزہ', meaning: 'Lion, strong', gender: 'Male', origin: 'Arabic' },
    { name: 'Iqra', urduName: 'اقْرَأ', meaning: 'Read, recite', gender: 'Female', origin: 'Arabic' },
    { name: 'Junaid', urduName: 'جُنید', meaning: 'Soldier, warrior', gender: 'Male', origin: 'Arabic' },
    { name: 'Khadija', urduName: 'خدیجہ', meaning: 'Prophet\'s first wife', gender: 'Female', origin: 'Arabic' },
    { name: 'Laila', urduName: 'لیلا', meaning: 'Night, beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Mariam', urduName: 'مریم', meaning: 'Pious, mother of Prophet Isa', gender: 'Female', origin: 'Arabic' },
    { name: 'Nabeel', urduName: 'نبیل', meaning: 'Noble, honorable', gender: 'Male', origin: 'Arabic' },
    { name: 'Nazia', urduName: 'نزیہ', meaning: 'Pride, elegance', gender: 'Female', origin: 'Arabic' },
    { name: 'Owais', urduName: 'اوَیس', meaning: 'Little wolf, gift', gender: 'Male', origin: 'Arabic' },
    { name: 'Qamar', urduName: 'قمر', meaning: 'Moon', gender: 'Male', origin: 'Arabic' },
    { name: 'Rania', urduName: 'رانیہ', meaning: 'Gazer, queenly', gender: 'Female', origin: 'Arabic' },
    { name: 'Shayan', urduName: 'شایان', meaning: 'Worthy, deserving', gender: 'Male', origin: 'Persian' },
    { name: 'Talia', urduName: 'تالیہ', meaning: 'Dew of heaven', gender: 'Female', origin: 'Hebrew' },
    { name: 'Zoya', urduName: 'زویا', meaning: 'Alive, loving', gender: 'Female', origin: 'Arabic' },
    { name: 'Abaas', urduName: 'عباس', meaning: 'Lion, severe, stern', gender: 'Male', origin: 'Arabic' },
    { name: 'Ahlam', urduName: 'احلام', meaning: 'Dreams, aspirations', gender: 'Female', origin: 'Arabic' },
    { name: 'Alina', urduName: 'الینا', meaning: 'Fair, beautiful', gender: 'Female', origin: 'Arabic' },
    { name: 'Amira', urduName: 'امیرہ', meaning: 'Princess, leader', gender: 'Female', origin: 'Arabic' },
    { name: 'Anas', urduName: 'انس', meaning: 'Friend, affection', gender: 'Male', origin: 'Arabic' },
    { name: 'Asim', urduName: 'عاصم', meaning: 'Guardian, protector', gender: 'Male', origin: 'Arabic' },
    { name: 'Ayesha', urduName: 'عائشہ', meaning: 'Alive, living', gender: 'Female', origin: 'Arabic' },
    { name: 'Bilal', urduName: 'بلال', meaning: 'Water, moisture', gender: 'Male', origin: 'Arabic' },
    { name: 'Daniah', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
    { name: 'Dawood', urduName: 'داؤد', meaning: 'Beloved, friend', gender: 'Male', origin: 'Arabic' },
    { name: 'Ebrahim', urduName: 'ابراھیم', meaning: 'Prophet\'s name, father of nations', gender: 'Male', origin: 'Hebrew' },
    { name: 'Fariha', urduName: 'فرحہ', meaning: 'Happy, joyful', gender: 'Female', origin: 'Arabic' },
    { name: 'Farhan', urduName: 'فرحان', meaning: 'Happy, cheerful', gender: 'Male', origin: 'Arabic' },
    { name: 'Fatima', urduName: 'فاطمہ', meaning: 'Captivating, daughter of Prophet Muhammad', gender: 'Female', origin: 'Arabic' },
    { name: 'Habiba', urduName: 'حبیبہ', meaning: 'Beloved, dear', gender: 'Female', origin: 'Arabic' },
    { name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
    { name: 'Haya', urduName: 'حیا', meaning: 'Modesty, life', gender: 'Female', origin: 'Arabic' },
    { name: 'Ibrahim', urduName: 'ابراھیم', meaning: 'Father of prophets', gender: 'Male', origin: 'Arabic' },
    { name: 'Imran', urduName: 'عمران', meaning: 'Prosperity, long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Jameel', urduName: 'جمیل', meaning: 'Handsome, beautiful', gender: 'Male', origin: 'Arabic' },
    { name: 'Kamilah', urduName: 'کاملہ', meaning: 'Perfect, complete', gender: 'Female', origin: 'Arabic' },
    { name: 'Khan', urduName: 'خان', meaning: 'Ruler, king', gender: 'Male', origin: 'Turkish' },
    { name: 'Lina', urduName: 'لینا', meaning: 'Tender, delicate', gender: 'Female', origin: 'Arabic' },
    { name: 'Mahir', urduName: 'ماہر', meaning: 'Skilled, expert', gender: 'Male', origin: 'Arabic' },
    { name: 'Mansoor', urduName: 'منصور', meaning: 'Victorious, helped', gender: 'Male', origin: 'Arabic' },
    { name: 'Mariam', urduName: 'مریم', meaning: 'Pious, mother of Prophet Isa', gender: 'Female', origin: 'Hebrew' },
    { name: 'Musa', urduName: 'موسیٰ', meaning: 'Drawn out of the water', gender: 'Male', origin: 'Hebrew' },
    { name: 'Nashwa', urduName: 'نشوہ', meaning: 'Ecstasy, joy', gender: 'Female', origin: 'Arabic' },
    { name: 'Rasheed', urduName: 'رشید', meaning: 'Rightly guided, wise', gender: 'Male', origin: 'Arabic' },
    { name: 'Reem', urduName: 'ریم', meaning: 'White antelope, gazelle', gender: 'Female', origin: 'Arabic' },
    { name: 'Sadiq', urduName: 'صدیق', meaning: 'Truthful, sincere', gender: 'Male', origin: 'Arabic' },
    { name: 'Samiya', urduName: 'سمیعہ', meaning: 'Exalted, high', gender: 'Female', origin: 'Arabic' },
    { name: 'Shifa', urduName: 'شفاء', meaning: 'Healing, cure', gender: 'Female', origin: 'Arabic' },
    { name: 'Suleiman', urduName: 'سلیمان', meaning: 'Man of peace', gender: 'Male', origin: 'Hebrew' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, pathfinder', gender: 'Male', origin: 'Arabic' },
    { name: 'Umar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Waleed', urduName: 'ولید', meaning: 'Newborn, son', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
    { name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases, name of a Prophet', gender: 'Male', origin: 'Hebrew' },
    { name: 'Zain', urduName: 'زین', meaning: 'Beauty, grace', gender: 'Male', origin: 'Arabic' },
    { name: 'Zaynab', urduName: 'زینب', meaning: 'Fragrant flower, Prophet\'s daughter', gender: 'Female', origin: 'Arabic' },
    { name: 'Zohra', urduName: 'زہرا', meaning: 'Radiant, brilliant', gender: 'Female', origin: 'Arabic' },
    { name: 'Zulfiqar', urduName: 'ذوالفقار', meaning: 'Sword of Ali, sharp', gender: 'Male', origin: 'Arabic' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Fragrant flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Ameen', urduName: 'امین', meaning: 'Trustworthy, faithful', gender: 'Male', origin: 'Arabic' },
    { name: 'Raheel', urduName: 'راحیل', meaning: 'One who shows the way', gender: 'Male', origin: 'Arabic' },
    { name: 'Hiba', urduName: 'ہبہ', meaning: 'Gift, present', gender: 'Female', origin: 'Arabic' },
    { name: 'Jalal', urduName: 'جلال', meaning: 'Majesty, grandeur', gender: 'Male', origin: 'Arabic' },
    { name: 'Rashida', urduName: 'رشیدہ', meaning: 'Rightly guided, wise', gender: 'Female', origin: 'Arabic' },
    { name: 'Suleiman', urduName: 'سلیمان', meaning: 'Man of peace', gender: 'Male', origin: 'Arabic' },
    { name: 'Yumna', urduName: 'یمنہ', meaning: 'Blessing, fortunate', gender: 'Female', origin: 'Arabic' },
    { name: 'Zakiya', urduName: 'زکیہ', meaning: 'Pure, clean', gender: 'Female', origin: 'Arabic' },
    { name: 'Aadila', urduName: 'عادلہ', meaning: 'Just, fair', gender: 'Female', origin: 'Arabic' },
    { name: 'Abeer', urduName: 'عبیر', meaning: 'Fragrance, scent', gender: 'Female', origin: 'Arabic' },
    { name: 'Ahsan', urduName: 'احسن', meaning: 'The best, most beautiful', gender: 'Male', origin: 'Arabic' },
    { name: 'Amna', urduName: 'آمنہ', meaning: 'Safe, secure', gender: 'Female', origin: 'Arabic' },
    { name: 'Anwar', urduName: 'انوار', meaning: 'Radiant, luminous', gender: 'Male', origin: 'Arabic' },
    { name: 'Arif', urduName: 'عارف', meaning: 'Knowledgeable, wise', gender: 'Male', origin: 'Arabic' },
    { name: 'Asra', urduName: 'اسرٰ', meaning: 'Night journey, traveler', gender: 'Female', origin: 'Arabic' },
    { name: 'Ayah', urduName: 'آیہ', meaning: 'Sign, miracle', gender: 'Female', origin: 'Arabic' },
    { name: 'Bashira', urduName: 'بشیرہ', meaning: 'Bringer of good news', gender: 'Female', origin: 'Arabic' },
    { name: 'Danish', urduName: 'دانش', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Persian' },
    { name: 'Dua', urduName: 'دعا', meaning: 'Prayer, supplication', gender: 'Female', origin: 'Arabic' },
    { name: 'Fahad', urduName: 'فہد', meaning: 'Panther, leopard', gender: 'Male', origin: 'Arabic' },
    { name: 'Faris', urduName: 'فارس', meaning: 'Knight, horseman', gender: 'Male', origin: 'Arabic' },
    { name: 'Farooq', urduName: 'فاروق', meaning: 'One who distinguishes between right and wrong', gender: 'Male', origin: 'Arabic' },
    { name: 'Fayza', urduName: 'فائزہ', meaning: 'Victorious, successful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ghazi', urduName: 'غازی', meaning: 'Warrior, conqueror', gender: 'Male', origin: 'Arabic' },
    { name: 'Hassanah', urduName: 'حسانہ', meaning: 'Good, beautiful', gender: 'Female', origin: 'Arabic' },
    { name: 'Ibrahim', urduName: 'ابراھیم', meaning: 'Prophet Ibrahim', gender: 'Male', origin: 'Arabic' },
    { name: 'Imaan', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Female', origin: 'Arabic' },
    { name: 'Irfan', urduName: 'عرفان', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Arabic' },
    { name: 'Jameel', urduName: 'جمیل', meaning: 'Handsome, beautiful', gender: 'Male', origin: 'Arabic' },
    { name: 'Jannah', urduName: 'جنہ', meaning: 'Paradise, heaven', gender: 'Female', origin: 'Arabic' },
    { name: 'Khalid', urduName: 'خالد', meaning: 'Eternal, immortal', gender: 'Male', origin: 'Arabic' },
    { name: 'Khadija', urduName: 'خدیجہ', meaning: 'First wife of the Prophet Muhammad', gender: 'Female', origin: 'Arabic' },
    { name: 'Lailah', urduName: 'لیلہ', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Maimuna', urduName: 'میمونہ', meaning: 'Blessed, fortunate', gender: 'Female', origin: 'Arabic' },
    { name: 'Malik', urduName: 'مالک', meaning: 'King, ruler', gender: 'Male', origin: 'Arabic' },
    { name: 'Mansoor', urduName: 'منصور', meaning: 'Victorious, helped', gender: 'Male', origin: 'Arabic' },
    { name: 'Maya', urduName: 'میا', meaning: 'Princess, the one who is loved', gender: 'Female', origin: 'Arabic' },
    { name: 'Muneeb', urduName: 'مُنِیب', meaning: 'Penitent, one who turns to Allah', gender: 'Male', origin: 'Arabic' },
    { name: 'Nadia', urduName: 'نادیہ', meaning: 'Hope, caller', gender: 'Female', origin: 'Arabic' },
    { name: 'Nashit', urduName: 'نشیط', meaning: 'Energetic, lively', gender: 'Male', origin: 'Arabic' },
    { name: 'Nawaf', urduName: 'نواف', meaning: 'High, elevated', gender: 'Male', origin: 'Arabic' },
    { name: 'Rashid', urduName: 'رشید', meaning: 'Rightly guided, wise', gender: 'Male', origin: 'Arabic' },
    { name: 'Rida', urduName: 'رِضا', meaning: 'Contentment, satisfaction', gender: 'Female', origin: 'Arabic' },
    { name: 'Sabeen', urduName: 'سبین', meaning: 'Patient, content', gender: 'Female', origin: 'Arabic' },
    { name: 'Salma', urduName: 'سلمیٰ', meaning: 'Safe, peaceful', gender: 'Female', origin: 'Arabic' },
    { name: 'Sami', urduName: 'سامی', meaning: 'Elevated, sublime', gender: 'Male', origin: 'Arabic' },
    { name: 'Sana', urduName: 'ثنا', meaning: 'Praise, resplendence', gender: 'Female', origin: 'Arabic' },
    { name: 'Shahida', urduName: 'شاہدہ', meaning: 'Witness, martyr', gender: 'Female', origin: 'Arabic' },
    { name: 'Shamil', urduName: 'شامل', meaning: 'Complete, whole', gender: 'Male', origin: 'Arabic' },
    { name: 'Sulaiman', urduName: 'سلیمان', meaning: 'Man of peace, Prophet\'s name', gender: 'Male', origin: 'Hebrew' },
    { name: 'Sumaya', urduName: 'سمیہ', meaning: 'High above', gender: 'Female', origin: 'Arabic' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, pathfinder', gender: 'Male', origin: 'Arabic' },
    { name: 'Umar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Wahid', urduName: 'واحِد', meaning: 'Unique, one', gender: 'Male', origin: 'Arabic' },
    { name: 'Warda', urduName: 'وردہ', meaning: 'Rose, flower', gender: 'Female', origin: 'Arabic' },
    { name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
    { name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases, Prophet\'s name', gender: 'Male', origin: 'Hebrew' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Fragrant flower, Prophet\'s daughter', gender: 'Female', origin: 'Arabic' },
    { name: 'Zayd', urduName: 'زید', meaning: 'Growth, abundance', gender: 'Male', origin: 'Arabic' },
    { name: 'Zayna', urduName: 'زینۃ', meaning: 'Beauty, grace', gender: 'Female', origin: 'Arabic' },
    { name: 'Aadam', urduName: 'آدم', meaning: 'Father of humanity, first man', gender: 'Male', origin: 'Hebrew' },
    { name: 'Abaad', urduName: 'آباد', meaning: 'Flourishing, prosperous', gender: 'Male', origin: 'Arabic' },
    { name: 'Adham', urduName: 'ادم', meaning: 'Dark, black, strong', gender: 'Male', origin: 'Arabic' },
    { name: 'Alia', urduName: 'علیہ', meaning: 'Exalted, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Asher', urduName: 'اشر', meaning: 'Blessed, fortunate', gender: 'Male', origin: 'Hebrew' },
    { name: 'Azhar', urduName: 'اظہر', meaning: 'Radiant, shining', gender: 'Male', origin: 'Arabic' },
    { name: 'Bariha', urduName: 'بارِہ', meaning: 'A woman of high stature', gender: 'Female', origin: 'Arabic' },
    { name: 'Bashir', urduName: 'بشیر', meaning: 'Bringer of good news', gender: 'Male', origin: 'Arabic' },
    { name: 'Benaisha', urduName: 'بنیہشا', meaning: 'A person of good fortune', gender: 'Female', origin: 'Arabic' },
    { name: 'Dara', urduName: 'دارا', meaning: 'Wealthy, noble', gender: 'Male', origin: 'Persian' },
    { name: 'Dua', urduName: 'دعا', meaning: 'Supplication, prayer', gender: 'Female', origin: 'Arabic' },
    { name: 'Fadil', urduName: 'فاضل', meaning: 'Virtuous, superior', gender: 'Male', origin: 'Arabic' },
    { name: 'Fariyal', urduName: 'فریال', meaning: 'Angel-like, a beautiful woman', gender: 'Female', origin: 'Persian' },
    { name: 'Ghaus', urduName: 'غوث', meaning: 'Helper, supporter', gender: 'Male', origin: 'Arabic' },
    { name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
    { name: 'Hidaya', urduName: 'ہدایہ', meaning: 'Guidance, leadership', gender: 'Female', origin: 'Arabic' },
    { name: 'Inaya', urduName: 'عنایہ', meaning: 'Concern, care, support', gender: 'Female', origin: 'Arabic' },
    { name: 'Iqra', urduName: 'اقرء', meaning: 'To read, to recite', gender: 'Female', origin: 'Arabic' },
    { name: 'Ismail', urduName: 'اسماعیل', meaning: 'God will hear', gender: 'Male', origin: 'Hebrew' },
    { name: 'Jadid', urduName: 'جدید', meaning: 'New, fresh', gender: 'Male', origin: 'Arabic' },
    { name: 'Jameela', urduName: 'جمیلۃ', meaning: 'Beautiful, graceful', gender: 'Female', origin: 'Arabic' },
    { name: 'Kamran', urduName: 'کامران', meaning: 'Successful, prosperous', gender: 'Male', origin: 'Persian' },
    { name: 'Karima', urduName: 'کریمۃ', meaning: 'Generous, noble', gender: 'Female', origin: 'Arabic' },
    { name: 'Khalid', urduName: 'خالد', meaning: 'Eternal, immortal', gender: 'Male', origin: 'Arabic' },
    { name: 'Kiran', urduName: 'کرن', meaning: 'Ray of light', gender: 'Female', origin: 'Persian' },
    { name: 'Laila', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
    { name: 'Maimuna', urduName: 'میمونہ', meaning: 'Blessed, fortunate', gender: 'Female', origin: 'Arabic' },
    { name: 'Malik', urduName: 'مالک', meaning: 'King, master', gender: 'Male', origin: 'Arabic' },
    { name: 'Mansoor', urduName: 'منصور', meaning: 'Victorious', gender: 'Male', origin: 'Arabic' },
    { name: 'Mashael', urduName: 'مشاعل', meaning: 'Torch, light', gender: 'Female', origin: 'Arabic' },
    { name: 'Musa', urduName: 'موسیٰ', meaning: 'Prophet’s name, drawn from the water', gender: 'Male', origin: 'Hebrew' },
    { name: 'Nadia', urduName: 'نادیہ', meaning: 'Hope, caller', gender: 'Female', origin: 'Arabic' },
    { name: 'Nashit', urduName: 'نشیط', meaning: 'Energetic, lively', gender: 'Male', origin: 'Arabic' },
    { name: 'Rahil', urduName: 'راحیل', meaning: 'Traveler, one who leaves', gender: 'Male', origin: 'Arabic' },
    { name: 'Rida', urduName: 'رِضا', meaning: 'Contentment, satisfaction', gender: 'Female', origin: 'Arabic' },
    { name: 'Sabeen', urduName: 'سبین', meaning: 'Patient, content', gender: 'Female', origin: 'Arabic' },
    { name: 'Sana', urduName: 'ثنا', meaning: 'Praise, resplendence', gender: 'Female', origin: 'Arabic' },
    { name: 'Shahida', urduName: 'شاہدہ', meaning: 'Witness, martyr', gender: 'Female', origin: 'Arabic' },
    { name: 'Shamil', urduName: 'شامل', meaning: 'Complete, whole', gender: 'Male', origin: 'Arabic' },
    { name: 'Sulaiman', urduName: 'سلیمان', meaning: 'Man of peace, Prophet’s name', gender: 'Male', origin: 'Hebrew' },
    { name: 'Sumaya', urduName: 'سمیہ', meaning: 'High above, elevated', gender: 'Female', origin: 'Arabic' },
    { name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, pathfinder', gender: 'Male', origin: 'Arabic' },
    { name: 'Umar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
    { name: 'Wahid', urduName: 'واحِد', meaning: 'Unique, one', gender: 'Male', origin: 'Arabic' },
    { name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
    { name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases, Prophet’s name', gender: 'Male', origin: 'Hebrew' },
    { name: 'Zainab', urduName: 'زینب', meaning: 'Fragrant flower, Prophet’s daughter', gender: 'Female', origin: 'Arabic' },
    { name: 'Aabid', urduName: 'عابد', meaning: 'Worshipper, devoted to God', gender: 'Male', origin: 'Arabic' },
{ name: 'Aasma', urduName: 'آسمہ', meaning: 'Pure, unblemished', gender: 'Female', origin: 'Arabic' },
{ name: 'Amal', urduName: 'امَل', meaning: 'Hope, aspiration', gender: 'Female', origin: 'Arabic' },
{ name: 'Anis', urduName: 'انیس', meaning: 'Companion, close friend', gender: 'Male', origin: 'Arabic' },
{ name: 'Areeba', urduName: 'اریبہ', meaning: 'Wise, intelligent', gender: 'Female', origin: 'Arabic' },
{ name: 'Arif', urduName: 'عارف', meaning: 'Knowledgeable, aware', gender: 'Male', origin: 'Arabic' },
{ name: 'Asim', urduName: 'عاصم', meaning: 'Protector, guardian', gender: 'Male', origin: 'Arabic' },
{ name: 'Badiha', urduName: 'بادیہ', meaning: 'Innovative, creative', gender: 'Female', origin: 'Arabic' },
{ name: 'Bashira', urduName: 'بشیرہ', meaning: 'Bringer of glad tidings', gender: 'Female', origin: 'Arabic' },
{ name: 'Bari', urduName: 'باری', meaning: 'The maker, creator', gender: 'Male', origin: 'Arabic' },
{ name: 'Dina', urduName: 'دینا', meaning: 'Judgment, strength', gender: 'Female', origin: 'Hebrew' },
{ name: 'Danish', urduName: 'دانش', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Persian' },
{ name: 'Faris', urduName: 'فارس', meaning: 'Knight, horseman', gender: 'Male', origin: 'Arabic' },
{ name: 'Fawad', urduName: 'فواد', meaning: 'Heart, feeling', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghina', urduName: 'غینہ', meaning: 'Wealth, richness', gender: 'Female', origin: 'Arabic' },
{ name: 'Hafiz', urduName: 'حافظ', meaning: 'Memorizer of the Quran', gender: 'Male', origin: 'Arabic' },
{ name: 'Hira', urduName: 'ہِرا', meaning: 'A mountain, precious stone', gender: 'Female', origin: 'Arabic' },
{ name: 'Imad', urduName: 'عماد', meaning: 'Pillar, support', gender: 'Male', origin: 'Arabic' },
{ name: 'Inas', urduName: 'انس', meaning: 'Friendly, companionable', gender: 'Male', origin: 'Arabic' },
{ name: 'Iqbal', urduName: 'اقبال', meaning: 'Prosperity, success', gender: 'Male', origin: 'Arabic' },
{ name: 'Ishaq', urduName: 'اسحاق', meaning: 'Laughter, joy', gender: 'Male', origin: 'Hebrew' },
{ name: 'Jameel', urduName: 'جمیل', meaning: 'Handsome, beautiful', gender: 'Male', origin: 'Arabic' },
{ name: 'Jasira', urduName: 'جاسِرہ', meaning: 'Brave, courageous', gender: 'Female', origin: 'Arabic' },
{ name: 'Kamilah', urduName: 'کاملہ', meaning: 'Perfect, complete', gender: 'Female', origin: 'Arabic' },
{ name: 'Khanza', urduName: 'خانزہ', meaning: 'A leader', gender: 'Female', origin: 'Arabic' },
{ name: 'Labeeb', urduName: 'لبیب', meaning: 'Sensible, intelligent', gender: 'Male', origin: 'Arabic' },
{ name: 'Lina', urduName: 'لینا', meaning: 'Tender, delicate', gender: 'Female', origin: 'Arabic' },
{ name: 'Mariam', urduName: 'مریم', meaning: 'Pious, mother of Isa', gender: 'Female', origin: 'Hebrew' },
{ name: 'Marwan', urduName: 'مروان', meaning: 'A stone, strong', gender: 'Male', origin: 'Arabic' },
{ name: 'Mazin', urduName: 'مازن', meaning: 'Proper name, cloud that brings rain', gender: 'Male', origin: 'Arabic' },
{ name: 'Muneeb', urduName: 'مُنیب', meaning: 'Repentant, one who returns to God', gender: 'Male', origin: 'Arabic' },
{ name: 'Naila', urduName: 'نائلہ', meaning: 'Attainer, successful', gender: 'Female', origin: 'Arabic' },
{ name: 'Nashit', urduName: 'نشیط', meaning: 'Active, energetic', gender: 'Male', origin: 'Arabic' },
{ name: 'Niamat', urduName: 'نعمت', meaning: 'Blessing, grace', gender: 'Female', origin: 'Arabic' },
{ name: 'Omar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Rayan', urduName: 'ریان', meaning: 'Gates of Heaven, lush', gender: 'Male', origin: 'Arabic' },
{ name: 'Raza', urduName: 'رضا', meaning: 'Contentment, acceptance', gender: 'Male', origin: 'Arabic' },
{ name: 'Ruba', urduName: 'ربا', meaning: 'Good, highland', gender: 'Female', origin: 'Arabic' },
{ name: 'Safa', urduName: 'صفا', meaning: 'Purity, clarity', gender: 'Female', origin: 'Arabic' },
{ name: 'Samar', urduName: 'ثمر', meaning: 'Fruit, reward', gender: 'Female', origin: 'Arabic' },
{ name: 'Shabir', urduName: 'شابر', meaning: 'Patient, persevering', gender: 'Male', origin: 'Arabic' },
{ name: 'Shams', urduName: 'شمس', meaning: 'Sun', gender: 'Male', origin: 'Arabic' },
{ name: 'Suhail', urduName: 'سهیل', meaning: 'A star, easy-going', gender: 'Male', origin: 'Arabic' },
{ name: 'Talha', urduName: 'طلحہ', meaning: 'A tree, name of a companion of the Prophet', gender: 'Male', origin: 'Arabic' },
{ name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, one who knocks', gender: 'Male', origin: 'Arabic' },
{ name: 'Zaki', urduName: 'ذکی', meaning: 'Pure, intelligent', gender: 'Male', origin: 'Arabic' },
{ name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
{ name: 'Aabira', urduName: 'آبِرہ', meaning: 'The one who returns', gender: 'Female', origin: 'Arabic' },
{ name: 'Aftab', urduName: 'آفتاب', meaning: 'Sunlight', gender: 'Male', origin: 'Persian' },
{ name: 'Ahsan', urduName: 'احسن', meaning: 'Best, most beautiful', gender: 'Male', origin: 'Arabic' },
{ name: 'Alayna', urduName: 'الینا', meaning: 'Noble, kind', gender: 'Female', origin: 'Arabic' },
{ name: 'Anwar', urduName: 'انوار', meaning: 'Luminous, radiant', gender: 'Male', origin: 'Arabic' },
{ name: 'Areeb', urduName: 'اریب', meaning: 'Intelligent, skilled', gender: 'Male', origin: 'Arabic' },
{ name: 'Arisha', urduName: 'آریشہ', meaning: 'Heavenly, high', gender: 'Female', origin: 'Arabic' },
{ name: 'Asrar', urduName: 'اسرار', meaning: 'Secrets, mysteries', gender: 'Male', origin: 'Arabic' },
{ name: 'Baqiya', urduName: 'باقیہ', meaning: 'Remaining, everlasting', gender: 'Female', origin: 'Arabic' },
{ name: 'Barir', urduName: 'باریر', meaning: 'Pious, virtuous', gender: 'Male', origin: 'Arabic' },
{ name: 'Daniah', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
{ name: 'Danish', urduName: 'دانش', meaning: 'Knowledge, wisdom', gender: 'Male', origin: 'Persian' },
{ name: 'Ehsan', urduName: 'احسان', meaning: 'Benevolence, good deed', gender: 'Male', origin: 'Arabic' },
{ name: 'Fahim', urduName: 'فہیم', meaning: 'Intelligent, perceptive', gender: 'Male', origin: 'Arabic' },
{ name: 'Farid', urduName: 'فرید', meaning: 'Unique, matchless', gender: 'Male', origin: 'Arabic' },
{ name: 'Fiza', urduName: 'فضہ', meaning: 'Wind, breeze', gender: 'Female', origin: 'Arabic' },
{ name: 'Ghaith', urduName: 'غیث', meaning: 'Rain, help', gender: 'Male', origin: 'Arabic' },
{ name: 'Hadiya', urduName: 'ہادیہ', meaning: 'Guidance, gift', gender: 'Female', origin: 'Arabic' },
{ name: 'Haseeb', urduName: 'حسیب', meaning: 'Noble, esteemed', gender: 'Male', origin: 'Arabic' },
{ name: 'Huda', urduName: 'ہدیٰ', meaning: 'Guidance', gender: 'Female', origin: 'Arabic' },
{ name: 'Imaan', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Male', origin: 'Arabic' },
{ name: 'Inaaya', urduName: 'عنایہ', meaning: 'Concern, care, attention', gender: 'Female', origin: 'Arabic' },
{ name: 'Israr', urduName: 'اسرار', meaning: 'Secrets, mysteries', gender: 'Male', origin: 'Arabic' },
{ name: 'Jadid', urduName: 'جدید', meaning: 'New, fresh', gender: 'Male', origin: 'Arabic' },
{ name: 'Jamila', urduName: 'جمیلہ', meaning: 'Beautiful, graceful', gender: 'Female', origin: 'Arabic' },
{ name: 'Kadir', urduName: 'قدیر', meaning: 'Powerful, capable', gender: 'Male', origin: 'Arabic' },
{ name: 'Kamilah', urduName: 'کاملہ', meaning: 'Perfect, complete', gender: 'Female', origin: 'Arabic' },
{ name: 'Kinan', urduName: 'کینان', meaning: 'To settle, to remain', gender: 'Male', origin: 'Arabic' },
{ name: 'Laila', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
{ name: 'Lina', urduName: 'لینا', meaning: 'Tender, delicate', gender: 'Female', origin: 'Arabic' },
{ name: 'Madiha', urduName: 'مدیحہ', meaning: 'Praiseworthy', gender: 'Female', origin: 'Arabic' },
{ name: 'Mansoor', urduName: 'منصور', meaning: 'Victorious', gender: 'Male', origin: 'Arabic' },
{ name: 'Muneeb', urduName: 'مُنیب', meaning: 'Repentant, one who returns to God', gender: 'Male', origin: 'Arabic' },
{ name: 'Naila', urduName: 'نائلہ', meaning: 'Attainer, successful', gender: 'Female', origin: 'Arabic' },
{ name: 'Naseem', urduName: 'نسیم', meaning: 'Breeze, fresh air', gender: 'Male', origin: 'Arabic' },
{ name: 'Rasha', urduName: 'رشا', meaning: 'Young deer', gender: 'Female', origin: 'Arabic' },
{ name: 'Sami', urduName: 'سامی', meaning: 'Exalted, lofty', gender: 'Male', origin: 'Arabic' },
{ name: 'Samiya', urduName: 'سمیہ', meaning: 'Elevated, sublime', gender: 'Female', origin: 'Arabic' },
{ name: 'Shamsa', urduName: 'شمسه', meaning: 'Sunshine, radiant', gender: 'Female', origin: 'Arabic' },
{ name: 'Zahra', urduName: 'زہرا', meaning: 'Radiant, blooming flower', gender: 'Female', origin: 'Arabic' },
{ name: 'Zayn', urduName: 'زین', meaning: 'Grace, beauty', gender: 'Male', origin: 'Arabic' },
{ name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
{ name: 'Aadiya', urduName: 'آدِیہ', meaning: 'First, beginning', gender: 'Female', origin: 'Arabic' },
{ name: 'Abeera', urduName: 'عبیرہ', meaning: 'Strong, powerful', gender: 'Female', origin: 'Arabic' },
{ name: 'Aiman', urduName: 'ایمان', meaning: 'Righteous, blessed', gender: 'Male', origin: 'Arabic' },
{ name: 'Alam', urduName: 'عالم', meaning: 'World, universe', gender: 'Male', origin: 'Arabic' },
{ name: 'Amira', urduName: 'امِیرہ', meaning: 'Princess, leader', gender: 'Female', origin: 'Arabic' },
{ name: 'Anza', urduName: 'انزا', meaning: 'Graceful, radiant', gender: 'Female', origin: 'Arabic' },
{ name: 'Areeb', urduName: 'اریب', meaning: 'Smart, intelligent', gender: 'Male', origin: 'Arabic' },
{ name: 'Azra', urduName: 'ازرا', meaning: 'Virgin, pure', gender: 'Female', origin: 'Arabic' },
{ name: 'Basil', urduName: 'باسل', meaning: 'Brave, valiant', gender: 'Male', origin: 'Arabic' },
{ name: 'Bariyah', urduName: 'باریہ', meaning: 'Pure, innocent', gender: 'Female', origin: 'Arabic' },
{ name: 'Diyan', urduName: 'دیان', meaning: 'Judge, leader', gender: 'Male', origin: 'Arabic' },
{ name: 'Dunia', urduName: 'دُنیا', meaning: 'World, earth', gender: 'Female', origin: 'Arabic' },
{ name: 'Elham', urduName: 'الہام', meaning: 'Inspiration, intuition', gender: 'Female', origin: 'Arabic' },
{ name: 'Ehsan', urduName: 'احسان', meaning: 'Goodness, favor', gender: 'Male', origin: 'Arabic' },
{ name: 'Faiza', urduName: 'فائزہ', meaning: 'Victorious, successful', gender: 'Female', origin: 'Arabic' },
{ name: 'Fariq', urduName: 'فارق', meaning: 'Distinguished, unique', gender: 'Male', origin: 'Arabic' },
{ name: 'Gibran', urduName: 'جبران', meaning: 'Reward, compensation', gender: 'Male', origin: 'Arabic' },
{ name: 'Gulsher', urduName: 'گُل شیر', meaning: 'Lion of flowers', gender: 'Male', origin: 'Persian' },
{ name: 'Hanan', urduName: 'حنان', meaning: 'Compassion, tenderness', gender: 'Male', origin: 'Arabic' },
{ name: 'Hiba', urduName: 'ہِبہ', meaning: 'Gift, present', gender: 'Female', origin: 'Arabic' },
{ name: 'Iman', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Female', origin: 'Arabic' },
{ name: 'Iqra', urduName: 'اقْرَأ', meaning: 'To read, recite', gender: 'Female', origin: 'Arabic' },
{ name: 'Janan', urduName: 'جانان', meaning: 'Beloved, life', gender: 'Female', origin: 'Persian' },
{ name: 'Jazira', urduName: 'جزیرہ', meaning: 'Island, peninsula', gender: 'Female', origin: 'Arabic' },
{ name: 'Kashan', urduName: 'کاشان', meaning: 'Place of work, skill', gender: 'Male', origin: 'Persian' },
{ name: 'Kanz', urduName: 'کنز', meaning: 'Treasure, hidden wealth', gender: 'Male', origin: 'Arabic' },
{ name: 'Layla', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
{ name: 'Lina', urduName: 'لینا', meaning: 'Delicate, soft', gender: 'Female', origin: 'Arabic' },
{ name: 'Maahir', urduName: 'ماہر', meaning: 'Expert, skilled', gender: 'Male', origin: 'Arabic' },
{ name: 'Madiha', urduName: 'مدیحہ', meaning: 'Praiseworthy, praised', gender: 'Female', origin: 'Arabic' },
{ name: 'Mansur', urduName: 'منصور', meaning: 'Victorious, aided', gender: 'Male', origin: 'Arabic' },
{ name: 'Marwa', urduName: 'مروہ', meaning: 'A mountain in Mecca', gender: 'Female', origin: 'Arabic' },
{ name: 'Mazin', urduName: 'مازن', meaning: 'Proper name, cloud that brings rain', gender: 'Male', origin: 'Arabic' },
{ name: 'Nadir', urduName: 'نادِر', meaning: 'Rare, precious', gender: 'Male', origin: 'Arabic' },
{ name: 'Nadia', urduName: 'نادِیہ', meaning: 'Caller, announcer', gender: 'Female', origin: 'Arabic' },
{ name: 'Najah', urduName: 'نجاح', meaning: 'Success, achievement', gender: 'Female', origin: 'Arabic' },
{ name: 'Rafa', urduName: 'رفاعہ', meaning: 'Exaltation, lifting up', gender: 'Female', origin: 'Arabic' },
{ name: 'Rayan', urduName: 'ریان', meaning: 'Gates of Heaven', gender: 'Male', origin: 'Arabic' },
{ name: 'Sahil', urduName: 'ساحل', meaning: 'Shore, coast', gender: 'Male', origin: 'Arabic' },
{ name: 'Samar', urduName: 'ثمر', meaning: 'Fruit, reward', gender: 'Female', origin: 'Arabic' },
{ name: 'Shaheer', urduName: 'شاہیر', meaning: 'Well-known, famous', gender: 'Male', origin: 'Arabic' },
{ name: 'Suhaila', urduName: 'سهیلا', meaning: 'Star, easy-going', gender: 'Female', origin: 'Arabic' },
{ name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, one who knocks', gender: 'Male', origin: 'Arabic' },
{ name: 'Tazeen', urduName: 'تزئین', meaning: 'Adornment, decoration', gender: 'Female', origin: 'Arabic' },
{ name: 'Zaki', urduName: 'ذکی', meaning: 'Pure, intelligent', gender: 'Male', origin: 'Arabic' },
{ name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
{ name: 'Aalam', urduName: 'عالم', meaning: 'World, universe', gender: 'Male', origin: 'Arabic' },
{ name: 'Aalya', urduName: 'عالیہ', meaning: 'Exalted, noble', gender: 'Female', origin: 'Arabic' },
{ name: 'Asim', urduName: 'عاصم', meaning: 'Guardian, protector', gender: 'Male', origin: 'Arabic' },
{ name: 'Baha', urduName: 'بہاء', meaning: 'Splendor, magnificence', gender: 'Male', origin: 'Arabic' },
{ name: 'Bana', urduName: 'بَنا', meaning: 'The one who is built, a builder', gender: 'Female', origin: 'Arabic' },
{ name: 'Bayan', urduName: 'بیان', meaning: 'Expression, eloquence', gender: 'Male', origin: 'Arabic' },
{ name: 'Dala', urduName: 'دالا', meaning: 'Guide, path', gender: 'Female', origin: 'Arabic' },
{ name: 'Fariyah', urduName: 'فریہ', meaning: 'Joyful, cheerful', gender: 'Female', origin: 'Arabic' },
{ name: 'Faseeh', urduName: 'فصیح', meaning: 'Fluent, eloquent', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghazal', urduName: 'غزال', meaning: 'A type of poetic song, deer', gender: 'Female', origin: 'Arabic' },
{ name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
{ name: 'Hidaya', urduName: 'ہدایہ', meaning: 'Guidance', gender: 'Female', origin: 'Arabic' },
{ name: 'Inas', urduName: 'انس', meaning: 'Friendly, sociable', gender: 'Male', origin: 'Arabic' },
{ name: 'Isha', urduName: 'عشا', meaning: 'Night prayer', gender: 'Female', origin: 'Arabic' },
{ name: 'Jameela', urduName: 'جمیلہ', meaning: 'Beautiful, lovely', gender: 'Female', origin: 'Arabic' },
{ name: 'Jamil', urduName: 'جمیل', meaning: 'Handsome, beautiful', gender: 'Male', origin: 'Arabic' },
{ name: 'Kareem', urduName: 'کریم', meaning: 'Generous, noble', gender: 'Male', origin: 'Arabic' },
{ name: 'Kenza', urduName: 'کِنزہ', meaning: 'Treasure, wealth', gender: 'Female', origin: 'Arabic' },
{ name: 'Lina', urduName: 'لینا', meaning: 'Tender, soft', gender: 'Female', origin: 'Arabic' },
{ name: 'Musa', urduName: 'موسی', meaning: 'Saved from water, prophet’s name', gender: 'Male', origin: 'Arabic' },
{ name: 'Meryem', urduName: 'مریم', meaning: 'Beloved, rebellious, virtuous', gender: 'Female', origin: 'Arabic' },
{ name: 'Mokha', urduName: 'مخہ', meaning: 'Sleek, shining', gender: 'Female', origin: 'Arabic' },
{ name: 'Nashit', urduName: 'نشیط', meaning: 'Active, energetic', gender: 'Male', origin: 'Arabic' },
{ name: 'Nour', urduName: 'نور', meaning: 'Light, radiance', gender: 'Female', origin: 'Arabic' },
{ name: 'Omar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Omayma', urduName: 'امیمہ', meaning: 'Little mother', gender: 'Female', origin: 'Arabic' },
{ name: 'Qamar', urduName: 'قمر', meaning: 'Moon, bright', gender: 'Male', origin: 'Arabic' },
{ name: 'Qudrat', urduName: 'قدرت', meaning: 'Power, capability', gender: 'Male', origin: 'Arabic' },
{ name: 'Rida', urduName: 'رضا', meaning: 'Contentment, satisfaction', gender: 'Female', origin: 'Arabic' },
{ name: 'Rafiq', urduName: 'رفیق', meaning: 'Companion, friend', gender: 'Male', origin: 'Arabic' },
{ name: 'Rukhshan', urduName: 'رخشان', meaning: 'Shining, bright', gender: 'Male', origin: 'Persian' },
{ name: 'Sadiq', urduName: 'صدیق', meaning: 'Truthful, honest', gender: 'Male', origin: 'Arabic' },
{ name: 'Saha', urduName: 'صحہ', meaning: 'Health, well-being', gender: 'Female', origin: 'Arabic' },
{ name: 'Sariyah', urduName: 'سارِیہ', meaning: 'Cloud, night traveler', gender: 'Female', origin: 'Arabic' },
{ name: 'Shahd', urduName: 'شہد', meaning: 'Honey, sweet', gender: 'Female', origin: 'Arabic' },
{ name: 'Suleman', urduName: 'سلیمان', meaning: 'Peaceful, the prophet’s name', gender: 'Male', origin: 'Arabic' },
{ name: 'Sahira', urduName: 'سہیرہ', meaning: 'Alert, wakeful', gender: 'Female', origin: 'Arabic' },
{ name: 'Samiya', urduName: 'سمیہ', meaning: 'Exalted, high', gender: 'Female', origin: 'Arabic' },
{ name: 'Sajid', urduName: 'ساجد', meaning: 'One who prostrates', gender: 'Male', origin: 'Arabic' },
{ name: 'Sufian', urduName: 'صفیان', meaning: 'Pure, clean', gender: 'Male', origin: 'Arabic' },
{ name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, he who knocks', gender: 'Male', origin: 'Arabic' },
{ name: 'Tayyib', urduName: 'طیب', meaning: 'Good, pure', gender: 'Male', origin: 'Arabic' },
{ name: 'Wajeeh', urduName: 'وجیہ', meaning: 'Handsome, distinguished', gender: 'Male', origin: 'Arabic' },
{ name: 'Warda', urduName: 'وردا', meaning: 'Rose, flower', gender: 'Female', origin: 'Arabic' },
{ name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
{ name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases, prophet’s name', gender: 'Male', origin: 'Arabic' },
{ name: 'Zahid', urduName: 'زاہد', meaning: 'Pious, ascetic', gender: 'Male', origin: 'Arabic' },
{ name: 'Zayna', urduName: 'زَیْنَہ', meaning: 'Beauty, grace', gender: 'Female', origin: 'Arabic' },
{ name: 'Zayd', urduName: 'زید', meaning: 'Growth, abundance', gender: 'Male', origin: 'Arabic' },
{ name: 'Abaad', urduName: 'آباد', meaning: 'Eternal, flourishing', gender: 'Male', origin: 'Arabic' },
{ name: 'Afnan', urduName: 'افنان', meaning: 'Branches of a tree, prosperous', gender: 'Male', origin: 'Arabic' },
{ name: 'Anisa', urduName: 'انیسہ', meaning: 'Friendly, companion', gender: 'Female', origin: 'Arabic' },
{ name: 'Baran', urduName: 'باران', meaning: 'Rain, clouds', gender: 'Female', origin: 'Persian' },
{ name: 'Daniya', urduName: 'دانیہ', meaning: 'Near, close', gender: 'Female', origin: 'Arabic' },
{ name: 'Eiman', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Male', origin: 'Arabic' },
{ name: 'Farhan', urduName: 'فرحان', meaning: 'Happy, joyful', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghina', urduName: 'غنیہ', meaning: 'Rich, wealthy', gender: 'Female', origin: 'Arabic' },
{ name: 'Hidaya', urduName: 'ہدایہ', meaning: 'Guidance', gender: 'Female', origin: 'Arabic' },
{ name: 'Ikram', urduName: 'اکرام', meaning: 'Honor, respect', gender: 'Male', origin: 'Arabic' },
{ name: 'Jabir', urduName: 'جابر', meaning: 'Comforter, healer', gender: 'Male', origin: 'Arabic' },
{ name: 'Jinan', urduName: 'جنّات', meaning: 'Paradise, gardens', gender: 'Female', origin: 'Arabic' },
{ name: 'Kamil', urduName: 'کامل', meaning: 'Complete, perfect', gender: 'Male', origin: 'Arabic' },
{ name: 'Laila', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
{ name: 'Mazin', urduName: 'مازن', meaning: 'Proper name, cloud that brings rain', gender: 'Male', origin: 'Arabic' },
{ name: 'Mansura', urduName: 'منصورہ', meaning: 'Victorious, aided', gender: 'Female', origin: 'Arabic' },
{ name: 'Nadia', urduName: 'نادِیہ', meaning: 'Caller, announcer', gender: 'Female', origin: 'Arabic' },
{ name: 'Nabil', urduName: 'نبیل', meaning: 'Noble, distinguished', gender: 'Male', origin: 'Arabic' },
{ name: 'Omar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Qamar', urduName: 'قمر', meaning: 'Moon', gender: 'Male', origin: 'Arabic' },
{ name: 'Rashid', urduName: 'رشید', meaning: 'Rightly guided, wise', gender: 'Male', origin: 'Arabic' },
{ name: 'Ranya', urduName: 'رانیا', meaning: 'Gazing, looking at', gender: 'Female', origin: 'Arabic' },
{ name: 'Safi', urduName: 'صافی', meaning: 'Pure, clear', gender: 'Male', origin: 'Arabic' },
{ name: 'Sakina', urduName: 'سکینہ', meaning: 'Tranquility, calmness', gender: 'Female', origin: 'Arabic' },
{ name: 'Shahira', urduName: 'شاہِرہ', meaning: 'Famous, renowned', gender: 'Female', origin: 'Arabic' },
{ name: 'Sulaiman', urduName: 'سلیمان', meaning: 'Peaceful, prophet’s name', gender: 'Male', origin: 'Arabic' },
{ name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, he who knocks', gender: 'Male', origin: 'Arabic' },
{ name: 'Umar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Yara', urduName: 'یارا', meaning: 'Small butterfly, beloved', gender: 'Female', origin: 'Arabic' },
{ name: 'Zaina', urduName: 'زینَہ', meaning: 'Beauty, grace', gender: 'Female', origin: 'Arabic' },
{ name: 'Zayn', urduName: 'زَین', meaning: 'Beauty, grace', gender: 'Male', origin: 'Arabic' },
{ name: 'Zuhair', urduName: 'زہیر', meaning: 'Bright, shining', gender: 'Male', origin: 'Arabic' },
{ name: 'Zaynaab', urduName: 'زینب', meaning: 'A fragrant flower', gender: 'Female', origin: 'Arabic' },
{ name: 'Zaheer', urduName: 'ظہیر', meaning: 'Bright, shining', gender: 'Male', origin: 'Arabic' },
{ name: 'Azlan', urduName: 'ازلَان', meaning: 'Lion, courageous', gender: 'Male', origin: 'Arabic' },
{ name: 'Basil', urduName: 'باسل', meaning: 'Brave, valiant', gender: 'Male', origin: 'Arabic' },
{ name: 'Bilal', urduName: 'بلال', meaning: 'Moistening, water, refreshing', gender: 'Male', origin: 'Arabic' },
{ name: 'Dalia', urduName: 'دالیا', meaning: 'Grape vine, branch', gender: 'Female', origin: 'Arabic' },
{ name: 'Faizan', urduName: 'فائزان', meaning: 'Benefactor, one who bestows', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghadeer', urduName: 'غدیر', meaning: 'Stream, river', gender: 'Male', origin: 'Arabic' },
{ name: 'Hadiya', urduName: 'ہادِیہ', meaning: 'Guide, gift', gender: 'Female', origin: 'Arabic' },
{ name: 'Imran', urduName: 'عمران', meaning: 'Prosperity, long life', gender: 'Male', origin: 'Arabic' },
{ name: 'Jameel', urduName: 'جمیل', meaning: 'Beautiful, handsome', gender: 'Male', origin: 'Arabic' },
{ name: 'Khalil', urduName: 'خلیل', meaning: 'Friend, companion', gender: 'Male', origin: 'Arabic' },
{ name: 'Lina', urduName: 'لینا', meaning: 'Delicate, tender', gender: 'Female', origin: 'Arabic' },
{ name: 'Maha', urduName: 'ماہَہ', meaning: 'Moon, wild cow', gender: 'Female', origin: 'Arabic' },
{ name: 'Nabilah', urduName: 'نبیلہ', meaning: 'Noble, distinguished', gender: 'Female', origin: 'Arabic' },
{ name: 'Owais', urduName: 'اویس', meaning: 'A companion of Prophet Muhammad', gender: 'Male', origin: 'Arabic' },
{ name: 'Rasha', urduName: 'رشا', meaning: 'Young deer, gazelle', gender: 'Female', origin: 'Arabic' },
{ name: 'Aadila', urduName: 'عادلہ', meaning: 'Just, fair', gender: 'Female', origin: 'Arabic' },
{ name: 'Ayaan', urduName: 'آیان', meaning: 'Gift of God', gender: 'Male', origin: 'Arabic' },
{ name: 'Bashir', urduName: 'بشیر', meaning: 'Bringer of good news', gender: 'Male', origin: 'Arabic' },
{ name: 'Bashira', urduName: 'بشیرہ', meaning: 'Bringer of glad tidings', gender: 'Female', origin: 'Arabic' },
{ name: 'Daliah', urduName: 'دالِیہ', meaning: 'Flower, branch, vine', gender: 'Female', origin: 'Arabic' },
{ name: 'Faris', urduName: 'فارس', meaning: 'Knight, horseman', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghadir', urduName: 'غدیر', meaning: 'Stream, river', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghazala', urduName: 'غزالہ', meaning: 'Doe, gazelle', gender: 'Female', origin: 'Arabic' },
{ name: 'Haider', urduName: 'حیدر', meaning: 'Lion, brave', gender: 'Male', origin: 'Arabic' },
{ name: 'Hifza', urduName: 'حفصہ', meaning: 'Protected, guarding', gender: 'Female', origin: 'Arabic' },
{ name: 'Imara', urduName: 'عمارہ', meaning: 'Leader, ruler', gender: 'Female', origin: 'Arabic' },
{ name: 'Ihsan', urduName: 'احسان', meaning: 'Beneficence, kindness', gender: 'Male', origin: 'Arabic' },
{ name: 'Jamilah', urduName: 'جمیلہ', meaning: 'Beautiful, graceful', gender: 'Female', origin: 'Arabic' },
{ name: 'Kamilah', urduName: 'کاملہ', meaning: 'Perfect, complete', gender: 'Female', origin: 'Arabic' },
{ name: 'Latif', urduName: 'لطیف', meaning: 'Gentle, kind', gender: 'Male', origin: 'Arabic' },
{ name: 'Liyana', urduName: 'لیانہ', meaning: 'Tender, soft', gender: 'Female', origin: 'Arabic' },
{ name: 'Maliha', urduName: 'ملیحہ', meaning: 'Beautiful, radiant', gender: 'Female', origin: 'Arabic' },
{ name: 'Musaab', urduName: 'مصعب', meaning: 'Hardship, difficulty', gender: 'Male', origin: 'Arabic' },
{ name: 'Nahla', urduName: 'نہلہ', meaning: 'Drink of water', gender: 'Female', origin: 'Arabic' },
{ name: 'Nura', urduName: 'نورا', meaning: 'Light', gender: 'Female', origin: 'Arabic' },
{ name: 'Omar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Qais', urduName: 'قیس', meaning: 'Firm, steadfast', gender: 'Male', origin: 'Arabic' },
{ name: 'Rameen', urduName: 'رامین', meaning: 'Happy, content', gender: 'Female', origin: 'Arabic' },
{ name: 'Rami', urduName: 'رامی', meaning: 'Archer, one who throws', gender: 'Male', origin: 'Arabic' },
{ name: 'Sami', urduName: 'سامی', meaning: 'Exalted, sublime', gender: 'Male', origin: 'Arabic' },
{ name: 'Sanjana', urduName: 'سنچنہ', meaning: 'Pure, clear', gender: 'Female', origin: 'Arabic' },
{ name: 'Suleiman', urduName: 'سلیمان', meaning: 'Peaceful, prophet’s name', gender: 'Male', origin: 'Arabic' },
{ name: 'Tariqa', urduName: 'طریقه', meaning: 'Path, way', gender: 'Female', origin: 'Arabic' },
{ name: 'Talha', urduName: 'طلحہ', meaning: 'A type of tree, palm', gender: 'Male', origin: 'Arabic' },
{ name: 'Zahra', urduName: 'زہرا', meaning: 'Bright, radiant', gender: 'Female', origin: 'Arabic' },
{ name: 'Zainab', urduName: 'زینب', meaning: 'Fragrant flower, father’s precious jewel', gender: 'Female', origin: 'Arabic' },
{ name: 'Zayd', urduName: 'زید', meaning: 'Growth, increase', gender: 'Male', origin: 'Arabic' },
{ name: 'Zaheer', urduName: 'ظہیر', meaning: 'Bright, shining', gender: 'Male', origin: 'Arabic' },
{ name: 'Zara', urduName: 'زارا', meaning: 'Princess, flower', gender: 'Female', origin: 'Arabic' },
{ name: 'Ziyad', urduName: 'زیاد', meaning: 'Growth, abundance', gender: 'Male', origin: 'Arabic' },
{ name: 'Amira', urduName: 'امِیرہ', meaning: 'Princess, leader', gender: 'Female', origin: 'Arabic' },
{ name: 'Aziza', urduName: 'عزیزہ', meaning: 'Powerful, dear', gender: 'Female', origin: 'Arabic' },
{ name: 'Bilal', urduName: 'بلال', meaning: 'Water, refreshing', gender: 'Male', origin: 'Arabic' },
{ name: 'Dani', urduName: 'دانی', meaning: 'Generous, noble', gender: 'Male', origin: 'Arabic' },
{ name: 'Dima', urduName: 'دیما', meaning: 'Downpour of rain', gender: 'Female', origin: 'Arabic' },
{ name: 'Ehab', urduName: 'اہاب', meaning: 'Gift, present', gender: 'Male', origin: 'Arabic' },
{ name: 'Fayza', urduName: 'فائزہ', meaning: 'Victorious, successful', gender: 'Female', origin: 'Arabic' },
{ name: 'Fouzia', urduName: 'فوزیہ', meaning: 'Victory, success', gender: 'Female', origin: 'Arabic' },
{ name: 'Ghena', urduName: 'غینہ', meaning: 'Wealth, richness', gender: 'Female', origin: 'Arabic' },
{ name: 'Hasan', urduName: 'حسن', meaning: 'Good, beautiful', gender: 'Male', origin: 'Arabic' },
{ name: 'Huda', urduName: 'ہدیٰ', meaning: 'Guidance', gender: 'Female', origin: 'Arabic' },
{ name: 'Imaan', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Female', origin: 'Arabic' },
{ name: 'Iqbal', urduName: 'اقبال', meaning: 'Prosperity, success', gender: 'Male', origin: 'Arabic' },
{ name: 'Isha', urduName: 'عشا', meaning: 'Night, last prayer', gender: 'Female', origin: 'Arabic' },
{ name: 'Khalil', urduName: 'خلیل', meaning: 'Friend, companion', gender: 'Male', origin: 'Arabic' },
{ name: 'Laila', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
{ name: 'Maysa', urduName: 'مَیسَہ', meaning: 'Proud, graceful', gender: 'Female', origin: 'Arabic' },
{ name: 'Aadil', urduName: 'عادل', meaning: 'Just, fair', gender: 'Male', origin: 'Arabic' },
{ name: 'Afnan', urduName: 'افنان', meaning: 'Branches, twigs of a tree', gender: 'Male', origin: 'Arabic' },
{ name: 'Bina', urduName: 'بِنا', meaning: 'Built, constructed', gender: 'Female', origin: 'Arabic' },
{ name: 'Dania', urduName: 'دانیہ', meaning: 'Close, near', gender: 'Female', origin: 'Arabic' },
{ name: 'Ehsan', urduName: 'احسان', meaning: 'Benevolence, kindness', gender: 'Male', origin: 'Arabic' },
{ name: 'Fayez', urduName: 'فائز', meaning: 'Winner, victorious', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghina', urduName: 'غنیہ', meaning: 'Rich, prosperous', gender: 'Female', origin: 'Arabic' },
{ name: 'Hammad', urduName: 'حماد', meaning: 'Praiseworthy', gender: 'Male', origin: 'Arabic' },
{ name: 'Inaam', urduName: 'انعام', meaning: 'Bounties, favors', gender: 'Male', origin: 'Arabic' },
{ name: 'Javeria', urduName: 'جویریہ', meaning: 'One who brings happiness', gender: 'Female', origin: 'Arabic' },
{ name: 'Kashan', urduName: 'کاشان', meaning: 'A Persian city, elegance', gender: 'Male', origin: 'Persian' },
{ name: 'Layan', urduName: 'لیان', meaning: 'Soft, gentle', gender: 'Female', origin: 'Arabic' },
{ name: 'Mazin', urduName: 'مازن', meaning: 'Rain cloud, proper name', gender: 'Male', origin: 'Arabic' },
{ name: 'Nashit', urduName: 'ناشِط', meaning: 'Energetic, active', gender: 'Male', origin: 'Arabic' },
{ name: 'Owais', urduName: 'اویس', meaning: 'A companion of the Prophet', gender: 'Male', origin: 'Arabic' },
{ name: 'Parsa', urduName: 'پارسا', meaning: 'Pious, virtuous', gender: 'Male', origin: 'Persian' },
{ name: 'Qasim', urduName: 'قاسم', meaning: 'Distributor, divider', gender: 'Male', origin: 'Arabic' },
{ name: 'Rameen', urduName: 'رامین', meaning: 'Happy, content', gender: 'Female', origin: 'Arabic' },
{ name: 'Sadiya', urduName: 'سادیہ', meaning: 'Lucky, fortunate', gender: 'Female', origin: 'Arabic' },
{ name: 'Taha', urduName: 'طہ', meaning: 'Pure, clean', gender: 'Male', origin: 'Arabic' },
{ name: 'Umar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Vahid', urduName: 'وحید', meaning: 'Unique, singular', gender: 'Male', origin: 'Arabic' },
{ name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
{ name: 'Zahida', urduName: 'زاہدہ', meaning: 'Ascetic, devout', gender: 'Female', origin: 'Arabic' },
{ name: 'Zeeshan', urduName: 'ذیشان', meaning: 'Majestic, of high rank', gender: 'Male', origin: 'Arabic' },
{ name: 'Asma', urduName: 'اسماء', meaning: 'Exalted, high status', gender: 'Female', origin: 'Arabic' },
{ name: 'Basil', urduName: 'باسل', meaning: 'Brave, courageous', gender: 'Male', origin: 'Arabic' },
{ name: 'Dariush', urduName: 'داریوش', meaning: 'Wealthy, prosperous', gender: 'Male', origin: 'Persian' },
{ name: 'Emira', urduName: 'امِیرہ', meaning: 'Princess, leader', gender: 'Female', origin: 'Arabic' },
{ name: 'Faris', urduName: 'فارس', meaning: 'Knight, horseman', gender: 'Male', origin: 'Arabic' },
{ name: 'Ghazal', urduName: 'غزل', meaning: 'Poetry, song', gender: 'Male', origin: 'Arabic' },
{ name: 'Haleem', urduName: 'حلیم', meaning: 'Gentle, kind', gender: 'Male', origin: 'Arabic' },
{ name: 'Imad', urduName: 'عماد', meaning: 'Support, pillar', gender: 'Male', origin: 'Arabic' },
{ name: 'Jaleel', urduName: 'جلیل', meaning: 'Great, magnificent', gender: 'Male', origin: 'Arabic' },
{ name: 'Khalil', urduName: 'خلیل', meaning: 'Friend, companion', gender: 'Male', origin: 'Arabic' },
{ name: 'Lina', urduName: 'لینا', meaning: 'Tender, delicate', gender: 'Female', origin: 'Arabic' },
{ name: 'Mazen', urduName: 'مازن', meaning: 'Rain, cloud', gender: 'Male', origin: 'Arabic' },
{ name: 'Nour', urduName: 'نور', meaning: 'Light, radiance', gender: 'Female', origin: 'Arabic' },
{ name: 'Omar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Parsa', urduName: 'پارسا', meaning: 'Pious, virtuous', gender: 'Male', origin: 'Persian' },
{ name: 'Qamar', urduName: 'قمر', meaning: 'Moon', gender: 'Male', origin: 'Arabic' },
{ name: 'Rumesha', urduName: 'رُمیشا', meaning: 'A beautiful woman, radiant', gender: 'Female', origin: 'Arabic' },
{ name: 'Rida', urduName: 'رضا', meaning: 'Contentment, acceptance', gender: 'Female', origin: 'Arabic' },
{ name: 'Ruqayya', urduName: 'رقیہ', meaning: 'Ascending, rise', gender: 'Female', origin: 'Arabic' },
{ name: 'Rimsha', urduName: 'رمشہ', meaning: 'A bundle of flowers', gender: 'Female', origin: 'Arabic' },
{ name: 'Ruqaiya', urduName: 'رقیہ', meaning: 'A name of one of the daughters of the Prophet', gender: 'Female', origin: 'Arabic' },
{ name: 'Rameesha', urduName: 'رامیشا', meaning: 'Beautiful, radiant', gender: 'Female', origin: 'Arabic' },
{ name: 'Ramin', urduName: 'رامِن', meaning: 'Joyous, happy', gender: 'Male', origin: 'Persian' },
{ name: 'Rasiya', urduName: 'راسیہ', meaning: 'Sincere, honest', gender: 'Female', origin: 'Arabic' },
{ name: 'Reesha', urduName: 'ریشہ', meaning: 'Feather, a bird’s feather', gender: 'Female', origin: 'Arabic' },
{ name: 'Riana', urduName: 'ریانہ', meaning: 'A graceful woman', gender: 'Female', origin: 'Persian' },
{ name: 'Riya', urduName: 'ریا', meaning: 'Grace, elegance', gender: 'Female', origin: 'Arabic' },
{ name: 'Bakhtbuland', urduName: 'بختبلند', meaning: 'High fortune, blessed with success', gender: 'Male', origin: 'Persian' },
{ name: 'Bakhtawar', urduName: 'بختاور', meaning: 'Fortunate, lucky', gender: 'Male', origin: 'Persian' },
{ name: 'Shamsul Haq', urduName: 'شمس الحق', meaning: 'The sun of truth', gender: 'Male', origin: 'Arabic' },
{ name: 'Fakhrul Alam', urduName: 'فخرالعالم', meaning: 'Pride of the world', gender: 'Male', origin: 'Arabic' },
{ name: 'Noorul Hassan', urduName: 'نورالحسن', meaning: 'Light of goodness', gender: 'Male', origin: 'Arabic' },
{ name: 'Razaul Fateh', urduName: 'رضاالفتح', meaning: 'Contentment of victory', gender: 'Male', origin: 'Arabic' },
{ name: 'Zafarul Azim', urduName: 'ظفرالعظیم', meaning: 'Victory of greatness', gender: 'Male', origin: 'Arabic' },
{ name: 'Ihsanul Alam', urduName: 'احسان العالم', meaning: 'Kindness to the world', gender: 'Male', origin: 'Arabic' },
{ name: 'Mahmoodul Zaman', urduName: 'محمود الزمان', meaning: 'Praiseworthy of the era', gender: 'Male', origin: 'Arabic' },
{ name: 'Najmuddin', urduName: 'نجم الدین', meaning: 'Star of the faith', gender: 'Male', origin: 'Arabic' },
{ name: 'Sultanul Wafa', urduName: 'سلطان الوفا', meaning: 'Sultan of loyalty', gender: 'Male', origin: 'Arabic' },
{ name: 'Zulfiqar', urduName: 'ذوالفقار', meaning: 'Sword of Ali, sharp sword', gender: 'Male', origin: 'Arabic' },
{ name: 'Ameen', urduName: 'امین', meaning: 'Trustworthy, faithful', gender: 'Male', origin: 'Arabic' },
{ name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, early morning', gender: 'Female', origin: 'Arabic' },
{ name: 'Azlan', urduName: 'ازلان', meaning: 'Lion, brave', gender: 'Male', origin: 'Arabic' },
{ name: 'Shahbaz', urduName: 'شاہباز', meaning: 'Royal falcon, high rank', gender: 'Male', origin: 'Persian' },
{ name: 'Shahira', urduName: 'شاہِرا', meaning: 'Famous, distinguished', gender: 'Female', origin: 'Arabic' },
{ name: 'Aaliyah', urduName: 'عالیہ', meaning: 'Exalted, noble', gender: 'Female', origin: 'Arabic' },
{ name: 'Ibrahim', urduName: 'ابراہیم', meaning: 'Prophet Abraham, father of many', gender: 'Male', origin: 'Arabic' },
{ name: 'Lailah', urduName: 'لیلہ', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
{ name: 'Zain', urduName: 'زین', meaning: 'Beauty, grace, adornment', gender: 'Male', origin: 'Arabic' },
{ name: 'Gulnar', urduName: 'گُلنا ر', meaning: 'Pomegranate flower, radiant beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Gulzar', urduName: 'گُلزار', meaning: 'A garden of flowers, lush garden', gender: 'Male', origin: 'Persian' },
{ name: 'Gulbahar', urduName: 'گُل بہار', meaning: 'Flower of spring, spring bloom', gender: 'Female', origin: 'Persian' },
{ name: 'Gulsher', urduName: 'گُل شیر', meaning: 'Flower of the lion, strong and beautiful', gender: 'Male', origin: 'Persian' },
{ name: 'Gulshan', urduName: 'گُلشن', meaning: 'Garden of flowers, paradise', gender: 'Male', origin: 'Persian' },
{ name: 'Gulsan', urduName: 'گُل سن', meaning: 'One who brings flowers, a blooming flower', gender: 'Female', origin: 'Persian' },
{ name: 'Gulnara', urduName: 'گُل نارا', meaning: 'Flower with a radiant beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Gulrukh', urduName: 'گُل رخ', meaning: 'Flower-faced, one with a beautiful face', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfira', urduName: 'گُل فِرا', meaning: 'Radiant like a flower', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfia', urduName: 'گُل فیہ', meaning: 'Beautiful like a flower', gender: 'Female', origin: 'Persian' },
{ name: 'Gulshanara', urduName: 'گُل شنرا', meaning: 'A beautiful garden of flowers', gender: 'Female', origin: 'Persian' },
{ name: 'Sarbuland', urduName: 'سربلند', meaning: 'High-ranking, elevated', gender: 'Male', origin: 'Persian' },
{ name: 'Sarfaraz', urduName: 'سرفراز', meaning: 'Exalted, honored', gender: 'Male', origin: 'Persian' },
{ name: 'Buland', urduName: 'بلند', meaning: 'High, elevated', gender: 'Male', origin: 'Arabic' },
{ name: 'Aftab', urduName: 'آفتاب', meaning: 'Sun, radiant', gender: 'Male', origin: 'Persian' },
{ name: 'Fakhrul Alam', urduName: 'فخرالعالم', meaning: 'Pride of the world', gender: 'Male', origin: 'Arabic' },
{ name: 'Shamsher', urduName: 'شمشیر', meaning: 'Sword, a powerful weapon', gender: 'Male', origin: 'Persian' },
{ name: 'Farhan', urduName: 'فرحان', meaning: 'Happy, joyful', gender: 'Male', origin: 'Arabic' },
{ name: 'Shahbaz', urduName: 'شاہباز', meaning: 'Royal falcon, high rank', gender: 'Male', origin: 'Persian' },
{ name: 'Zafar', urduName: 'ظفر', meaning: 'Victory, success', gender: 'Male', origin: 'Arabic' },
{ name: 'Riyasat', urduName: 'ریاست', meaning: 'Kingdom, sovereignty', gender: 'Male', origin: 'Arabic' },
{ name: 'Zmarai', urduName: 'زمرای', meaning: 'Lion, brave', gender: 'Male', origin: 'Pashto' },
{ name: 'Shamsia', urduName: 'شمسیہ', meaning: 'Sunlight, radiant', gender: 'Female', origin: 'Afghani' },
{ name: 'Samin', urduName: 'سمین', meaning: 'Pure, honest', gender: 'Male', origin: 'Afghani' },
{ name: 'Feroza', urduName: 'فیروزی', meaning: 'Turquoise, victorious', gender: 'Female', origin: 'Persian/Afghani' },
{ name: 'Nasrat', urduName: 'نصرت', meaning: 'Victory, triumph', gender: 'Male', origin: 'Pashto' },
{ name: 'Khawaja', urduName: 'خواجہ', meaning: 'Master, lord', gender: 'Male', origin: 'Afghani' },
{ name: 'Meena', urduName: 'مینا', meaning: 'Precious gem, beloved', gender: 'Female', origin: 'Afghani' },
{ name: 'Gulzar', urduName: 'گُلزار', meaning: 'A garden of flowers, lush garden', gender: 'Male', origin: 'Pashto' },
{ name: 'Shahina', urduName: 'شاہینہ', meaning: 'Like a queen, royal', gender: 'Female', origin: 'Afghani' },
{ name: 'Sadaf', urduName: 'صدف', meaning: 'Seashell, pearl', gender: 'Female', origin: 'Afghani' },
{ name: 'Gulzar', urduName: 'گُلزار', meaning: 'A garden of flowers, lush garden', gender: 'Male', origin: 'Persian' },
{ name: 'Gulshan', urduName: 'گُلشن', meaning: 'Garden of flowers, paradise', gender: 'Male', origin: 'Persian' },
{ name: 'Gulbahar', urduName: 'گُل بہار', meaning: 'Flower of spring, spring bloom', gender: 'Female', origin: 'Persian' },
{ name: 'Gulsher', urduName: 'گُل شیر', meaning: 'Flower of the lion, strong and beautiful', gender: 'Male', origin: 'Persian' },
{ name: 'Gulrukh', urduName: 'گُل رخ', meaning: 'Flower-faced, one with a beautiful face', gender: 'Female', origin: 'Persian' },
{ name: 'Gulnar', urduName: 'گُلنا ر', meaning: 'Pomegranate flower, radiant beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Gulnara', urduName: 'گُل نارا', meaning: 'Flower with a radiant beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Gulbano', urduName: 'گُل بانو', meaning: 'Lady of flowers, noble lady', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfam', urduName: 'گُل فام', meaning: 'One with the color of flowers, radiant', gender: 'Male', origin: 'Persian' },
{ name: 'Gulzaar', urduName: 'گُلزار', meaning: 'A flower garden, blooming garden', gender: 'Male', origin: 'Persian' },
{ name: 'Gulnaz', urduName: 'گُل ناز', meaning: 'Flower of pride, beautiful flower', gender: 'Female', origin: 'Persian' },
{ name: 'Gulhena', urduName: 'گُل ہیرا', meaning: 'Flower and jewel, precious flower', gender: 'Female', origin: 'Persian' },
{ name: 'Gulbahram', urduName: 'گُل بہرام', meaning: 'Flower of the heart', gender: 'Male', origin: 'Persian' },
{ name: 'Gulbari', urduName: 'گُل باری', meaning: 'Garden of flowers, garden of bliss', gender: 'Male', origin: 'Persian' },
{ name: 'Gulwar', urduName: 'گُل وار', meaning: 'Warrior of flowers, strong and beautiful', gender: 'Male', origin: 'Persian' },
{ name: 'Guldana', urduName: 'گُل دانہ', meaning: 'The one who brings flowers', gender: 'Female', origin: 'Persian' },
{ name: 'Gulil', urduName: 'گُلِل', meaning: 'Small flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulshen', urduName: 'گُل شن', meaning: 'Paradise of flowers, beautiful garden', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfaiz', urduName: 'گُل فائز', meaning: 'Success of flowers', gender: 'Male', origin: 'Persian' },
{ name: 'Gulafshan', urduName: 'گُل افشاں', meaning: 'One who scatters flowers', gender: 'Male', origin: 'Persian' },
{ name: 'Gulrain', urduName: 'گُل رین', meaning: 'Rain of flowers, blessings', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfira', urduName: 'گُل فِرا', meaning: 'Radiant flower', gender: 'Female', origin: 'Persian' },
{ name: 'Gulwaran', urduName: 'گُل واران', meaning: 'The one who brings flowers', gender: 'Male', origin: 'Persian' },
{ name: 'Gulraiz', urduName: 'گُل ریز', meaning: 'Flower that shines, radiant flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulrahnama', urduName: 'گُل رہنما', meaning: 'Leader of flowers', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfaz', urduName: 'گُل فاز', meaning: 'Flower in bloom', gender: 'Male', origin: 'Persian' },
{ name: 'Gulzarika', urduName: 'گُل زارِکا', meaning: 'A rich garden of flowers', gender: 'Female', origin: 'Persian' },
{ name: 'Gulbanoo', urduName: 'گُل بانو', meaning: 'Lady of flowers', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfiroz', urduName: 'گُل فیروز', meaning: 'Radiant as a turquoise flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulhila', urduName: 'گُل ہِلا', meaning: 'One who loves flowers', gender: 'Female', origin: 'Persian' },
{ name: 'Gulsana', urduName: 'گُل سَنا', meaning: 'A flower of praise, praised flower', gender: 'Female', origin: 'Persian' },
{ name: 'Gulnash', urduName: 'گُل ناش', meaning: 'Blooming flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulsor', urduName: 'گُل سور', meaning: 'Bright flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulpen', urduName: 'گُل پَن', meaning: 'Flower-like soul', gender: 'Female', origin: 'Persian' },
{ name: 'Gulsharif', urduName: 'گُل شریف', meaning: 'Noble flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulhamza', urduName: 'گُل حمزہ', meaning: 'Flower with strength', gender: 'Male', origin: 'Persian' },
{ name: 'Gulmira', urduName: 'گُل میرا', meaning: 'One with the beauty of a flower', gender: 'Female', origin: 'Persian' },
{ name: 'Guljeel', urduName: 'گُل جیل', meaning: 'Flower of the oasis', gender: 'Male', origin: 'Persian' },
{ name: 'Gulroza', urduName: 'گُل روزہ', meaning: 'Flower of the fast, one with patience', gender: 'Female', origin: 'Persian' },
{ name: 'Gulnasreen', urduName: 'گُل نسرین', meaning: 'Flower-like beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Gulraza', urduName: 'گُل رَزا', meaning: 'Flower of the soul', gender: 'Female', origin: 'Persian' },
{ name: 'Gulzaara', urduName: 'گُل زارَہ', meaning: 'A flower from a beautiful garden', gender: 'Female', origin: 'Persian' },
{ name: 'Gulsheran', urduName: 'گُل شیرَن', meaning: 'Lion-hearted flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulzaman', urduName: 'گُل زمان', meaning: 'Timeless flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulwahid', urduName: 'گُل واحد', meaning: 'Unique flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulwali', urduName: 'گُل والی', meaning: 'Protector of flowers', gender: 'Male', origin: 'Persian' },
{ name: 'Gulrahim', urduName: 'گُل رحیم', meaning: 'Compassionate flower', gender: 'Male', origin: 'Persian' },
{ name: 'Gulsaz', urduName: 'گُل ساز', meaning: 'Creator of flowers', gender: 'Male', origin: 'Persian' },
{ name: 'Gulfair', urduName: 'گُل فیر', meaning: 'Flower of fairness', gender: 'Male', origin: 'Persian' },
{ name: 'Gulnur', urduName: 'گُل نور', meaning: 'Flower of light', gender: 'Male', origin: 'Persian' },
{ name: 'Gulwat', urduName: 'گُل وت', meaning: 'Flower-like elegance', gender: 'Female', origin: 'Persian' },
{ name: 'Gulshina', urduName: 'گُل شِنا', meaning: 'One who knows flowers', gender: 'Female', origin: 'Persian' },
{ name: 'Gulfan', urduName: 'گُل فان', meaning: 'Flower of the star', gender: 'Male', origin: 'Persian' },
{ name: 'Gulisha', urduName: 'گُل عِشہ', meaning: 'A beautiful flower', gender: 'Female', origin: 'Persian' },
{ name: 'Zarif', urduName: 'ظریف', meaning: 'Elegant, graceful', gender: 'Male', origin: 'Pashto' },
{ name: 'Shahbaz', urduName: 'شاہباز', meaning: 'Royal falcon', gender: 'Male', origin: 'Pashto' },
{ name: 'Nazia', urduName: 'نازیہ', meaning: 'Proud, a woman of pride', gender: 'Female', origin: 'Pashto' },
{ name: 'Feroze', urduName: 'فیروز', meaning: 'Victory, turquoise', gender: 'Male', origin: 'Afghani' },
{ name: 'Khayr', urduName: 'خیر', meaning: 'Goodness, blessing', gender: 'Male', origin: 'Pashto' },
{ name: 'Sadaf', urduName: 'صدف', meaning: 'Seashell, pearl', gender: 'Female', origin: 'Afghani' },
{ name: 'Khadija', urduName: 'خدیجہ', meaning: 'Early baby, trustworthy', gender: 'Female', origin: 'Afghani' },
{ name: 'Gulnaz', urduName: 'گُل ناز', meaning: 'Pride of flowers, beautiful flower', gender: 'Female', origin: 'Pashto' },
{ name: 'Hassan', urduName: 'حسن', meaning: 'Good, handsome', gender: 'Male', origin: 'Pashto' },
{ name: 'Zohra', urduName: 'زہرا', meaning: 'Radiant, Venus', gender: 'Female', origin: 'Afghani' },
{ name: 'Mirwais', urduName: 'مرویس', meaning: 'Leader, strong', gender: 'Male', origin: 'Pashto' },
{ name: 'Sami', urduName: 'سامی', meaning: 'Elevated, sublime', gender: 'Male', origin: 'Pashto' },
{ name: 'Gulbahar', urduName: 'گُل بہار', meaning: 'Flower of spring', gender: 'Female', origin: 'Pashto' },
{ name: 'Tariq', urduName: 'طارق', meaning: 'Morning star', gender: 'Male', origin: 'Afghani' },
{ name: 'Ayesha', urduName: 'عائشہ', meaning: 'Alive, living', gender: 'Female', origin: 'Afghani' },
{ name: 'Shamsher', urduName: 'شمشیر', meaning: 'Sword, a powerful weapon', gender: 'Male', origin: 'Afghani' },
{ name: 'Samiya', urduName: 'سمیہ', meaning: 'Exalted, elevated', gender: 'Female', origin: 'Afghani' },
{ name: 'Zarina', urduName: 'زَرِینہ', meaning: 'Golden, princess', gender: 'Female', origin: 'Afghani' },
{ name: 'Nadir', urduName: 'نادِر', meaning: 'Rare, precious', gender: 'Male', origin: 'Pashto' },
{ name: 'Shireen', urduName: 'شیرین', meaning: 'Sweet, lovely', gender: 'Female', origin: 'Afghani' },
{ name: 'Omar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Afghani' },
{ name: 'Rashid', urduName: 'رشید', meaning: 'Rightly guided, wise', gender: 'Male', origin: 'Afghani' },
{ name: 'Noor', urduName: 'نور', meaning: 'Light, radiance', gender: 'Female', origin: 'Afghani' },
{ name: 'Musharraf', urduName: 'مشرف', meaning: 'Honored, distinguished', gender: 'Male', origin: 'Pashto' },
{ name: 'Jamilah', urduName: 'جمیلَہ', meaning: 'Beautiful', gender: 'Female', origin: 'Afghani' },
{ name: 'Zaman', urduName: 'زمان', meaning: 'Time, era', gender: 'Male', origin: 'Afghani' },
{ name: 'Farzana', urduName: 'فرزانہ', meaning: 'Intelligent, wise', gender: 'Female', origin: 'Afghani' },
{ name: 'Khalil', urduName: 'خلیل', meaning: 'Friend, intimate', gender: 'Male', origin: 'Afghani' },
{ name: 'Anisa', urduName: 'انیسہ', meaning: 'Companion, friendly', gender: 'Female', origin: 'Afghani' },
{ name: 'Zahid', urduName: 'زاہد', meaning: 'Pious, ascetic', gender: 'Male', origin: 'Afghani' },
{ name: 'Parveen', urduName: 'پروین', meaning: 'Star, a constellation', gender: 'Female', origin: 'Afghani' },
{ name: 'Jawad', urduName: 'جواد', meaning: 'Generous, benevolent', gender: 'Male', origin: 'Afghani' },
{ name: 'Meena', urduName: 'مینا', meaning: 'Precious gem, beloved', gender: 'Female', origin: 'Afghani' },
{ name: 'Hamid', urduName: 'حامِد', meaning: 'Praised, commendable', gender: 'Male', origin: 'Afghani' },
{ name: 'Samira', urduName: 'سمیرا', meaning: 'Companion in evening talk', gender: 'Female', origin: 'Afghani' },
{ name: 'Ibrahim', urduName: 'ابراہم', meaning: 'Father of a multitude', gender: 'Male', origin: 'Afghani' },
{ name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, morning', gender: 'Female', origin: 'Afghani' },
{ name: 'Raza', urduName: 'رضا', meaning: 'Contentment, acceptance', gender: 'Male', origin: 'Afghani' },
{ name: 'Mariam', urduName: 'مریم', meaning: 'Beloved, sea of bitterness', gender: 'Female', origin: 'Afghani' },
{ name: 'Aylin', urduName: 'آیلن', meaning: 'Moonlight', gender: 'Female', origin: 'Turkish' },
{ name: 'Emir', urduName: 'امیر', meaning: 'Commander, prince', gender: 'Male', origin: 'Turkish' },
{ name: 'Deniz', urduName: 'دنیز', meaning: 'Sea', gender: 'Female', origin: 'Turkish' },
{ name: 'Elif', urduName: 'الف', meaning: 'Unique, first letter of the alphabet', gender: 'Female', origin: 'Turkish' },
{ name: 'Baran', urduName: 'باران', meaning: 'Rain', gender: 'Male', origin: 'Turkish' },
{ name: 'Kaan', urduName: 'کاان', meaning: 'Ruler, emperor', gender: 'Male', origin: 'Turkish' },
{ name: 'Selin', urduName: 'سِلِن', meaning: 'Calm, serene', gender: 'Female', origin: 'Turkish' },
{ name: 'Alican', urduName: 'علیجان', meaning: 'Noble, heroic', gender: 'Male', origin: 'Turkish' },
{ name: 'Zeynep', urduName: 'زینب', meaning: 'Precious gem, father’s jewel', gender: 'Female', origin: 'Turkish' },
{ name: 'Cem', urduName: 'جم', meaning: 'King, ruler', gender: 'Male', origin: 'Turkish' },
{ name: 'Hazar', urduName: 'ہزار', meaning: 'Thousand, countless', gender: 'Male', origin: 'Turkish' },
{ name: 'Duru', urduName: 'دُرو', meaning: 'Pure, clean', gender: 'Female', origin: 'Turkish' },
{ name: 'Murat', urduName: 'مراد', meaning: 'Desire, wish', gender: 'Male', origin: 'Turkish' },
{ name: 'Tarkan', urduName: 'ترکان', meaning: 'A legendary hero', gender: 'Male', origin: 'Turkish' },
{ name: 'Asya', urduName: 'آسیا', meaning: 'Asia, the continent', gender: 'Female', origin: 'Turkish' },
{ name: 'Berk', urduName: 'برک', meaning: 'Strong, solid', gender: 'Male', origin: 'Turkish' },
{ name: 'Yasemin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Turkish' },
{ name: 'Melek', urduName: 'ملیک', meaning: 'Angel', gender: 'Female', origin: 'Turkish' },
{ name: 'Orhan', urduName: 'ارخان', meaning: 'Great leader', gender: 'Male', origin: 'Turkish' },
{ name: 'Buse', urduName: 'بُسَے', meaning: 'A kiss, a token of love', gender: 'Female', origin: 'Turkish' },
{ name: 'Can', urduName: 'جان', meaning: 'Life, soul', gender: 'Male', origin: 'Turkish' },
{ name: 'Fikret', urduName: 'فکرت', meaning: 'Thought, reflection', gender: 'Male', origin: 'Turkish' },
{ name: 'Aysel', urduName: 'آئسل', meaning: 'Moonstream', gender: 'Female', origin: 'Turkish' },
{ name: 'Sinem', urduName: 'سینم', meaning: 'Heart, soul, cinema', gender: 'Female', origin: 'Turkish' },
{ name: 'Riza', urduName: 'رضا', meaning: 'Consent, satisfaction', gender: 'Male', origin: 'Turkish' },
{ name: 'Gokhan', urduName: 'گُکھان', meaning: 'Sky Khan', gender: 'Male', origin: 'Turkish' },
{ name: 'Nisan', urduName: 'نیسان', meaning: 'April, the month', gender: 'Female', origin: 'Turkish' },
{ name: 'Elvan', urduName: 'الوان', meaning: 'Colorful, rainbow', gender: 'Male', origin: 'Turkish' },
{ name: 'Yavuz', urduName: 'یواز', meaning: 'Fearless, brave', gender: 'Male', origin: 'Turkish' },
{ name: 'Seren', urduName: 'سرن', meaning: 'Calm, peace', gender: 'Female', origin: 'Turkish' },
{ name: 'Neva', urduName: 'نیوا', meaning: 'New, melody', gender: 'Female', origin: 'Turkish' },
{ name: 'Alper', urduName: 'الپر', meaning: 'Hero, brave', gender: 'Male', origin: 'Turkish' },
{ name: 'Gizem', urduName: 'گیزم', meaning: 'Mystery, secret', gender: 'Female', origin: 'Turkish' },
{ name: 'Tolga', urduName: 'تولگا', meaning: 'A helmet, protector', gender: 'Male', origin: 'Turkish' },
{ name: 'Nedim', urduName: 'نیدم', meaning: 'Friend, companion', gender: 'Male', origin: 'Turkish' },
{ name: 'Zehra', urduName: 'زہرا', meaning: 'Radiant, brilliant', gender: 'Female', origin: 'Turkish' },
{ name: 'Berkay', urduName: 'برکای', meaning: 'Strong moon', gender: 'Male', origin: 'Turkish' },
{ name: 'Sevda', urduName: 'سِوْدَ', meaning: 'Love, passion', gender: 'Female', origin: 'Turkish' },
{ name: 'Cemre', urduName: 'جمری', meaning: 'The warmth of spring', gender: 'Female', origin: 'Turkish' },
{ name: 'Hüseyin', urduName: 'حسین', meaning: 'Handsome, good', gender: 'Male', origin: 'Turkish' },
{ name: 'Simge', urduName: 'سمگے', meaning: 'Symbol, sign', gender: 'Female', origin: 'Turkish' },
{ name: 'Erhan', urduName: 'ارحان', meaning: 'Good, respectful', gender: 'Male', origin: 'Turkish' },
{ name: 'Beyza', urduName: 'بیزا', meaning: 'Pure, white', gender: 'Female', origin: 'Turkish' },
{ name: 'Hasan', urduName: 'حسن', meaning: 'Good, handsome', gender: 'Male', origin: 'Turkish' },
{ name: 'Nalan', urduName: 'نالان', meaning: 'Complaining, sorrowful', gender: 'Female', origin: 'Turkish' },
{ name: 'Gülşen', urduName: 'گُلشَن', meaning: 'Flower garden, paradise', gender: 'Female', origin: 'Turkish' },
{ name: 'Ferhat', urduName: 'فرحت', meaning: 'A hero, one who is strong', gender: 'Male', origin: 'Turkish' },
{ name: 'Seda', urduName: 'سِدا', meaning: 'Voice, sound', gender: 'Female', origin: 'Turkish' },
{ name: 'Ece', urduName: 'ایجہ', meaning: 'Queen, crown', gender: 'Female', origin: 'Turkish' },
{ name: 'Koray', urduName: 'کُورای', meaning: 'Moonlight', gender: 'Male', origin: 'Turkish' },
{ name: 'Tuğba', urduName: 'تُگبا', meaning: 'Paradise tree, a blessed tree', gender: 'Female', origin: 'Turkish' },
{ name: 'İsmail', urduName: 'اسماعیل', meaning: 'God will hear, one who is heard', gender: 'Male', origin: 'Turkish' },
{ name: 'Beyhan', urduName: 'بےہان', meaning: 'A queen, ruler', gender: 'Male', origin: 'Turkish' },
{ name: 'Nejat', urduName: 'نجات', meaning: 'Rescue, salvation', gender: 'Male', origin: 'Turkish' },
{ name: 'Derya', urduName: 'دریا', meaning: 'Sea, ocean', gender: 'Female', origin: 'Turkish' },
{ name: 'Alican', urduName: 'علیجان', meaning: 'Noble, a heroic figure', gender: 'Male', origin: 'Turkish' },
{ name: 'Yelda', urduName: 'یلدا', meaning: 'Longest night of the year', gender: 'Female', origin: 'Turkish' },
{ name: 'Kubra', urduName: 'کبرا', meaning: 'Great, superior', gender: 'Female', origin: 'Turkish' },
{ name: 'Barış', urduName: 'بارش', meaning: 'Peace, harmony', gender: 'Male', origin: 'Turkish' },
{ name: 'Alp', urduName: 'آلپ', meaning: 'Brave, heroic', gender: 'Male', origin: 'Turkish' },
{ name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases, a prophet’s name', gender: 'Male', origin: 'Turkish' },
{ name: 'Şirin', urduName: 'شیرین', meaning: 'Sweet, charming', gender: 'Female', origin: 'Turkish' },
{ name: 'Süleyman', urduName: 'سلیمان', meaning: 'Peaceful, a prophet’s name', gender: 'Male', origin: 'Turkish' },
{ name: 'Feryal', urduName: 'فریال', meaning: 'Beautiful, lovely', gender: 'Female', origin: 'Turkish' },
{ name: 'Görkem', urduName: 'گورکم', meaning: 'Majesty, splendor', gender: 'Male', origin: 'Turkish' },
{ name: 'Zeynep', urduName: 'زینب', meaning: 'Precious gem, father’s jewel', gender: 'Female', origin: 'Turkish' },
{ name: 'Serkan', urduName: 'سرکان', meaning: 'Leader, chief', gender: 'Male', origin: 'Turkish' },
{ name: 'Alev', urduName: 'آلِو', meaning: 'Flame, fire', gender: 'Female', origin: 'Turkish' },
{ name: 'Alperen', urduName: 'الپرن', meaning: 'Heroic, brave', gender: 'Male', origin: 'Turkish' },
{ name: 'Tuğra', urduName: 'تُگرا', meaning: 'Imperial seal, a symbol of the sultan', gender: 'Female', origin: 'Turkish' },
{ name: 'Berkay', urduName: 'برکای', meaning: 'Strong moon, a noble moon', gender: 'Male', origin: 'Turkish' },
{ name: 'Emine', urduName: 'امینے', meaning: 'Trustworthy, faithful', gender: 'Female', origin: 'Turkish' },
{ name: 'Hakan', urduName: 'ہاکان', meaning: 'Emperor, ruler', gender: 'Male', origin: 'Turkish' },
{ name: 'Ayla', urduName: 'آئلہ', meaning: 'Moonlight, halo', gender: 'Female', origin: 'Turkish' },
{ name: 'Volkan', urduName: 'وولکان', meaning: 'Volcano, fiery', gender: 'Male', origin: 'Turkish' },
{ name: 'Efe', urduName: 'ایفے', meaning: 'Brave, noble', gender: 'Male', origin: 'Turkish' },
{ name: 'Zeytin', urduName: 'زیتِن', meaning: 'Olive, olive tree', gender: 'Male', origin: 'Turkish' },
{ name: 'Tuba', urduName: 'تُبا', meaning: 'A tree in heaven', gender: 'Female', origin: 'Turkish' },
{ name: 'Derya', urduName: 'دریا', meaning: 'Sea, ocean', gender: 'Female', origin: 'Turkish' },
{ name: 'Cemre', urduName: 'جمری', meaning: 'The warmth of spring', gender: 'Female', origin: 'Turkish' },
{ name: 'Narin', urduName: 'نارِن', meaning: 'Delicate, graceful', gender: 'Female', origin: 'Turkish' },
{ name: 'Feride', urduName: 'فریدہ', meaning: 'Unique, precious', gender: 'Female', origin: 'Turkish' },
{ name: 'Emre', urduName: 'امرے', meaning: 'Friend, brother', gender: 'Male', origin: 'Turkish' },
{ name: 'İrem', urduName: 'ایرم', meaning: 'Paradise, a mythical garden', gender: 'Female', origin: 'Turkish' },
{ name: 'Parisa', urduName: 'پریسا', meaning: 'Like a fairy', gender: 'Female', origin: 'Persian' },
{ name: 'Kian', urduName: 'کیان', meaning: 'Realm, kingdom', gender: 'Male', origin: 'Persian' },
{ name: 'Niloofar', urduName: 'نیلوفر', meaning: 'Water lily, lotus', gender: 'Female', origin: 'Persian' },
{ name: 'Arya', urduName: 'آریا', meaning: 'Noble, pure', gender: 'Male', origin: 'Persian' },
{ name: 'Shirin', urduName: 'شیرین', meaning: 'Sweet, charming', gender: 'Female', origin: 'Persian' },
{ name: 'Ramin', urduName: 'رامن', meaning: 'A character from Persian literature, symbolizing love and sacrifice', gender: 'Male', origin: 'Persian' },
{ name: 'Dariush', urduName: 'داریوش', meaning: 'Wealthy, kingly', gender: 'Male', origin: 'Persian' },
{ name: 'Soraya', urduName: 'ثریا', meaning: 'Princess, the Pleiades (a cluster of stars)', gender: 'Female', origin: 'Persian' },
{ name: 'Samira', urduName: 'سمیرا', meaning: 'Companion in evening talk, entertaining', gender: 'Female', origin: 'Persian' },
{ name: 'Omid', urduName: 'امید', meaning: 'Hope', gender: 'Male', origin: 'Persian' },
{ name: 'Zahra', urduName: 'زہرا', meaning: 'Radiant, brilliant', gender: 'Female', origin: 'Persian' },
{ name: 'Arash', urduName: 'آرش', meaning: 'A heroic figure in Persian mythology', gender: 'Male', origin: 'Persian' },
{ name: 'Ladan', urduName: 'لادن', meaning: 'A fragrant flower', gender: 'Female', origin: 'Persian' },
{ name: 'Navid', urduName: 'نوید', meaning: 'Good news, glad tidings', gender: 'Male', origin: 'Persian' },
{ name: 'Zainab', urduName: 'زینب', meaning: 'A precious gem', gender: 'Female', origin: 'Persian' },
{ name: 'Kaveh', urduName: 'کاوِہ', meaning: 'A mythological figure known for rebellion', gender: 'Male', origin: 'Persian' },
{ name: 'Shahla', urduName: 'شہلا', meaning: 'Dark-eyed, a name of beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Yasmin', urduName: 'یاسمین', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
{ name: 'Cyrus', urduName: 'کیروس', meaning: 'Sun, throne, like a king', gender: 'Male', origin: 'Persian' },
{ name: 'Leila', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Farid', urduName: 'فرید', meaning: 'Unique, precious', gender: 'Male', origin: 'Persian' },
{ name: 'Parvaneh', urduName: 'پروانہ', meaning: 'Butterfly', gender: 'Female', origin: 'Persian' },
{ name: 'Roxan', urduName: 'رکسان', meaning: 'Bright, dawn', gender: 'Female', origin: 'Persian' },
{ name: 'Khosrow', urduName: 'خسرو', meaning: 'King, ruler', gender: 'Male', origin: 'Persian' },
{ name: 'Niloufar', urduName: 'نیلوفر', meaning: 'Water lily', gender: 'Female', origin: 'Persian' },
{ name: 'Javad', urduName: 'جواد', meaning: 'Generous', gender: 'Male', origin: 'Persian' },
{ name: 'Taraneh', urduName: 'ترانہ', meaning: 'Song, melody', gender: 'Female', origin: 'Persian' },
{ name: 'Mehrdad', urduName: 'مہر داد', meaning: 'Gift of the sun, kindness', gender: 'Male', origin: 'Persian' },
{ name: 'Shahram', urduName: 'شہرام', meaning: 'A Persian king, famed for courage', gender: 'Male', origin: 'Persian' },
{ name: 'Sima', urduName: 'سیما', meaning: 'Face, image, beauty', gender: 'Female', origin: 'Persian' },
{ name: 'Arman', urduName: 'آرمان', meaning: 'Desire, wish, goal', gender: 'Male', origin: 'Persian' },
{ name: 'Zain', urduName: 'زین', meaning: 'Beauty, grace', gender: 'Male', origin: 'Persian' },
{ name: 'Mahnaz', urduName: 'مَہنَاز', meaning: 'The beauty of the moon', gender: 'Female', origin: 'Persian' },
{ name: 'Nima', urduName: 'نیما', meaning: 'Just, fair', gender: 'Male', origin: 'Persian' },
{ name: 'Sanam', urduName: 'صنم', meaning: 'Beloved, idol', gender: 'Female', origin: 'Persian' },
{ name: 'Vahid', urduName: 'وحید', meaning: 'Unique, only one', gender: 'Male', origin: 'Persian' },
{ name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, morning', gender: 'Female', origin: 'Persian' },
{ name: 'Arman', urduName: 'آرمان', meaning: 'Ideal, dream, goal', gender: 'Male', origin: 'Persian' },
{ name: 'Shadi', urduName: 'شادِی', meaning: 'Happiness, joy', gender: 'Female', origin: 'Persian' },
{ name: 'Kian', urduName: 'کیان', meaning: 'Realm, kingdom', gender: 'Male', origin: 'Persian' },
{ name: 'Mehr', urduName: 'مہر', meaning: 'Sun, affection, love', gender: 'Male', origin: 'Persian' },
{ name: 'Zeynab', urduName: 'زینب', meaning: 'A precious gem, father’s jewel', gender: 'Female', origin: 'Persian' },
{ name: 'Farrah', urduName: 'فَرَح', meaning: 'Joy, happiness', gender: 'Female', origin: 'Persian' },
{ name: 'Kamal', urduName: 'کمال', meaning: 'Perfection, excellence', gender: 'Male', origin: 'Persian' },
{ name: 'Amin', urduName: 'امین', meaning: 'Trustworthy, honest', gender: 'Male', origin: 'Persian' },
{ name: 'Roya', urduName: 'رؤیا', meaning: 'Dream, vision', gender: 'Female', origin: 'Persian' },
{ name: 'Yasaman', urduName: 'یاسمن', meaning: 'Jasmine flower', gender: 'Female', origin: 'Persian' },
{ name: 'Nadir', urduName: 'نادر', meaning: 'Rare, precious', gender: 'Male', origin: 'Persian' },
{ name: 'Goli', urduName: 'گلی', meaning: 'Flower, rose', gender: 'Female', origin: 'Persian' },
{ name: 'Javad', urduName: 'جواد', meaning: 'Generous', gender: 'Male', origin: 'Persian' },
{ name: 'Nima', urduName: 'نیما', meaning: 'Fair, just', gender: 'Male', origin: 'Persian' },
{ name: 'Zahra', urduName: 'زہرا', meaning: 'Radiant, brilliant', gender: 'Female', origin: 'Persian' },
{ name: 'Arash', urduName: 'آرش', meaning: 'Heroic figure in Persian mythology', gender: 'Male', origin: 'Persian' },
{ name: 'Anahita', urduName: 'انہیتا', meaning: 'Goddess of water, purity', gender: 'Female', origin: 'Persian' },
{ name: 'Shahram', urduName: 'شہرام', meaning: 'A Persian king, famed for courage', gender: 'Male', origin: 'Persian' },
{ name: 'Dariush', urduName: 'داریوش', meaning: 'Wealthy, kingly', gender: 'Male', origin: 'Persian' },
{ name: 'Ladan', urduName: 'لادن', meaning: 'A fragrant flower', gender: 'Female', origin: 'Persian' },
{ name: 'Sima', urduName: 'سیما', meaning: 'Face, beauty, image', gender: 'Female', origin: 'Persian' },
{ name: 'Vahid', urduName: 'وحید', meaning: 'Unique, the one', gender: 'Male', origin: 'Persian' },
{ name: 'Mehrdad', urduName: 'مہر داد', meaning: 'Gift of the sun, kindness', gender: 'Male', origin: 'Persian' },
{ name: 'Kian', urduName: 'کیان', meaning: 'Realm, kingdom', gender: 'Male', origin: 'Persian' },
{ name: 'Parvaneh', urduName: 'پروانہ', meaning: 'Butterfly', gender: 'Female', origin: 'Persian' },
{ name: 'Roxana', urduName: 'رکسانہ', meaning: 'Bright, dawn', gender: 'Female', origin: 'Persian' },
{ name: 'Niloofar', urduName: 'نیلوفر', meaning: 'Water lily', gender: 'Female', origin: 'Persian' },
{ name: 'Zeynab', urduName: 'زینب', meaning: 'A precious gem', gender: 'Female', origin: 'Persian' },
{ name: 'Ramin', urduName: 'رامن', meaning: 'A Persian mythical hero, symbolizing love', gender: 'Male', origin: 'Persian' },
{ name: 'Ava', urduName: 'آوا', meaning: 'Voice, sound', gender: 'Female', origin: 'Persian' },
{ name: 'Sahar', urduName: 'سحر', meaning: 'Dawn, morning', gender: 'Female', origin: 'Persian' },
{ name: 'Ramin', urduName: 'رامن', meaning: 'A character from Persian literature', gender: 'Male', origin: 'Persian' },
{ name: 'Mahin', urduName: 'ماہین', meaning: 'Of the moon, moonlight', gender: 'Female', origin: 'Persian' },
{ name: 'Taraneh', urduName: 'ترانہ', meaning: 'Song, melody', gender: 'Female', origin: 'Persian' },
{ name: 'Zohreh', urduName: 'زہرا', meaning: 'Venus, bright star', gender: 'Female', origin: 'Persian' },
{ name: 'Farid', urduName: 'فرید', meaning: 'Unique, precious', gender: 'Male', origin: 'Persian' },
{ name: 'Nima', urduName: 'نیما', meaning: 'Just, fair', gender: 'Male', origin: 'Persian' },
{ name: 'Mahsa', urduName: 'مہسا', meaning: 'Like the moon', gender: 'Female', origin: 'Persian' },
{ name: 'Kourosh', urduName: 'کوروش', meaning: 'Like the sun, radiant', gender: 'Male', origin: 'Persian' },
{ name: 'Negin', urduName: 'نگین', meaning: 'Precious stone, gem', gender: 'Female', origin: 'Persian' },
{ name: 'Amir', urduName: 'امیر', meaning: 'Prince, leader', gender: 'Male', origin: 'Persian' },
{ name: 'Laleh', urduName: 'لالہ', meaning: 'Tulip, flower', gender: 'Female', origin: 'Persian' },
{ name: 'Omid', urduName: 'امید', meaning: 'Hope', gender: 'Male', origin: 'Persian' },
{ name: 'Setareh', urduName: 'ستارِہ', meaning: 'Star', gender: 'Female', origin: 'Persian' },
{ name: 'Amina', urduName: 'امینہ', meaning: 'Trustworthy, honest', gender: 'Female', origin: 'Arabic' },
{ name: 'Ali', urduName: 'علی', meaning: 'Exalted, noble', gender: 'Male', origin: 'Arabic' },
{ name: 'Fatimah', urduName: 'فاطمہ', meaning: 'Captivating, one who abstains', gender: 'Female', origin: 'Arabic' },
{ name: 'Zainab', urduName: 'زینب', meaning: 'Father’s precious jewel', gender: 'Female', origin: 'Arabic' },
{ name: 'Omar', urduName: 'عمر', meaning: 'Life, long-lived', gender: 'Male', origin: 'Arabic' },
{ name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
{ name: 'Hussain', urduName: 'حسین', meaning: 'Good, handsome, beautiful', gender: 'Male', origin: 'Arabic' },
{ name: 'Maryam', urduName: 'مریم', meaning: 'Pious, mother of Prophet Isa (Jesus)', gender: 'Female', origin: 'Arabic' },
{ name: 'Yusuf', urduName: 'یوسف', meaning: 'God increases, Prophet Joseph', gender: 'Male', origin: 'Arabic' },
{ name: 'Bilal', urduName: 'بلال', meaning: 'Water, moistening, name of the first muezzin (caller to prayer)', gender: 'Male', origin: 'Arabic' },
{ name: 'Musa', urduName: 'موسی', meaning: 'Moses, drawn from water', gender: 'Male', origin: 'Arabic' },
{ name: 'Aminah', urduName: 'امینہ', meaning: 'Trustworthy, faithful', gender: 'Female', origin: 'Arabic' },
{ name: 'Suleiman', urduName: 'سلیمان', meaning: 'Peaceful, King Solomon', gender: 'Male', origin: 'Arabic' },
{ name: 'Ruqayyah', urduName: 'رقیہ', meaning: 'To rise, to ascend', gender: 'Female', origin: 'Arabic' },
{ name: 'Yunus', urduName: 'یونس', meaning: 'Dove, Prophet Jonah', gender: 'Male', origin: 'Arabic' },
{ name: 'Ayyub', urduName: 'ایوب', meaning: 'Patient, Job (a prophet)', gender: 'Male', origin: 'Arabic' },
{ name: 'Laila', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
{ name: 'Rida', urduName: 'رضا', meaning: 'Contentment, acceptance', gender: 'Female', origin: 'Arabic' },
{ name: 'Jannah', urduName: 'جنہ', meaning: 'Paradise, garden', gender: 'Female', origin: 'Arabic' },
{ name: 'Zayd', urduName: 'زید', meaning: 'Growth, abundance', gender: 'Male', origin: 'Arabic' },
{ name: 'Khadijah', urduName: 'خدیجہ', meaning: 'Early baby, first wife of Prophet Muhammad (PBUH)', gender: 'Female', origin: 'Arabic' },
{ name: 'Jibril', urduName: 'جبرائیل', meaning: 'Gabriel, archangel', gender: 'Male', origin: 'Arabic' },
{ name: 'Anas', urduName: 'انس', meaning: 'Affection, love', gender: 'Male', origin: 'Arabic' },
{ name: 'Fajr', urduName: 'فجر', meaning: 'Dawn, the first light', gender: 'Female', origin: 'Arabic' },
{ name: 'Kamilah', urduName: 'کاملہ', meaning: 'Perfection, complete', gender: 'Female', origin: 'Arabic' },
{ name: 'Zaki', urduName: 'زکی', meaning: 'Pure, intelligent', gender: 'Male', origin: 'Arabic' },
{ name: 'Rashid', urduName: 'رشید', meaning: 'Rightly guided', gender: 'Male', origin: 'Arabic' },
{ name: 'Bashir', urduName: 'بشیر', meaning: 'Bringer of good news', gender: 'Male', origin: 'Arabic' },
{ name: 'Mariam', urduName: 'مریم', meaning: 'Pure, Virgin Mary', gender: 'Female', origin: 'Arabic' },
{ name: 'Saad', urduName: 'سعد', meaning: 'Prosperity, good fortune', gender: 'Male', origin: 'Arabic' },
{ name: 'Amina', urduName: 'امینہ', meaning: 'Trustworthy, peaceful', gender: 'Female', origin: 'Arabic' },
{ name: 'Shams', urduName: 'شمس', meaning: 'Sun, radiant', gender: 'Male', origin: 'Arabic' },
{ name: 'Farhan', urduName: 'فرحان', meaning: 'Happy, joyous', gender: 'Male', origin: 'Arabic' },
{ name: 'Mujtaba', urduName: 'مجتبیٰ', meaning: 'Chosen one', gender: 'Male', origin: 'Arabic' },
{ name: 'Fawzi', urduName: 'فوزی', meaning: 'Victorious, successful', gender: 'Male', origin: 'Arabic' },
{ name: 'Zuhair', urduName: 'زہیر', meaning: 'Bright, shining', gender: 'Male', origin: 'Arabic' },
{ name: 'Dua', urduName: 'دعا', meaning: 'Supplication, prayer', gender: 'Female', origin: 'Arabic' },
{ name: 'Hasan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
{ name: 'Maha', urduName: 'ماہا', meaning: 'Oryx, wild cow, moonlight', gender: 'Female', origin: 'Arabic' },
{ name: 'Ayah', urduName: 'آیہ', meaning: 'Sign, verse from the Quran', gender: 'Female', origin: 'Arabic' },
{ name: 'Ibrahim', urduName: 'ابراہیم', meaning: 'Father of many nations, Prophet Abraham', gender: 'Male', origin: 'Arabic' },
{ name: 'Salim', urduName: 'سلیم', meaning: 'Safe, healthy', gender: 'Male', origin: 'Arabic' },
{ name: 'Zayn', urduName: 'زین', meaning: 'Beauty, grace', gender: 'Male', origin: 'Arabic' },
{ name: 'Ayub', urduName: 'ایوب', meaning: 'Job, patience', gender: 'Male', origin: 'Arabic' },
{ name: 'Nuh', urduName: 'نوح', meaning: 'Noah, the Prophet', gender: 'Male', origin: 'Arabic' },
{ name: 'Asma', urduName: 'اسماء', meaning: 'Exalted, high status', gender: 'Female', origin: 'Arabic' },
{ name: 'Tariq', urduName: 'طارق', meaning: 'Morning star, he who knocks', gender: 'Male', origin: 'Arabic' },
{ name: 'Rashida', urduName: 'رشیدہ', meaning: 'Rightly guided', gender: 'Female', origin: 'Arabic' },
{ name: 'Samira', urduName: 'سمیرا', meaning: 'Companion in evening talk, entertaining', gender: 'Female', origin: 'Arabic' },
{ name: 'Musa', urduName: 'موسی', meaning: 'Moses, prophet', gender: 'Male', origin: 'Arabic' },
{ name: 'Iman', urduName: 'ایمان', meaning: 'Faith, belief', gender: 'Female', origin: 'Arabic' },
{ name: 'Khadijah', urduName: 'خدیجہ', meaning: 'Early baby, first wife of Prophet Muhammad (PBUH)', gender: 'Female', origin: 'Arabic' },
{ name: 'Safa', urduName: 'صفا', meaning: 'Purity, clarity', gender: 'Female', origin: 'Arabic' },
{ name: 'Dawood', urduName: 'داؤد', meaning: 'David, beloved', gender: 'Male', origin: 'Arabic' },
{ name: 'Layla', urduName: 'لیلا', meaning: 'Night, dark beauty', gender: 'Female', origin: 'Arabic' },
{ name: 'Jameela', urduName: 'جمیلہ', meaning: 'Beautiful', gender: 'Female', origin: 'Arabic' },
{ name: 'Amir', urduName: 'امیر', meaning: 'Prince, leader', gender: 'Male', origin: 'Arabic' },
{ name: 'Mariam', urduName: 'مریم', meaning: 'Pure, Virgin Mary', gender: 'Female', origin: 'Arabic' },
{ name: 'Sami', urduName: 'سامی', meaning: 'Elevated, sublime', gender: 'Male', origin: 'Arabic' },
{ name: 'Ruqayya', urduName: 'رقیہ', meaning: 'Rise, ascend', gender: 'Female', origin: 'Arabic' },
{ name: 'Huda', urduName: 'ہدیٰ', meaning: 'Guidance', gender: 'Female', origin: 'Arabic' },
{ name: 'Nadia', urduName: 'نادیا', meaning: 'Caller, announcer', gender: 'Female', origin: 'Arabic' },
{ name: 'Zuhair', urduName: 'زہیر', meaning: 'Bright, radiant', gender: 'Male', origin: 'Arabic' },
{ name: 'Tayyib', urduName: 'طیب', meaning: 'Pure, good, kind', gender: 'Male', origin: 'Arabic' },
{ name: 'Jibril', urduName: 'جبرائیل', meaning: 'Gabriel, archangel', gender: 'Male', origin: 'Arabic' },
{ name: 'Bilal', urduName: 'بلال', meaning: 'Moisture, water', gender: 'Male', origin: 'Arabic' },
{ name: 'Shamsa', urduName: 'شمسہ', meaning: 'Sun, radiant', gender: 'Female', origin: 'Arabic' },
{ name: 'Amira', urduName: 'امیرہ', meaning: 'Princess, leader', gender: 'Female', origin: 'Arabic' },
{ name: 'Musa', urduName: 'موسی', meaning: 'Moses, the prophet', gender: 'Male', origin: 'Arabic' },
{ name: 'Fatimah', urduName: 'فاطمہ', meaning: 'Captivating, one who abstains', gender: 'Female', origin: 'Arabic' },
{ name: 'Rabi', urduName: 'رابی', meaning: 'Spring, garden', gender: 'Male', origin: 'Arabic' },
{ name: 'Ayman', urduName: 'ایمن', meaning: 'Blessed, lucky', gender: 'Male', origin: 'Arabic' },
{ name: 'Sabah', urduName: 'صباح', meaning: 'Morning', gender: 'Female', origin: 'Arabic' },
{ name: 'Zayd', urduName: 'زید', meaning: 'Growth, increase', gender: 'Male', origin: 'Arabic' },
{ name: 'Bashir', urduName: 'بشیر', meaning: 'Bringer of good news', gender: 'Male', origin: 'Arabic' },
{ name: 'Rafee', urduName: 'رفیع', meaning: 'Exalted, elevated', gender: 'Male', origin: 'Arabic' },
{ name: 'Karim', urduName: 'کریم', meaning: 'Generous, noble', gender: 'Male', origin: 'Arabic' },
{ name: 'Aziz', urduName: 'عزیز', meaning: 'Powerful, respected', gender: 'Male', origin: 'Arabic' },
{ name: 'Nawal', urduName: 'نوال', meaning: 'Gift, grace', gender: 'Female', origin: 'Arabic' },
{ name: 'Wahid', urduName: 'وحید', meaning: 'Unique, the only one', gender: 'Male', origin: 'Arabic' },
{ name: 'Hassan', urduName: 'حسن', meaning: 'Handsome, good', gender: 'Male', origin: 'Arabic' },
{ name: 'Kamil', urduName: 'کامل', meaning: 'Perfect, complete', gender: 'Male', origin: 'Arabic' },
{ name: 'Tariq', urduName: 'طارق', meaning: 'He who knocks, morning star', gender: 'Male', origin: 'Arabic' },
{ name: 'Aariz', urduName: 'عارِض', meaning: 'Respectable, intelligent', gender: 'Male', origin: 'Arabic' },
{ name: 'Barirah', urduName: 'بریرہ', meaning: 'Pious, virtuous', gender: 'Female', origin: 'Arabic' },
{ name: 'Fawzan', urduName: 'فوزان', meaning: 'Successful, prosperous', gender: 'Male', origin: 'Arabic' },
{ name: 'Rashma', urduName: 'رشما', meaning: 'Ray of light, beam', gender: 'Female', origin: 'Arabic' },
{ name: 'Tanveer', urduName: 'تنویر', meaning: 'Radiance, light', gender: 'Male', origin: 'Arabic' },
{ name: 'Zahira', urduName: 'زاہِرہ', meaning: 'Bright, luminous', gender: 'Female', origin: 'Arabic' },
{ name: 'Suhayl', urduName: 'سہیل', meaning: 'A star, gentle, smooth', gender: 'Male', origin: 'Arabic' },
{ name: 'Amara', urduName: 'عمَرہ', meaning: 'Eternal, graceful', gender: 'Female', origin: 'Arabic' },
{ name: 'Feroze', urduName: 'فروز', meaning: 'Victorious, bright', gender: 'Male', origin: 'Persian' },
{ name: 'Rayhan', urduName: 'ریحان', meaning: 'Basil, fragrance', gender: 'Male', origin: 'Arabic' },
{ name: 'Nadira', urduName: 'نادرہ', meaning: 'Rare, unique', gender: 'Female', origin: 'Arabic' },
{ name: 'Junaid', urduName: 'جُنید', meaning: 'Soldier, warrior', gender: 'Male', origin: 'Arabic' },
{ name: 'Zareen', urduName: 'زرین', meaning: 'Golden, precious', gender: 'Female', origin: 'Persian' },
{ name: 'Marwan', urduName: 'مروان', meaning: 'Flint stone, solid', gender: 'Male', origin: 'Arabic' },
{ name: 'Nafisa', urduName: 'نفیسہ', meaning: 'Precious, valuable', gender: 'Female', origin: 'Arabic' },
{ name: 'Ayaan', urduName: 'ایان', meaning: 'Gift of God, fortunate', gender: 'Male', origin: 'Arabic' },
{ name: 'Shahira', urduName: 'شاہِرہ', meaning: 'Famous, renowned', gender: 'Female', origin: 'Arabic' },
{ name: 'Imad', urduName: 'عماد', meaning: 'Pillar, support', gender: 'Male', origin: 'Arabic' },
{ name: 'Sadiya', urduName: 'سادیہ', meaning: 'Lucky, fortunate', gender: 'Female', origin: 'Arabic' },
{ name: 'Ihsan', urduName: 'احسان', meaning: 'Charity, excellence', gender: 'Male', origin: 'Arabic' },
{ name: 'Khaula', urduName: 'خولہ', meaning: 'A strong, graceful woman', gender: 'Female', origin: 'Arabic' },
{ name: 'Talha', urduName: 'طلحہ', meaning: 'A companion of the Prophet Muhammad (PBUH)', gender: 'Male', origin: 'Arabic' },
{ name: 'Aneesah', urduName: 'انِیسہ', meaning: 'Friendly, companion', gender: 'Female', origin: 'Arabic' },
{ name: 'Ziyan', urduName: 'زیان', meaning: 'Grace, beauty', gender: 'Male', origin: 'Arabic' },
{ name: 'Anisa', urduName: 'انِیسہ', meaning: 'Friendly, sociable', gender: 'Female', origin: 'Arabic' },
{ name: 'Fahim', urduName: 'فہِم', meaning: 'Intelligent, wise', gender: 'Male', origin: 'Arabic' },
{ name: 'Khushbu', urduName: 'خُوشبو', meaning: 'Fragrance, aroma', gender: 'Female', origin: 'Arabic' },
{ name: 'Nawaf', urduName: 'نواف', meaning: 'High, elevated', gender: 'Male', origin: 'Arabic' },
{ name: 'Taqwa', urduName: 'تقویٰ', meaning: 'Piety, God-consciousness', gender: 'Female', origin: 'Arabic' },
{ name: 'Zulfiqar', urduName: 'ذوالفقار', meaning: 'A sword of Imam Ali, sharp', gender: 'Male', origin: 'Arabic' },
{ name: 'Raihan', urduName: 'ریحان', meaning: 'Basil, sweet-smelling herb', gender: 'Male', origin: 'Arabic' },
{ name: 'Alayna', urduName: 'عَیْنَہ', meaning: 'Precious, noble', gender: 'Female', origin: 'Arabic' },
{ name: 'Jameel', urduName: 'جمیل', meaning: 'Beautiful, handsome', gender: 'Male', origin: 'Arabic' },
{ name: 'Ruba', urduName: 'رُبٰی', meaning: 'Joyful, beautiful', gender: 'Female', origin: 'Arabic' },
{ name: 'Muaz', urduName: 'مُعاذ', meaning: 'Protected, defended', gender: 'Male', origin: 'Arabic' },
{ name: 'Maha', urduName: 'ماہا', meaning: 'Moonlight, wild ox', gender: 'Female', origin: 'Arabic' },
{ name: 'Shuja', urduName: 'شجاع', meaning: 'Brave, courageous', gender: 'Male', origin: 'Arabic' },
{ name: 'Fariha', urduName: 'فرحہ', meaning: 'Happy, joyful', gender: 'Female', origin: 'Arabic' },
{ name: 'Basil', urduName: 'باسل', meaning: 'Brave, lion', gender: 'Male', origin: 'Arabic' },
{ name: 'Samir', urduName: 'سمِیر', meaning: 'Companion of evening talk', gender: 'Male', origin: 'Arabic' },
{ name: 'Rabia', urduName: 'رابعہ', meaning: 'Spring, garden', gender: 'Female', origin: 'Arabic' },
{ name: 'Naseem', urduName: 'نسیم', meaning: 'Breeze, fresh air', gender: 'Male', origin: 'Arabic' }












];

// // Function to remove duplicates based on name
// const removeDuplicates = (array) => {
//     const uniqueNames = new Set();
//     return array.filter((item) => {
//         const isDuplicate = uniqueNames.has(item.name);
//         uniqueNames.add(item.name);
//         return !isDuplicate;
//     });
// };

// // Sort the baby names in alphabetical order
// const uniqueBabyNames = removeDuplicates(babyNames);
// const sortedBabyNames = uniqueBabyNames.sort((a, b) => a.name.localeCompare(b.name));

// const IslamicBabyNames = () => {
//   return (
//     <div className="baby-names-container">
//       <h2 className="baby-names-title">اسلامی بچوں کے نام/Islamic Baby Names </h2> {/* Urdu Title */}
//       <table className="baby-names-table">
//         <thead>
//           <tr>
//             <th>Name</th>
//             <th>Urdu Name</th>
//             <th>Meaning</th>
//             <th>Gender</th>
//             <th>Origin</th>
//           </tr>
//         </thead>
//         <tbody>
//           {sortedBabyNames.map((baby, index) => (
//             <tr key={index}>
//               <td>{baby.name}</td>
//               <td>{baby.urduName}</td>
//               <td>{baby.meaning}</td>
//               <td>{baby.gender}</td>
//               <td>{baby.origin}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default IslamicBabyNames;
// Function to remove duplicates based on name
const removeDuplicates = (array) => {
  const uniqueNames = new Set();
  return array.filter((item) => {
      const isDuplicate = uniqueNames.has(item.name);
      uniqueNames.add(item.name);
      return !isDuplicate;
  });
};


const uniqueBabyNames = removeDuplicates(babyNames);
const sortedBabyNames = uniqueBabyNames.sort((a, b) => a.name.localeCompare(b.name));

const IslamicBabyNames = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredNames = sortedBabyNames.filter((baby) =>
        baby.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        baby.urduName.includes(searchTerm) ||
        baby.meaning.toLowerCase().includes(searchTerm.toLowerCase()) ||
        baby.gender.toLowerCase().includes(searchTerm.toLowerCase()) ||
        baby.origin.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="baby-names-container">
            <h2 className="baby-names-title">اسلامی بچوں کے نام/Islamic Baby Names</h2>
            <input
                type="text"
                placeholder="Search names..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <table className="baby-names-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Urdu Name</th>
                        <th>Meaning</th>
                        <th>Gender</th>
                        <th>Origin</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredNames.map((baby, index) => (
                        <tr key={index}>
                            <td>{baby.name}</td>
                            <td>{baby.urduName}</td>
                            <td>{baby.meaning}</td>
                            <td>{baby.gender}</td>
                            <td>{baby.origin}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default IslamicBabyNames;