import React, { useState, useEffect } from 'react';
import './IslamicFigures.css';

const IslamicFigures = () => {
  const [figures, setFigures] = useState([]);
  const [selectedFigure, setSelectedFigure] = useState(null);

  useEffect(() => {
    const fetchFigures = () => {
      const figuresData = [
        {
          Name: "Imam Ali ibn Abi Talib",
          DateOfBirth: "600 AD",
          DateOfDeath: "661 AD",
          PlaceOfBirth: "Mecca, Saudi Arabia",
          Role: "Fourth Caliph, Military Leader, Islamic Scholar",
          NotableAchievements: "The fourth caliph of the Rashidun Caliphate, known for his justice, bravery, and eloquent speeches.",
          ContributionsToIslam: "A key figure in Islamic history, known for his leadership and contributions to Islamic jurisprudence and philosophy.",
          Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Imam_Ali_(137).jpg/220px-Imam_Ali_(137).jpg"
        },
        {
          Name: "Salah ad-Din (Saladin)",
          DateOfBirth: "1137 AD",
          DateOfDeath: "1193 AD",
          PlaceOfBirth: "Tikrit, Iraq",
          Role: "Sultan, Military Leader",
          NotableAchievements: "Renowned for his leadership during the Crusades and for recapturing Jerusalem from the Crusaders in 1187.",
          ContributionsToIslam: "His commitment to chivalry, tolerance, and justice during his campaigns has made him a legendary figure in Islamic and world history.",
          Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Saladin_Damascus.jpg/220px-Saladin_Damascus.jpg"
        },
        {
          Name: "Umar ibn al-Khattab",
          DateOfBirth: "584 AD",
          DateOfDeath: "644 AD",
          PlaceOfBirth: "Mecca, Saudi Arabia",
          Role: "Second Caliph of the Rashidun Caliphate",
          NotableAchievements: "Under his leadership, the Islamic empire expanded rapidly, conquering large parts of the Byzantine and Persian empires.",
          ContributionsToIslam: "He is credited with establishing many administrative structures and legal reforms in the early Islamic state.",
          Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/63/Umar_ibn_Khattab.jpg/220px-Umar_ibn_Khattab.jpg"
        },
        {
          Name: "Rumi",
          DateOfBirth: "1207 AD",
          DateOfDeath: "1273 AD",
          PlaceOfBirth: "Balkh, Afghanistan",
          Role: "Poet, Theologian, Philosopher",
          NotableAchievements: "Famous for his spiritual poetry, his works have been translated into many languages and remain influential worldwide.",
          ContributionsToIslam: "Founder of the Mevlevi order, known for the Whirling Dervishes, and for his contributions to Sufi mysticism.",
          Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Mevlana_Jalal_al-Din_Rumi.jpg/220px-Mevlana_Jalal_al-Din_Rumi.jpg"
        },
        {
          Name: "Aisha bint Abi Bakr",
          DateOfBirth: "613 AD",
          DateOfDeath: "678 AD",
          PlaceOfBirth: "Mecca, Saudi Arabia",
          Role: "Wife of Prophet Muhammad, Scholar",
          NotableAchievements: "One of the most prominent female figures in early Islamic history, known for her knowledge and contributions to hadith.",
          ContributionsToIslam: "Aisha is revered for her role in the transmission of hadith and her participation in significant events such as the Battle of the Camel.",
          Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Aisha_in_Badr_(After_Sinan).jpg/220px-Aisha_in_Badr_(After_Sinan).jpg"
        },
        // {
        //     Name: "Prophet Muhammad",
        //     DateOfBirth: "570 AD",
        //     DateOfDeath: "632 AD",
        //     PlaceOfBirth: "Mecca, Saudi Arabia",
        //     Role: "Prophet, Founder of Islam",
        //     NotableAchievements: "Founder of Islam, known for his revelation of the Qur'an and his leadership in uniting the Arabian Peninsula under Islam.",
        //     ContributionsToIslam: "Muhammad's teachings form the foundation of Islamic theology, law, and ethics.",
        //     Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6e/Muhammad_portrait_1.jpg/220px-Muhammad_portrait_1.jpg"
        //   },
          {
            Name: "Abu Bakr al-Siddiq",
            DateOfBirth: "573 AD",
            DateOfDeath: "634 AD",
            PlaceOfBirth: "Mecca, Saudi Arabia",
            Role: "First Caliph of the Rashidun Caliphate",
            NotableAchievements: "The first caliph after the death of Prophet Muhammad, he led the Muslim community and expanded the Islamic state.",
            ContributionsToIslam: "Abu Bakr's leadership ensured the unity of the Muslim community, and he is known for his role in preserving and compiling the Qur'an.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Abu_Bakr_al-Siddiq.jpg/220px-Abu_Bakr_al-Siddiq.jpg"
          },
          {
            Name: "Fatimah bint Muhammad",
            DateOfBirth: "605 AD",
            DateOfDeath: "632 AD",
            PlaceOfBirth: "Mecca, Saudi Arabia",
            Role: "Daughter of Prophet Muhammad, Religious Figure",
            NotableAchievements: "Mother of Hasan and Husayn, important figure in early Islamic history and a role model for women.",
            ContributionsToIslam: "Fatimah's role in supporting her father and her later contributions through her children, especially in shaping Islamic leadership, is highly regarded.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Fatimah_bint_Muhammad.jpg/220px-Fatimah_bint_Muhammad.jpg"
          },
          {
            Name: "Imam Hussain ibn Ali",
            DateOfBirth: "626 AD",
            DateOfDeath: "680 AD",
            PlaceOfBirth: "Medina, Saudi Arabia",
            Role: "Imam, Grandson of Prophet Muhammad",
            NotableAchievements: "Known for his martyrdom at the Battle of Karbala, Hussain is a symbol of resistance against tyranny.",
            ContributionsToIslam: "His martyrdom is a defining event in Islamic history, particularly for Shi'a Muslims, emphasizing justice and sacrifice.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Imam_Hussain.jpg/220px-Imam_Hussain.jpg"
          },
          {
            Name: "Al-Ghazali",
            DateOfBirth: "1058 AD",
            DateOfDeath: "1111 AD",
            PlaceOfBirth: "Tus, Iran",
            Role: "Philosopher, Theologian, Jurist",
            NotableAchievements: "A prominent Islamic philosopher and theologian, his works on philosophy, mysticism, and law influenced Islamic thought.",
            ContributionsToIslam: "Al-Ghazali reconciled Sufism with orthodox Islam, contributing to Islamic philosophy and theology.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Al-Ghazali.jpg/220px-Al-Ghazali.jpg"
          },
          {
            Name: "Ibn Rushd (Averroes)",
            DateOfBirth: "1126 AD",
            DateOfDeath: "1198 AD",
            PlaceOfBirth: "Córdoba, Spain",
            Role: "Philosopher, Jurist",
            NotableAchievements: "Known for his commentaries on Aristotle and his works on philosophy and jurisprudence.",
            ContributionsToIslam: "Ibn Rushd’s interpretations of Aristotle had a significant influence on both Islamic and Western medieval philosophy.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/31/Ibn_Rushd.jpg/220px-Ibn_Rushd.jpg"
          },
          {
            Name: "Al-Ma'mun",
            DateOfBirth: "786 AD",
            DateOfDeath: "833 AD",
            PlaceOfBirth: "Baghdad, Iraq",
            Role: "Caliph of the Abbasid Caliphate",
            NotableAchievements: "Al-Ma'mun was known for his patronage of science, philosophy, and the translation movement.",
            ContributionsToIslam: "He is remembered for promoting the intellectual flourishing of the Islamic Golden Age, particularly in mathematics, astronomy, and medicine.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Al-Mamun.jpg/220px-Al-Mamun.jpg"
          },
          {
            Name: "Khadijah bint Khuwaylid",
            DateOfBirth: "555 AD",
            DateOfDeath: "619 AD",
            PlaceOfBirth: "Mecca, Saudi Arabia",
            Role: "Wife of Prophet Muhammad, Businesswoman",
            NotableAchievements: "First wife of Prophet Muhammad and the first person to accept Islam.",
            ContributionsToIslam: "Her support and belief in Prophet Muhammad were crucial in the early years of Islam.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Khadijah_bint_Khuwaylid.jpg/220px-Khadijah_bint_Khuwaylid.jpg"
          },
          {
            Name: "Ibn Khaldun",
            DateOfBirth: "1332 AD",
            DateOfDeath: "1406 AD",
            PlaceOfBirth: "Tunis, Tunisia",
            Role: "Historian, Sociologist, Philosopher",
            NotableAchievements: "A pioneering figure in sociology and historiography, he is best known for his work 'Muqaddimah' on the philosophy of history.",
            ContributionsToIslam: "His groundbreaking ideas in sociology and economics helped lay the foundation for modern social sciences.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Ibn_Khaldun.jpg/220px-Ibn_Khaldun.jpg"
          },
          {
            Name: "Imam Malik ibn Anas",
            DateOfBirth: "711 AD",
            DateOfDeath: "795 AD",
            PlaceOfBirth: "Medina, Saudi Arabia",
            Role: "Islamic Scholar, Jurist",
            NotableAchievements: "Founder of the Maliki school of jurisprudence, one of the four major Sunni schools of law.",
            ContributionsToIslam: "Imam Malik's teachings on Islamic law had a significant impact on the development of Islamic jurisprudence.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Imam_Malik.jpg/220px-Imam_Malik.jpg"
          },
          {
            Name: "Imam Shafi'i",
            DateOfBirth: "767 AD",
            DateOfDeath: "820 AD",
            PlaceOfBirth: "Gaza, Palestine",
            Role: "Islamic Scholar, Jurist",
            NotableAchievements: "Founder of the Shafi'i school of Islamic jurisprudence.",
            ContributionsToIslam: "Imam Shafi'i played a key role in the development of Islamic legal theory and methodology.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Imam_Shafi%27i.jpg/220px-Imam_Shafi%27i.jpg"
          },
          {
            Name: "Imam Abu Hanifa",
            DateOfBirth: "699 AD",
            DateOfDeath: "767 AD",
            PlaceOfBirth: "Kufa, Iraq",
            Role: "Islamic Scholar, Jurist",
            NotableAchievements: "Founder of the Hanafi school of jurisprudence, the most widely followed school of Islamic law.",
            ContributionsToIslam: "His contributions to Islamic jurisprudence and legal thought continue to influence Islamic societies today.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Abu_Hanifa.jpg/220px-Abu_Hanifa.jpg"
          },
          {
            Name: "Imam Ahmad ibn Hanbal",
            DateOfBirth: "780 AD",
            DateOfDeath: "855 AD",
            PlaceOfBirth: "Baghdad, Iraq",
            Role: "Islamic Scholar, Jurist",
            NotableAchievements: "Founder of the Hanbali school of Islamic jurisprudence, known for his strict adherence to the hadith.",
            ContributionsToIslam: "Imam Ahmad's work on hadith and jurisprudence shaped the Hanbali school of thought.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/Imam_Ahmad_ibn_Hanbal.jpg/220px-Imam_Ahmad_ibn_Hanbal.jpg"
          },
          {
            Name: "Saladin (Salah ad-Din)",
            DateOfBirth: "1137 AD",
            DateOfDeath: "1193 AD",
            PlaceOfBirth: "Tikrit, Iraq",
            Role: "Sultan, Military Leader",
            NotableAchievements: "Renowned for his leadership during the Crusades and for recapturing Jerusalem in 1187.",
            ContributionsToIslam: "Saladin's military prowess and ethics in battle made him a revered figure in Islamic history.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c9/Saladin_Damascus.jpg/220px-Saladin_Damascus.jpg"
          },
          {
            Name: "Rumi (Jalal ad-Din Muhammad Rumi)",
            DateOfBirth: "1207 AD",
            DateOfDeath: "1273 AD",
            PlaceOfBirth: "Balkh, Afghanistan",
            Role: "Poet, Theologian, Philosopher",
            NotableAchievements: "Famous for his spiritual poetry and philosophy, Rumi is one of the most influential figures in Islamic mysticism.",
            ContributionsToIslam: "Rumi's teachings on love, spirituality, and the relationship between the human soul and God have left a lasting legacy.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Mevlana_Jalal_al-Din_Rumi.jpg/220px-Mevlana_Jalal_al-Din_Rumi.jpg"
          },
          {
            Name: "Al-Farabi",
            DateOfBirth: "872 AD",
            DateOfDeath: "950 AD",
            PlaceOfBirth: "Farab, Kazakhstan",
            Role: "Philosopher, Scientist",
            NotableAchievements: "Known as the 'Second Teacher' after Aristotle, his works on philosophy, logic, and political theory influenced both Islamic and Western thought.",
            ContributionsToIslam: "Al-Farabi's philosophical ideas on ethics, politics, and metaphysics have had a lasting impact on Islamic intellectual traditions.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Al-Farabi.jpg/220px-Al-Farabi.jpg"
          },
          {
            Name: "Al-Biruni",
            DateOfBirth: "973 AD",
            DateOfDeath: "1048 AD",
            PlaceOfBirth: "Khwarezm, Uzbekistan",
            Role: "Polymath, Scholar",
            NotableAchievements: "Known for his contributions to science, astronomy, and geography.",
            ContributionsToIslam: "Al-Biruni's work in astronomy and his precise calculations have made him a significant figure in the history of Islamic science.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Al_Biruni.jpg/220px-Al_Biruni.jpg"
          },
          {
            Name: "Ibn Sina (Avicenna)",
            DateOfBirth: "980 AD",
            DateOfDeath: "1037 AD",
            PlaceOfBirth: "Bukhara, Uzbekistan",
            Role: "Philosopher, Physician",
            NotableAchievements: "A major figure in the history of medicine and philosophy, Ibn Sina's medical encyclopedia 'The Canon of Medicine' was used in Europe for centuries.",
            ContributionsToIslam: "Ibn Sina's contributions to Islamic philosophy and medicine had a profound influence on both the Islamic world and Europe.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Ibn_Sina.jpg/220px-Ibn_Sina.jpg"
          },
          {
            Name: "Ibn Khaldun",
            DateOfBirth: "1332 AD",
            DateOfDeath: "1406 AD",
            PlaceOfBirth: "Tunis, Tunisia",
            Role: "Historian, Sociologist, Philosopher",
            NotableAchievements: "A pioneering figure in sociology and historiography, his work 'Muqaddimah' laid the groundwork for modern social sciences.",
            ContributionsToIslam: "Ibn Khaldun's theories on society, economics, and history are foundational to both Islamic and Western scholarship.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Ibn_Khaldun.jpg/220px-Ibn_Khaldun.jpg"
          },
          {
            Name: "Abu Hurairah",
            DateOfBirth: "603 AD",
            DateOfDeath: "681 AD",
            PlaceOfBirth: "Yemen",
            Role: "Companion of Prophet Muhammad, Hadith Narrator",
            NotableAchievements: "One of the most prolific narrators of hadith, having reported over 5,000 hadiths.",
            ContributionsToIslam: "Abu Hurairah’s transmission of the teachings of Prophet Muhammad is crucial to the preservation of Islamic tradition.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Abu_Hurayrah.jpg/220px-Abu_Hurayrah.jpg"
          },
          {
            Name: "Imam Ali ibn Abi Talib",
            DateOfBirth: "600 AD",
            DateOfDeath: "661 AD",
            PlaceOfBirth: "Mecca, Saudi Arabia",
            Role: "Fourth Caliph, Military Leader, Islamic Scholar",
            NotableAchievements: "The fourth caliph of the Rashidun Caliphate, known for his justice, bravery, and eloquent speeches.",
            ContributionsToIslam: "A key figure in Islamic history, Imam Ali’s leadership and contributions to Islamic jurisprudence and philosophy are legendary.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Imam_Ali_(137).jpg/220px-Imam_Ali_(137).jpg"
          },
          {
            Name: "Aisha bint Abi Bakr",
            DateOfBirth: "613 AD",
            DateOfDeath: "678 AD",
            PlaceOfBirth: "Mecca, Saudi Arabia",
            Role: "Wife of Prophet Muhammad, Scholar",
            NotableAchievements: "One of the most prominent female figures in early Islamic history, known for her knowledge and contributions to hadith.",
            ContributionsToIslam: "Aisha’s role in the transmission of hadith and her participation in important events such as the Battle of the Camel are crucial to Islamic history.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Aisha_in_Badr_(After_Sinan).jpg/220px-Aisha_in_Badr_(After_Sinan).jpg"
          },
          {
            Name: "Al-Muhasibi",
            DateOfBirth: "781 AD",
            DateOfDeath: "857 AD",
            PlaceOfBirth: "Baghdad, Iraq",
            Role: "Theologian, Mystic",
            NotableAchievements: "A key figure in early Islamic mysticism and theology.",
            ContributionsToIslam: "Al-Muhasibi is known for his works on ethics, psychology, and spirituality within the framework of Islam.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a0/Al-Muhasibi.jpg/220px-Al-Muhasibi.jpg"
          },
          {
            Name: "Al-Tirmidhi",
            DateOfBirth: "824 AD",
            DateOfDeath: "892 AD",
            PlaceOfBirth: "Tirmidh, Uzbekistan",
            Role: "Hadith Scholar",
            NotableAchievements: "Known for compiling one of the most famous collections of hadith in Islam.",
            ContributionsToIslam: "Al-Tirmidhi's compilation of hadiths remains one of the six major hadith collections in Sunni Islam.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Al-Tirmidhi.jpg/220px-Al-Tirmidhi.jpg"
          },
          {
            Name: "Imam Zayn al-Abidin",
            DateOfBirth: "658 AD",
            DateOfDeath: "713 AD",
            PlaceOfBirth: "Medina, Saudi Arabia",
            Role: "Imam, Son of Imam Hussain",
            NotableAchievements: "Known for his piety and devotion, Imam Zayn al-Abidin survived the Battle of Karbala and became a key figure in the Shi'a tradition.",
            ContributionsToIslam: "Imam Zayn al-Abidin is revered for his prayers and supplications, which are a significant part of Islamic spiritual literature.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Zayn_al-Abidin.jpg/220px-Zayn_al-Abidin.jpg"
          },
          {
            Name: "Al-Tabari",
            DateOfBirth: "838 AD",
            DateOfDeath: "923 AD",
            PlaceOfBirth: "Amol, Iran",
            Role: "Historian, Theologian",
            NotableAchievements: "Known for his famous historical and Quranic exegesis works.",
            ContributionsToIslam: "Al-Tabari’s historical writings are considered among the most important in Islamic historiography.",
            Portrait: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/eb/Al-Tabari.jpg/220px-Al-Tabari.jpg"
          }
      ];
      setFigures(figuresData);
    };

    fetchFigures();
  }, []);

  const handleFigureClick = (figure) => {
    setSelectedFigure(figure);
  };

  const closeFigurePopup = () => {
    setSelectedFigure(null);
  };

  return (
    <div className="figures-container">
      <h2>Notable Islamic Figures</h2>
      <div className="figure-list">
        {figures.map((figure, index) => (
          <div
            key={index}
            className="figure-item"
            onClick={() => handleFigureClick(figure)}
          >
            {/* <img
              src={figure.Portrait}
              className="figure-image"
              alt={figure.Name}
            /> */}
            <h3>{figure.Name}</h3>
            <p>{figure.Role}</p>
          </div>
        ))}
      </div>

      {selectedFigure && (
        <div className="popup">
          <div className="popup-content">
            <h3>{selectedFigure.Name}</h3>
            <p><strong>Date of Birth:</strong> {selectedFigure.DateOfBirth}</p>
            <p><strong>Place of Birth:</strong> {selectedFigure.PlaceOfBirth}</p>
            <p><strong>Date of Death:</strong> {selectedFigure.DateOfDeath}</p>
            <p><strong>Role:</strong> {selectedFigure.Role}</p>
            <p><strong>Notable Achievements:</strong> {selectedFigure.NotableAchievements}</p>
            <p><strong>Contributions to Islam:</strong> {selectedFigure.ContributionsToIslam}</p>
            <button className="close-button" onClick={closeFigurePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IslamicFigures;
