import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // We don't need to export other functions here
import { collection, addDoc, query, orderBy, limit, getDocs } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCBQnk6Agbd-xXY6G8CkrC3YzJEb6Q0Gqs",
  authDomain: "the-quran-club.firebaseapp.com",
  projectId: "the-quran-club",
  storageBucket: "the-quran-club.firebasestorage.app",
  messagingSenderId: "291396283996",
  appId: "1:291396283996:web:2b54270d8af0664aa30099",
  measurementId: "G-Z4MV98K572"
};

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app); // Initialize Auth
// const db = getFirestore(app); // Initialize Firestore
// const analytics = getAnalytics(app);

// // Export the auth and db instances
// export { auth, db };



const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


// Export db to use in other parts of the app
export { db, collection, addDoc, query, orderBy, limit, getDocs };

export const saveQuizData = async (name, score, timeTaken) => {
  try {
    await addDoc(collection(db, "quiz_scores"), {
      name: name,
      score: score,
      timeTaken: timeTaken,
      date: new Date(),
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getLeaderboard = async () => {
  const q = query(collection(db, "quiz_scores"), orderBy("score", "desc"), orderBy("timeTaken", "asc"), limit(10));
  const querySnapshot = await getDocs(q);
  const leaderboard = [];
  querySnapshot.forEach((doc) => {
    leaderboard.push(doc.data());
  });
  return leaderboard;
};

// Fetch the scoreboard
export const fetchScoreboard = async () => {
  try {
    const q = query(
      collection(db, "quiz_scores"),
      orderBy("score", "desc"),
      orderBy("timeTaken", "asc"),
      limit(10)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Error fetching scoreboard: ", error);
    return [];
  }
};
