import React, { useState, useEffect } from 'react';
import './HaramFoods.css';

const HaramFoods = () => {
  const [foods, setFoods] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);

  useEffect(() => {
    const fetchFoods = () => {
      const foodsData = [
        {
          Name: "Pork",
          Description: "Pork and pork products are considered haram (forbidden) in Islam due to religious prohibitions found in the Quran.",
          Reasons: "Muslims are prohibited from consuming pork because it is mentioned as impure in the Quran (2:173, 5:3, 6:145, 16:115).",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Pig_%28unsplash%29.jpg/800px-Pig_%28unsplash%29.jpg",
          Reference: {
            Text: "The Quran explicitly mentions pork as forbidden.",
            Verses: [
              { Verse: "2:173", Translation: "Forbidden to you (for food) are: dead meat, blood, the flesh of swine..." },
              { Verse: "5:3", Translation: "Prohibited to you (for food) are: dead meat, blood, the flesh of swine..." },
              { Verse: "6:145", Translation: "Say: 'I do not find in that which has been revealed to me anything forbidden..." },
              { Verse: "16:115", Translation: "He has only forbidden you dead meat, blood, the flesh of swine..." }
            ],
            Explanation: "Pork is considered impure and its consumption is prohibited in Islam due to its harmful nature."
          }
        },
        {
          Name: "Alcohol",
          Description: "Alcohol is forbidden in Islam due to its intoxicating effects and its potential to lead to immoral behavior.",
          Reasons: "The consumption of alcohol is prohibited in Islam as it leads to intoxication, which impairs judgment and promotes harmful behavior.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Glass_of_wine.jpg/800px-Glass_of_wine.jpg",
          Reference: {
            Text: "The Quran prohibits intoxicants due to their adverse effects on behavior and reasoning.",
            Verses: [
              { Verse: "5:90", Translation: "O you who have believed, indeed, intoxicants, gambling, [sacrificing on] stone alters..." }
            ],
            Explanation: "Alcohol impairs judgment and leads to destructive behavior, making it forbidden."
          }
        },
        {
          Name: "Dead Meat",
          Description: "Consuming meat from dead animals, or carrion, is forbidden in Islam.",
          Reasons: "In Islam, meat from animals that have not been slaughtered according to halal guidelines is considered impure and is prohibited.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/52/Fish_death.jpg/800px-Fish_death.jpg",
          Reference: {
            Text: "The Quran forbids the consumption of carrion and dead meat.",
            Verses: [
              { Verse: "5:3", Translation: "Prohibited to you (for food) are: dead meat, blood, the flesh of swine..." },
              { Verse: "6:145", Translation: "Say: 'I do not find in that which has been revealed to me anything forbidden..." }
            ],
            Explanation: "Animals that are dead or not slaughtered according to Islamic law are deemed impure."
          }
        },
        {
          Name: "Blood",
          Description: "Blood is considered haram in Islam, including blood of any animal, and is forbidden for consumption.",
          Reasons: "The consumption of blood is prohibited because it is considered impure, and the Quran forbids its consumption.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Animal_blood.jpg/800px-Animal_blood.jpg",
          Reference: {
            Text: "The Quran prohibits consuming blood, stating that it is impure.",
            Verses: [
              { Verse: "2:173", Translation: "Forbidden to you (for food) are: dead meat, blood, the flesh of swine..." },
              { Verse: "5:3", Translation: "Prohibited to you (for food) are: dead meat, blood, the flesh of swine..." },
              { Verse: "6:145", Translation: "Say: 'I do not find in that which has been revealed to me anything forbidden..." }
            ],
            Explanation: "Blood is considered impure and is forbidden for consumption in Islam."
          }
        },
        {
          Name: "Intoxicants",
          Description: "Any intoxicating substances, including drugs, are haram in Islam due to their harmful effects on the mind and body.",
          Reasons: "Substances that impair one's judgment, cause harm, or alter the state of mind are prohibited in Islam.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Cocaine_cocaine.jpg/800px-Cocaine_cocaine.jpg",
          Reference: {
            Text: "The Quran refers to intoxicants as abominations of Satan's handiwork.",
            Verses: [
              { Verse: "5:90", Translation: "O you who have believed, indeed, intoxicants, gambling, [sacrificing on] stone alters..." }
            ],
            Explanation: "Any substance that leads to intoxication is considered haram due to its potential for harm."
          }
        },
        {
          Name: "Carnivorous Animals",
          Description: "Carnivorous animals (such as lions, tigers, and wolves) are considered haram to consume.",
          Reasons: "These animals are haram because they are predatory and the Quran and Hadith indicate that consuming carnivorous animals is forbidden.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Tiger_in_Sundarbans.jpg/800px-Tiger_in_Sundarbans.jpg",
          Reference: {
            Text: "Hadith prohibiting the consumption of predatory animals.",
            Hadith: "Sahih Muslim 3233",
            Explanation: "Carnivorous animals are forbidden because they are considered impure and unsuitable for consumption."
          }
        },
        {
          Name: "Reptiles",
          Description: "Reptiles, including snakes, lizards, and frogs, are considered haram in Islam.",
          Reasons: "Consuming reptiles is prohibited in Islam based on the teachings of the Prophet Muhammad (PBUH), as they are seen as impure creatures.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Green_Lizard_%28Lacerta_Viridis%29.jpg/800px-Green_Lizard_%28Lacerta_Viridis%29.jpg",
          Reference: {
            Text: "Hadith prohibiting the consumption of reptiles.",
            Hadith: "Sahih Muslim 3242",
            Explanation: "Reptiles are considered impure, and their consumption is prohibited by the teachings of Prophet Muhammad (PBUH)."
          }
        }
      ];
      setFoods(foodsData);
    };

    fetchFoods();
  }, []);

  const handleFoodClick = (food) => {
    setSelectedFood(food);
  };

  const closeFoodPopup = () => {
    setSelectedFood(null);
  };

  return (
    <div className="foods-container">
      <h2>Haram Foods in Islam</h2>
      <div className="food-list">
        {foods.map((food, index) => (
          <div
            key={index}
            className="food-item"
            onClick={() => handleFoodClick(food)}
          >
            {/* <img
              src={food.Image}
              className="food-image"
              alt={food.Name}
            /> */}
            <h3>{food.Name}</h3>
            <p>{food.Description}</p>
          </div>
        ))}
      </div>

      {selectedFood && (
        <div className="popup">
          <div className="popup-content">
            <h3>{selectedFood.Name}</h3>
            <p><strong>Description:</strong> {selectedFood.Description}</p>
            <p><strong>Reasons for Prohibition:</strong> {selectedFood.Reasons}</p>
           
            {selectedFood.Reference.Verses && (
              <div>
                <strong>Verses:</strong>
                <ul>
                  {selectedFood.Reference.Verses.map((verse, index) => (
                    <li key={index}>
                      <strong>{verse.Verse}:</strong> {verse.Translation}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {selectedFood.Reference.Hadith && (
              <div>
                <strong>Hadith:</strong> {selectedFood.Reference.Hadith}
              </div>
            )}
            <p><strong>Explanation:</strong> {selectedFood.Reference.Explanation}</p>
            <button className="close-button" onClick={closeFoodPopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HaramFoods;
