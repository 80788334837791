import React, { useState, useEffect } from 'react';
import './HaramRelations.css';

const HaramRelations = () => {
  const [relations, setRelations] = useState([]);
  const [selectedRelation, setSelectedRelation] = useState(null);

  useEffect(() => {
    const fetchRelations = () => {
      const relationsData = [
        {
          Name: "Adultery and Fornication (Zina)",
          Description: "Any sexual relationship outside of marriage is forbidden in Islam.",
          Reasons: "Zina undermines the sanctity of marriage and moral values in society.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Crescent_with_star.svg/800px-Crescent_with_star.svg.png",
          Reference: {
            Text: "The Quran explicitly forbids adultery and fornication.",
            Verses: [
              { Verse: "17:32", Translation: "And do not approach unlawful sexual intercourse. Indeed, it is ever an immorality and is evil as a way." },
              { Verse: "24:2", Translation: "The woman and the man guilty of adultery or fornication — flog each of them with a hundred stripes." }
            ],
            Hadith: "The Prophet (PBUH) said: 'When a man commits adultery, faith leaves him...' (Sahih Bukhari, 2475)"
          }
        },
        {
          Name: "Incestuous Relationships",
          Description: "Relationships with close family members such as parents, siblings, or children are forbidden.",
          Reasons: "These relationships harm family structure and are unnatural.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Islamic_calligraphy.svg/800px-Islamic_calligraphy.svg.png",
          Reference: {
            Text: "The Quran explicitly forbids marriage with close relatives.",
            Verses: [
              { Verse: "4:23", Translation: "Prohibited to you (for marriage) are: your mothers, your daughters, your sisters, your paternal aunts, your maternal aunts, your brother's daughters, your sister's daughters..." }
            ]
          }
        },
        {
          Name: "Homosexual Relations",
          Description: "Same-sex relationships are considered haram in Islam.",
          Reasons: "Islam teaches that sexual relations should occur only within marriage between a man and a woman.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Islamic_star.svg/800px-Islamic_star.svg.png",
          Reference: {
            Text: "The Quran condemns the actions of the people of Lut (Lot).",
            Verses: [
              { Verse: "7:80-81", Translation: "Indeed, you approach men with desire, instead of women. Rather, you are a transgressing people." },
              { Verse: "26:165-166", Translation: "Do you approach males among the worlds and leave what your Lord has created for you as mates? But you are a transgressing people." }
            ]
          }
        },
        {
          Name: "Polytheistic Marriages",
          Description: "Marriage to someone who worships other than Allah is forbidden unless they are from the People of the Book (Jews or Christians).",
          Reasons: "Marriage requires spiritual harmony, which may not exist with differing beliefs.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Calligraphy_Islamic_Art.png/800px-Calligraphy_Islamic_Art.png",
          Reference: {
            Text: "The Quran warns against marrying polytheists.",
            Verses: [
              { Verse: "2:221", Translation: "And do not marry polytheistic women until they believe. And a believing slave woman is better than a polytheist, even though she might please you." }
            ]
          }
        },
        {
          Name: "Forced Marriages",
          Description: "Islam forbids forcing someone into a marriage against their will.",
          Reasons: "Marriage must be based on mutual consent and respect.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Islamic_pattern.svg/800px-Islamic_pattern.svg.png",
          Reference: {
            Text: "Marriage requires the free consent of both parties.",
            Hadith: "The Prophet (PBUH) said: 'A previously married woman has more right concerning herself than her guardian, and a virgin’s consent must be asked.' (Sunan Ibn Majah, 1871)"
          }
        },
        {
          Name: "Temporary Marriages (Mut'ah)",
          Description: "Temporary marriages, known as Mut'ah, are prohibited in Sunni Islam.",
          Reasons: "Such relationships lack the permanency and responsibilities of a true marriage.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Islamic_Art_Dome.svg/800px-Islamic_Art_Dome.svg.png",
          Reference: {
            Text: "Temporary marriages are viewed as exploitative.",
            Hadith: "The Prophet (PBUH) said: 'O people, I had permitted you to contract temporary marriage with women, but Allah has forbidden it till the Day of Resurrection.' (Sahih Muslim, 1406a)"
          }
        },
        {
          Name: "Prostitution",
          Description: "Engaging in or promoting prostitution is strictly forbidden in Islam.",
          Reasons: "It undermines moral values and exploits individuals.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Islamic_Patterns.png/800px-Islamic_Patterns.png",
          Reference: {
            Text: "The Quran forbids forcing women into prostitution.",
            Verses: [
              { Verse: "24:33", Translation: "But do not compel your slave girls to prostitution if they desire chastity, to seek the temporary interests of worldly life." }
            ]
          }
        },
        {
          Name: "Marrying Someone in Iddah (Waiting Period)",
          Description: "It is forbidden to marry a woman who is in her waiting period after divorce or the death of her husband.",
          Reasons: "The waiting period ensures clarity regarding lineage and respect for the sanctity of the previous marriage.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Islamic_Star_Pattern.svg/800px-Islamic_Star_Pattern.svg.png",
          Reference: {
            Text: "The Quran mandates observing the waiting period.",
            Verses: [
              {
                Verse: "2:228",
                Translation: "Divorced women shall wait concerning themselves for three monthly periods..."
              },
              {
                Verse: "65:4",
                Translation: "And for those who no longer expect menstruation among your women, if you doubt, then their period is three months, and for those who have not menstruated. And for those who are pregnant, their term is until they give birth."
              }
            ]
          }
        },
        {
          Name: "Bestiality",
          Description: "Any sexual act with animals is strictly forbidden.",
          Reasons: "Such acts are considered unnatural and immoral, violating human dignity and purity.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Islamic_Geometric_Pattern.svg/800px-Islamic_Geometric_Pattern.svg.png",
          Reference: {
            Text: "Islamic teachings emphasize the purity of human relations.",
            "Hadith": "The Prophet (PBUH) said: 'Cursed is the one who has intercourse with an animal.' (Sunan Abu Dawood, 4464)"
          }
        },
        {
          Name: "Adoption of Children for Marriage (Tabanni)",
          Description: "Marrying an adopted child as if they were not part of the family lineage is prohibited.",
          Reasons: "Adoption in Islam doesn't change the biological lineage, and it ensures proper familial boundaries.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c0/Islamic_Tile_Pattern.svg/800px-Islamic_Tile_Pattern.svg.png",
          Reference: {
            Text: "The Quran emphasizes the preservation of lineage.",
            Verses: [
              {
                Verse: "33:4-5",
                Translation: "Nor has He made your adopted sons your [true] sons. That is [merely] your saying by your mouths, but Allah says the truth, and He guides to the [right] way. Call them by [the names of] their fathers; it is more just in the sight of Allah."
              }
            ]
          }
        },
        {
          Name: "Incestuous Marriages with Foster Relatives (Rada'a)",
          Description: "Marriage to foster relatives (those who nursed from the same woman) is forbidden.",
          Reasons: "Foster relationships are considered equivalent to blood relations in matters of marriage.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Islamic_Star_Mosaic.svg/800px-Islamic_Star_Mosaic.svg.png",
          Reference: {
            Text: "The Quran prohibits such marriages.",
            Verses: [
              {
                Verse: "4:23",
                Translation: "Prohibited to you [for marriage] are your mothers who breastfed you, your sisters through breastfeeding..."
              }
            ]
          }
        },
        {
          Name: "Child Marriage without Maturity",
          Description: "Though Islam permits marriage at a young age, consummation is only allowed when both parties are physically and mentally mature.",
          Reasons: "Marriage requires the ability to fulfill its responsibilities and mutual consent.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Islamic_Floral_Pattern.svg/800px-Islamic_Floral_Pattern.svg.png",
          Reference: {
            Text: "Islam stresses maturity in marital relationships.",
            Verses: [
              {
                Verse: "4:6",
                Translation: "And test the orphans [in their abilities] until they reach marriageable age. Then if you perceive in them sound judgment, release their property to them."
              }
            ],
            "Hadith": "The Prophet (PBUH) delayed the consummation of his marriage to Aisha until she was of age and capable of managing marital responsibilities."
          }
        },
        {
          Name: "Marrying a Non-Muslim Man",
          Description: "Muslim women are not permitted to marry non-Muslim men unless they convert to Islam.",
          Reasons: "Islam requires spiritual leadership in the household, which is believed to be incompatible with differing beliefs.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2e/Islamic_Art_Pattern.svg/800px-Islamic_Art_Pattern.svg.png",
          Reference: {
            Text: "The Quran sets guidelines on interfaith marriages.",
            Verses: [
              {
                Verse: "2:221",
                Translation: "And do not give [your daughters] in marriage to polytheistic men until they believe."
              }
            ]
          }
        },
        {
          Name: "Engagements without Intention of Marriage",
          Description: "Engagements or prolonged relationships without serious intent for marriage are discouraged.",
          Reasons: "Such engagements can lead to emotional harm and compromise the sanctity of relationships.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Islamic_Pattern_Tiles.svg/800px-Islamic_Pattern_Tiles.svg.png",
          Reference: {
            Text: "Islam emphasizes intention and commitment in relationships.",
            Verses: [
              {
                Verse: "2:235",
                Translation: "There is no sin upon you for that to which you indirectly allude concerning a proposal to women or for what you conceal within yourselves. Allah knows that you will have them in mind."
              }
            ]
          }
        },
        {
          Name: "Cohabitation without Marriage",
          Description: "Living together as a couple without being married is strictly prohibited.",
          Reasons: "Cohabitation outside of marriage undermines the institution of marriage and societal values.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Islamic_Geometric_Art.svg/800px-Islamic_Geometric_Art.svg.png",
          Reference: {
            Text: "Islam requires formal marriage to validate cohabitation.",
            Verses: [
              {
                Verse: "24:32",
                Translation: "And marry the unmarried among you and the righteous among your male slaves and female slaves."
              }
            ]
          }
        },
        {
          Name: "Secret Marriages",
          Description: "Marriage without public knowledge or without witnesses is not valid in Islam.",
          Reasons: "Transparency in marriage protects the rights of both parties and prevents social complications.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Islamic_Tessellation.svg/800px-Islamic_Tessellation.svg.png",
          Reference: {
            Text: "Islam mandates the announcement of marriage.",
            "Hadith": "The Prophet (PBUH) said: 'Announce the marriage and conduct it in the mosque.' (Sunan Tirmidhi, 1089)"
          }
        },
        {
          Name: "Marrying a Person Already Married (Without Consent)",
          Description: "A man may marry multiple wives in Islam, but this requires consent and the ability to treat them fairly.",
          Reasons: "Justice among spouses is a key condition for polygamy.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Islamic_Art_Motif.svg/800px-Islamic_Art_Motif.svg.png",
          Reference: {
            Text: "The Quran permits polygamy under strict conditions.",
            Verses: [
              {
                Verse: "4:129",
                Translation: "You will never be able to be equal [in feeling] between wives, even if you should strive to do so. So do not incline completely [toward one] and leave another hanging."
              }
            ]
          }
        },
        {
          Name: "Marriage with Intention of Immediate Divorce",
          Description: "Entering a marriage with the intention of divorcing after a short time is considered deceitful and impermissible.",
          Reasons: "Marriage in Islam is meant to be a lifelong commitment.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8c/Islamic_Decoration.svg/800px-Islamic_Decoration.svg.png",
          Reference: {
            Text: "Islam forbids deceitful practices in marriage.",
            "Hadith": "The Prophet (PBUH) said: 'The most hated permissible act to Allah is divorce.' (Sunan Abu Dawood, 2178)"
          }
        },
        {
          Name: "Marrying a Prostitute without Repentance",
          Description: "Marrying someone actively engaged in prostitution without their repentance is discouraged.",
          Reasons: "Islam emphasizes moral reformation before marriage.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Islamic_Pattern_Floral.svg/800px-Islamic_Pattern_Floral.svg.png",
          Reference: {
            Text: "Repentance is a prerequisite for such marriages.",
            Verses: [
              {
                Verse: "24:3",
                Translation: "The fornicator does not marry except a [female] fornicator or polytheist, and none marries her except a fornicator or a polytheist."
              }
            ]
          }
        },
        {
          Name: "Marrying a Woman During Her Iddah (Waiting Period) from Divorce",
          Description: "It is forbidden to marry a woman who is in her waiting period following a divorce.",
          Reasons: "The waiting period ensures clarity of lineage and respects the sanctity of the previous marriage.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/14/Islamic_Art_Design.svg/800px-Islamic_Art_Design.svg.png",
          Reference: {
            Text: "The Quran emphasizes the importance of observing the waiting period.",
            Verses: [
              {
                Verse: "2:228",
                Translation: "Divorced women shall wait concerning themselves for three monthly periods."
              }
            ]
          }
        },
        {
          Name: "Marrying a Woman During Her Iddah (Waiting Period) from Widowhood",
          Description: "Marriage is prohibited during a woman's waiting period after the death of her husband.",
          Reasons: "This waiting period shows respect for the previous relationship and ensures clarity regarding potential pregnancy.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Islamic_Motif_Tile.svg/800px-Islamic_Motif_Tile.svg.png",
          Reference: {
            Text: "The Quran mandates observing the iddah for widows.",
            Verses: [
              {
                Verse: "2:234",
                Translation: "And those who are taken in death among you and leave wives behind—they [the wives] shall wait four months and ten [days]."
              }
            ]
          }
        },
        {
          Name: "Nikah al-Shighar (Exchange Marriages)",
          Description: "This type of marriage, where two families marry off individuals without dower (mahr) in exchange, is forbidden.",
          Reasons: "It exploits individuals and deprives them of their rightful dowry.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Islamic_Art_Pattern_Tile.svg/800px-Islamic_Art_Pattern_Tile.svg.png",
          Reference: {
            Text: "Islam prohibits marriages without mahr.",
            "Hadith": "The Prophet (PBUH) said: 'There is no Shighar in Islam.' (Sahih Bukhari, 5112)"
          }
        },
        {
          Name: "Mutual Divorce Agreements with Pre-Conditions",
          Description: "Agreeing to divorce for material or other conditions invalidates the marriage.",
          Reasons: "Marriage must be based on sincerity, not conditional or material benefits.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Islamic_Geometric_Design.svg/800px-Islamic_Geometric_Design.svg.png",
          Reference: {
            Text: "Islam forbids conditional agreements in marriage.",
            "Hadith": "The Prophet (PBUH) said: 'The most hateful permissible thing to Allah is divorce.' (Sunan Abu Dawood, 2178)"
          }
        },
        {
          Name: "Marrying Without the Guardian’s (Wali’s) Consent for Women",
          Description: "A woman marrying without the consent of her wali (guardian) is considered invalid in most Islamic jurisprudence.",
          Reasons: "The wali ensures the woman’s rights are protected.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Islamic_Pattern_Art.svg/800px-Islamic_Pattern_Art.svg.png",
          Reference: {
            Text: "A wali is essential for the validity of marriage.",
            "Hadith": "The Prophet (PBUH) said: 'There is no marriage except with a guardian.' (Sunan Abu Dawood, 2085)"
          }
        },
        {
          Name: "Temporary Abstinence from Marriage for Religious Devotion",
          Description: "Committing to celibacy or temporary abstinence for religious reasons is discouraged.",
          Reasons: "Islam encourages marriage as a means of fulfilling natural desires and protecting oneself from sin.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Islamic_Tiles_Design.svg/800px-Islamic_Tiles_Design.svg.png",
          Reference: {
            Text: "Marriage is highly encouraged in Islam.",
            "Hadith": "The Prophet (PBUH) said: 'Marriage is my tradition. Whoever does not follow my tradition is not from me.' (Sahih Bukhari, 5063)"
          }
        },
        {
          Name: "Marrying for Wealth or Social Status Alone",
          Description: "Islam discourages marriage for the sole purpose of gaining wealth or social status.",
          Reasons: "Marriage should be based on faith, character, and mutual respect.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Islamic_Floral_Tile.svg/800px-Islamic_Floral_Tile.svg.png",
          Reference: {
            Text: "The Prophet emphasized the importance of choosing a spouse for their faith.",
            "Hadith": "The Prophet (PBUH) said: 'A woman is married for four reasons: her wealth, her family status, her beauty, and her religion. So marry the one who is religious, and you will prosper.' (Sahih Bukhari, 5090)"
          }
        },
        {
          Name: "Marrying to Harm or Take Revenge",
          Description: "Marriage with the intent of harming the spouse or their family is prohibited.",
          Reasons: "Marriage in Islam is based on compassion and mutual benefit.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/71/Islamic_Art_Tile_Pattern.svg/800px-Islamic_Art_Tile_Pattern.svg.png",
          Reference: {
            Text: "Islam forbids intentions of harm in any form.",
            "Hadith": "The Prophet (PBUH) said: 'Actions are judged by intentions.' (Sahih Bukhari, 1)"
          }
        },
        {
          Name: "Marrying Someone with Known Deceitful Intentions",
          Description: "Marrying someone who hides significant information, such as a prior marriage or health condition, is deceitful and discouraged.",
          Reasons: "Marriage should be based on honesty and transparency.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Islamic_Tile_Geometric.svg/800px-Islamic_Tile_Geometric.svg.png",
          Reference: {
            Text: "Honesty is crucial in marriage.",
            "Hadith": "The Prophet (PBUH) said: 'He who deceives is not of us.' (Sahih Muslim, 102)"
          }
        },
        {
          Name: "Marrying During a Time of Ihram (Sacred State in Hajj/Umrah)",
          Description: "It is forbidden to marry or arrange a marriage while in the state of Ihram during Hajj or Umrah.",
          Reasons: "Ihram is a time for spiritual devotion, free from worldly distractions.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Islamic_Pattern_Designs.svg/800px-Islamic_Pattern_Designs.svg.png",
          Reference: {
            Text: "The Prophet prohibited marriage in Ihram.",
            "Hadith": "The Prophet (PBUH) said: 'The Muhrim (person in Ihram) should not marry, nor arrange the marriage of others.' (Sahih Muslim, 3432)"
          }
        },
        {
          Name: "Marrying Someone Who Publicly Rejects Islamic Obligations",
          Description: "Marriage to someone who openly denies fundamental Islamic practices, such as prayer or fasting, is discouraged.",
          Reasons: "Such individuals may influence the household's spiritual environment negatively.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Islamic_Pattern_Floral_2.svg/800px-Islamic_Pattern_Floral_2.svg.png",
          Reference: {
            Text: "Islam emphasizes the importance of a shared commitment to religious obligations.",
            Verses: [
              {
                Verse: "5:2",
                Translation: "Help one another in righteousness and piety, but do not help one another in sin and aggression."
              }
            ]
          }
        },
        {
          Name: "Marriage with the Intention of Exposing Spouse's Secrets",
          Description: "Using marriage as a means to uncover and spread personal or family secrets is forbidden.",
          Reasons: "This violates the trust and sanctity of marriage.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a1/Islamic_Geometric_Design_2.svg/800px-Islamic_Geometric_Design_2.svg.png",
          Reference: {
            Text: "Islam condemns deceit and betrayal.",
            Hadith: "The Prophet (PBUH) said: 'The most severe of people in betrayal on the Day of Judgment is the one who discloses his wife's secrets.' (Sahih Muslim, 1437a)"
          }
        },
        {
          Name: "Marrying Solely for Physical Attraction",
          Description: "While physical attraction is important, marrying solely based on it without considering character and piety is discouraged.",
          Reasons: "A successful marriage requires compatibility in values and beliefs.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Islamic_Floral_Pattern_2.svg/800px-Islamic_Floral_Pattern_2.svg.png",
          Reference: {
            Text: "The Prophet emphasized the importance of faith and character.",
            Hadith: "The Prophet (PBUH) said: 'A woman may be married for four things: wealth, status, beauty, and religion. Marry the religious one.' (Sahih Bukhari, 5090)"
          }
        },
        {
          Name: "Marrying Without Sufficient Financial Means",
          Description: "Marriage is discouraged if one lacks the financial ability to fulfill marital obligations.",
          Reasons: "Financial stability ensures the ability to support a family and meet responsibilities.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Islamic_Geometric_Pattern_2.svg/800px-Islamic_Geometric_Pattern_2.svg.png",
          Reference: {
            Text: "Islam encourages financial preparedness for marriage.",
            Verses: [
              {
                Verse: "24:33",
                Translation: "And let those who find not the means to marry keep themselves chaste until Allah enriches them of His bounty."
              }
            ]
          }
        },
        {
          Name: "Marrying Without Prior Consultation or Istikhara (Seeking Guidance)",
          Description: "Making marital decisions without seeking guidance from Allah or consulting family and trusted advisors is discouraged.",
          Reasons: "Islam encourages thoughtful and prayerful consideration in major life decisions.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Islamic_Art_Pattern_3.svg/800px-Islamic_Art_Pattern_3.svg.png",
          Reference: {
            Text: "The Prophet recommended seeking divine guidance in all matters.",
            Hadith: "The Prophet (PBUH) said: 'If any of you is concerned about a decision, he should pray two rak'ahs of voluntary prayer and say the Istikhara supplication.' (Sahih Bukhari, 6382)"
          }
        },
        {
          Name: "Marrying Someone Who is Addicted to Harmful Substances",
          Description: "Islam discourages marrying someone actively addicted to intoxicants or other harmful substances.",
          Reasons: "Addictions can harm the individual, family, and household environment.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Islamic_Tile_Floral_Pattern.svg/800px-Islamic_Tile_Floral_Pattern.svg.png",
          Reference: {
            Text: "The Quran condemns intoxicants.",
            Verses: [
              {
                Verse: "5:90",
                Translation: "O you who have believed, indeed, intoxicants, gambling, [sacrificing on] stone alters [to other than Allah], and divining arrows are but defilement from the work of Satan, so avoid it that you may be successful."
              }
            ]
          }
        },
        {
          Name: "Marrying Someone Who Disrespects Parents or Elderly",
          Description: "Islam discourages marriage to those who openly display disrespect toward their parents or elders.",
          Reasons: "Respect for family is a foundational Islamic value.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Islamic_Floral_Design.svg/800px-Islamic_Floral_Design.svg.png",
          Reference: {
            Text: "Respecting parents is a critical component of faith.",
            Verses: [
              {
                Verse: "17:23",
                Translation: "And We have enjoined upon man [care] for his parents. His mother carried him, [increasing her] in weakness upon weakness, and his weaning is in two years. Be grateful to Me and to your parents; to Me is the [final] destination."
              }
            ]
          }
        },
        {
          Name: "Marrying to Escape Personal Responsibilities",
          Description: "Marriage should not be used as an escape from personal responsibilities or as a quick fix for problems.",
          Reasons: "Marriage brings additional responsibilities that require maturity and readiness.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Islamic_Pattern_Art_2.svg/800px-Islamic_Pattern_Art_2.svg.png",
          Reference: {
            Text: "Islam emphasizes readiness and responsibility in marriage.",
            Hadith: "The Prophet (PBUH) said: 'O young people! Whoever among you can afford to get married, let him do so.' (Sahih Bukhari, 5065)"
          }
        },
        {
          Name: "Marrying Someone with a History of Abuse without Evidence of Reform",
          Description: "Marriage to someone with a known history of abusive behavior is discouraged unless they have demonstrated genuine repentance and change.",
          Reasons: "Islam prioritizes the safety and well-being of individuals in a marriage.",
          Image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/Islamic_Tile_Design.svg/800px-Islamic_Tile_Design.svg.png",
          Reference: {
            Text: "Islam encourages kindness and mercy in all relationships.",
            Verses: [
              {
                Verse: "30:21",
                Translation: "And of His signs is that He created for you from yourselves mates that you may find tranquility in them; and He placed between you affection and mercy."
              }
            ]
          }
        }
      ];
      setRelations(relationsData);
    };

    fetchRelations();
  }, []);

  const handleRelationClick = (relation) => {
    setSelectedRelation(relation);
  };

  const closeRelationPopup = () => {
    setSelectedRelation(null);
  };

  return (
    <div className="relations-container">
      <h2>Haram Relationships in Islam</h2>
      <div className="relation-list">
        {relations.map((relation, index) => (
          <div
            key={index}
            className="relation-item"
            onClick={() => handleRelationClick(relation)}
          >
            {/* <img
              src={relation.Image}
              className="relation-image"
              alt={relation.Name}
            /> */}
            <h3>{relation.Name}</h3>
            <p>{relation.Description}</p>
          </div>
        ))}
      </div>

      {selectedRelation && (
        <div className="popup">
          <div className="popup-content">
            <h3>{selectedRelation.Name}</h3>
            <p><strong>Description:</strong> {selectedRelation.Description}</p>
            <p><strong>Reasons for Prohibition:</strong> {selectedRelation.Reasons}</p>
            <p><strong>Reference:</strong></p>
            {selectedRelation.Reference.Verses && (
              <div>
                <strong>Verses:</strong>
                <ul>
                  {selectedRelation.Reference.Verses.map((verse, index) => (
                    <li key={index}>
                      <strong>{verse.Verse}:</strong> {verse.Translation}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {selectedRelation.Reference.Hadith && (
              <div>
                <strong>Hadith:</strong> {selectedRelation.Reference.Hadith}
              </div>
            )}
            <p><strong>Explanation:</strong> {selectedRelation.Reference.Text}</p>
            <button className="close-button" onClick={closeRelationPopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HaramRelations;
