// import React from 'react';
import React, { useState } from 'react';
import './IslamicBooks.css';

const IslamicBooks = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const books = [
    {
      title: 'Holy Quran',
      description: 'Holy Quran with beautiful Arabic text and large font size.',
      link: 'https://ia803408.us.archive.org/20/items/holy-quran-beautiful-arabic-text/holy-quran-beautiful-arabic-text.pdf',
      buyLink: 'https://amzn.to/3ZmbItM', // Example buy link
      cover: 'https://tinyurl.com/quranarchivebook',
    },
    {
      title: 'The Sealed Nectar',
      description: 'A biography of Prophet Muhammad (PBUH) by Safi-ur-Rahman al-Mubarakpuri.',
      link: 'https://ia801906.us.archive.org/31/items/TheSealedNectar-Alhamdulillah-library.blogspot.in.pdf/TheSealedNectar-Alhamdulillah-library.blogspot.in.pdf',
      buyLink: 'https://amzn.to/4g01PIh', // Example buy link
      cover: 'https://m.media-amazon.com/images/I/91YHrcQT+3L._SL1500_.jpg',
    },
    {
      title: 'Fortress of the Muslim',
      description: 'A collection of daily supplications (Duas) from the Quran and Sunnah.',
      link: 'https://ia801709.us.archive.org/26/items/FortressOfTheMuslim_201711/fortress_of_the_muslim_pdf%282%29.pdf',
      buyLink: 'https://amzn.to/4iaWFur', // Example buy link
      cover: 'https://m.media-amazon.com/images/I/51IF4+n1qiL._SX342_SY445_.jpg',
    },
    {
      title: 'Stories of the Prophets',
      description: 'A detailed account of the lives of Prophets by Ibn Kathir.',
      link: 'https://ia601709.us.archive.org/30/items/stories-of-the-prophets-by-ibn-kathir-english-pdf/stories-of-the%20prophets-by-ibn-kathir-english-pdf.pdf',
      buyLink: 'https://amzn.to/4i8yQDl', // Example buy link
      cover: 'https://m.media-amazon.com/images/I/51M7zn4iqoL.jpg',
    },
    {
      title: "In the Shade of the Qur'an",
      description: 'A detailed commentary on the Quran by Sayyid Qutb, offering insights into its meanings and themes.',
      link: 'https://ia803207.us.archive.org/33/items/QuranTafsirInTheShadeOfTheQuranSayyidQutbEngAllVolIn1Pdf/Quran%20Tafsir-In%20the%20Shade%20of%20the%20Quran-Sayyid%20Qutb-Eng-All%20Vol%20in%201%20pdf.pdf',
      buyLink: 'https://amzn.to/4fISPXP', // Example buy link
      cover: 'https://m.media-amazon.com/images/I/71nzb5SFZjL._SL1500_.jpg',
    },
    {
      title: "Riyad-us-Saliheen Jild 1",
      description: "A collection of Hadith compiled by Imam Nawawi, covering various aspects of Muslim life.",
      link: "https://ia902808.us.archive.org/23/items/riaz-us-saliheen-jild-1/riaz-us-saliheen-jild-1.pdf",
      buyLink: 'https://amzn.to/417ip4k', // Example buy link
      cover: "https://tinyurl.com/riyadulsalihinjild1",
    },
    {
      title: "Riyad-us-Saliheen Jild 2",
      description: "A collection of Hadith compiled by Imam Nawawi, covering various aspects of Muslim life.",
      link: "https://ia902808.us.archive.org/23/items/riaz-us-saliheen-jild-1/riaz-us-saliheen-jild-2.pdf",
      buyLink: 'https://amzn.to/417ip4k', // Example buy link
      cover: "https://tinyurl.com/riyadulsalihinjild2",
    },
    {
      title: "40 Hadith by Imam Nawawi",
      description: "A compilation of 40 Hadith focusing on fundamental principles of Islam.",
      link: "https://ia801307.us.archive.org/17/items/40HadithOfImamNawawi/40%20Hadith%20of%20Imam%20Nawawi.pdf",
      buyLink: 'https://amzn.to/3Oo4QG2', // Example buy link
      cover: "https://tinyurl.com/40hadithimamnawawi",
    },
    {
      title: "A Brief Illustrated Guide to Understanding Islam",
      description: "An introduction to Islam with illustrations and Quranic references.",
      link: "https://ia801800.us.archive.org/17/items/islam-guide-en/A%20Brief%20Illustrated%20Guide%20To%20Understanding%20Islam.pdf",
      buyLink: 'https://amzn.to/4g4KDRs', // Example buy link
      cover: "https://tinyurl.com/bgtuislam",
    },
    {
      title: "Fiqh-us-Sunnah",
      description: "A comprehensive book on Islamic jurisprudence by Sayyid Sabiq.",
      link: "https://ia802909.us.archive.org/28/items/fiqh-us-sunnah-five-volumes/fiqh-us-sunnah-five-volumes.pdf",
      buyLink: 'https://amzn.to/4i7mT0z', // Example buy link
      cover: "https://tinyurl.com/fiqhussunnah",
    },
    {
      title: "The History of the Khalifahs",
      description: "A historical account of the Caliphs who followed Prophet Muhammad (PBUH).",
      link: "https://ia601309.us.archive.org/26/items/TheHistoryOfTheKhalifahs/The%20history%20of%20the%20Khalifahs.pdf",
      buyLink: 'https://amzn.to/499DSeM', // Example buy link
      cover: "https://tinyurl.com/thehistoryofkhalifahs",
    },
    {
        title: "Healing with the Medicine of the Prophet",
        description: "A book on natural remedies mentioned by Prophet Muhammad (PBUH), by Ibn Qayyim al-Jawziyya.",
        link: "https://ia902305.us.archive.org/4/items/healing-with-the-medicine-of-the-prophet-by-ibn-al-qayyim/Healing-with-the-Medicine-of-the-Prophet-Ibn-al-Qayyim.pdf",
        buyLink: "https://amzn.to/3Z4UC2d", // Example buy link
        cover: "https://tinyurl.com/healingwithprophet",
      },
      {
        title: "Purification of the Heart",
        description: "A guide to addressing spiritual ailments, by Hamza Yusuf.",
        link: "https://ia802804.us.archive.org/19/items/learnislampdfenglishbookpurificationoftheheartbyhamzayusufcomplete/learn%20islam%20pdf%20english%20book%20__%20PurificationOfTheHeartByHamzaYusufcomplete.pdf",
        buyLink: "https://amzn.to/4g6iWaJ", // Example buy link
        cover: "https://tinyurl.com/purificationofheart",
      },
      {
        title: "Al-Adab Al-Mufrad",
        description: "A book of manners and ethics in Islam by Imam Bukhari.",
        link: "https://ia802308.us.archive.org/21/items/AlAdabAlMufrad_201712/Al-Adab%20al-Mufrad.pdf",
        buyLink: "https://amzn.to/3VauJg5", // Example buy link
        cover: "https://tinyurl.com/aladabalmufradbook",
      },
      {
        title: "Women Around the Messenger",
        description: "A detailed account of the prominent women in the life of Prophet Muhammad (PBUH).",
        link: "https://ia902806.us.archive.org/1/items/women-around-the-messenger/Women%20Around%20the%20Messenger-pbuh.pdf",
        buyLink: "https://amzn.to/4eSGkHI", // Example buy link
        cover: "https://tinyurl.com/womenaroundprophet",
      },
      {
        title: "The Fundamentals of Tawheed",
        description: "An explanation of Islamic monotheism by Dr. Abu Ameenah Bilal Philips.",
        link: "https://ia800902.us.archive.org/14/items/FP1151/1151.pdf",
        buyLink: "https://amzn.to/3CJXWs2", // Example buy link
        cover: "https://m.media-amazon.com/images/I/61izuPemxtL._SL1000_.jpg",
      },
      {
        title: "My First Quran Storybook",
        description: "An engaging visual guide for children to learn Quranic stories.",
        link: "https://ia801303.us.archive.org/5/items/my-first-quran-storybook/My%20First%20Quran%20Storybook.pdf",
        buyLink: "https://amzn.to/3OrARNu", // Example buy link
        cover: "https://tinyurl.com/storybookquran",
      },
      {
        title: "Islamic Studies Grade 1",
        description: "A foundational Islamic studies curriculum for young learners.",
        link: "https://ia902900.us.archive.org/33/items/islamic-studies_202004/IS_01.pdf",
        buyLink: "https://www.eislamworld.com/product/islamic-studies-grade-1/", // Example buy link
        cover: "https://i0.wp.com/www.darussalam.in/wp-content/uploads/2020/02/Islamic-Studies-Grade-1-HC.png?fit=1000%2C1360&ssl=1",
      },
      {
        title: "The Clear Quran",
        description: "A simplified and clear translation of the Quran by Dr. Mustafa Khattab.",
        link: "https://ia804705.us.archive.org/5/items/the-clear-quran-a-thematic-english-translation-allah-edition-dr.-mustafa-khattab-2017-bc-2-c-0-ddb/The_Clear_Quran_A_Thematic_English_Translation_Allah_edition_--_Dr._Mustafa_Khattab_2017_BC2C0DDB.pdf",
        buyLink: "https://amzn.to/3Opm9X8", // Example buy link
        cover: "https://tinyurl.com/clearquranmk",
      },
      {
        title: "Islamic Creed Series: Belief in Allah",
        description: "An insightful explanation of Islamic theology by Dr. Umar Sulaiman Al-Ashqar.",
        link: "https://ia802302.us.archive.org/4/items/Islamic-Creed-Series/Belief-in-Allah-In-the-Light-of-the-Qur-an-and-Sunnah.pdf",
        buyLink: "https://amzn.to/4g5EjsL", // Example buy link
        cover: "https://tinyurl.com/ISLAMCREED",
      },
      {
        title: "The Prophetic Medicine",
        description: "A book on Islamic medicine and health, written by Ibn Qayyim al-Jawziyya.",
        link: "https://ia801208.us.archive.org/2/items/ThePropheticMedicineByIbnQayyimAlJawziyya/ThePropheticMedicineByIbnQayyimAl-jawziyya.pdf",
        buyLink: "https://amzn.to/4963Hwl", // Example buy link
        cover: "https://tinyurl.com/PROPMEDICINE",
      },
      {
        title: "The Ideal Muslimah",
        description: "A comprehensive guide to the character and behavior of an ideal Muslim woman.",
        link: "https://ia601506.us.archive.org/27/items/TheIdealMuslimah-alhamdulillah-library.blogspot.in.pdf/TheIdealMuslimah-alhamdulillah-library.blogspot.in.pdf",
        buyLink: "https://amzn.to/3Va73so", // Example buy link
        cover: "https://m.media-amazon.com/images/I/61HEW8W11ML._AC_UF1000,1000_QL80_.jpg",
      },
      {
        title: "In the Footsteps of the Prophet",
        description: "A deep reflection on the life and character of Prophet Muhammad (PBUH) by Tariq Ramadan.",
        link: "https://ia601408.us.archive.org/24/items/in-the-footsteps-of-the-prophet-lessons-from-the-life-of-muhammad-tariq-ramadan/In%20the%20Footsteps%20of%20the%20Prophet%20Lessons%20from%20the%20Life%20of%20Muhammad%20%28Tariq%20Ramadan%29%20.pdf",
        buyLink: "https://amzn.to/4g9tzJM",
        cover: "https://tinyurl.com/footstepsofprophet"
      },
    //   {
    //     title: "Al-Qawā'id al-Fiqhīyah",
    //     description: "A guide to Islamic legal maxims and principles by Dr. Muhammad al-Zuhayli.",
    //     link: "https://ia801300.us.archive.org/32/items/AlQawaidAlFiqhiyyah/Al-Qawaid%20al-Fiqhiyyah.pdf",
    //     buyLink: "https://amzn.to/4hHXqF4",
    //     cover: "https://m.media-amazon.com/images/I/61H4yXqvfLL._AC_UL1000_.jpg"
    //   },
      {
        title: "The Book of Knowledge",
        description: "A classical Islamic text by Imam al-Ghazali on the importance and types of knowledge in Islam.",
        link: "https://ia801801.us.archive.org/7/items/islam-the-book-of-knowledge-by-ghazali/islam%20The%20Book%20Of%20Knowledge%20by%20Ghazali.pdf",
        buyLink: "https://amzn.to/4idqDh6",
        cover: "https://tinyurl.com/bokbig"
      },
      {
        title: "The Fiqh of Fasting",
        description: "A detailed guide on the rules and regulations of fasting during Ramadan and other occasions.",
        link: "https://ia903108.us.archive.org/6/items/kitab-al-muqoddimah-al-hadromiyah-karangan-syekh-abdullah-bin-abdurrahman-al-hadrami/Kitab%20al-Muqoddimah%20al-Hadromiyah%20-%20Karangan%20Syekh%20%27Abdullah%20bin%20%27Abdurrahman%20al-Hadrami.pdf",
        buyLink: "https://amzn.to/3ZiJAGS",
        cover: "https://tinyurl.com/fiqhfastarba"
      },
      {
        title: "The Life of the Prophet Muhammad",
        description: "A comprehensive biography of the Prophet Muhammad (PBUH) by Muhammad Husayn Haykal.",
        link: "https://ia803207.us.archive.org/7/items/muhammad-by-haykal/muhammad%20by%20haykal.pdf",
        buyLink: "https://amzn.to/416vlan",
        cover: "https://m.media-amazon.com/images/I/71CjPDE4u3L._SL1360_.jpg"
      },
      {
        title: "Islam: The Essentials",
        description: "Islam: The Essentials (Pelican Books) Ramadan, Tariq",
        link: "https://ia902300.us.archive.org/21/items/islam-the-essentials/islam-the-essentials.pdf",
        buyLink: "https://amzn.to/3Vg6nSp",
        cover: "https://m.media-amazon.com/images/I/61mt1R-Ca3L._SL1500_.jpg"
      },
      {
        title: "The Islamic Way of Life",
        description: "A detailed explanation of how Islam guides every aspect of a Muslim's life.",
        link: "https://ia802901.us.archive.org/12/items/islamic-way-of-life/Islamic%20Way%20of%20Life.pdf",
        buyLink: "https://amzn.to/3CKjrsE",
        cover: "https://tinyurl.com/iwlalmas"
      },
      {
        title: "The Heart of Islam",
        description: "An introduction to Islamic spirituality and its practices, written by Seyyed Hossein Nasr.",
        link: "https://ia902809.us.archive.org/17/items/learnislampdfenglishbooktheheartofislamenduringvaluesforhumanity/learn%20islam%20pdf%20english%20book%20__%20the_heart_of_islam__enduring_values_for_humanity.pdf",
        buyLink: "https://amzn.to/4eSQg3S",
        cover: "https://m.media-amazon.com/images/I/71zw-W0XqjL._SL1360_.jpg"
      },
      {
        title: "Understanding Islam",
        description: "An introductory guide to the core beliefs and practices of Islam.",
        link: "https://www.kalamullah.com/Books/Understanding_Islam.pdf",
        buyLink: "https://amzn.to/3Zcg1Xe",
        cover: "https://m.media-amazon.com/images/I/61LWLRNYEGL._UF1000,1000_QL80_.jpg"
      },
      // {
      //   title: "Islamic Law: A Very Short Introduction",
      //   description: "A concise overview of Islamic law (Sharia), its principles, and history by Mashood A. Baderin.",
      //   link: "https://ia802608.us.archive.org/16/items/IslamicLawAVeryShortIntroduction/Islamic%20Law%20-%20A%20Very%20Short%20Introduction.pdf",
      //   buyLink: "https://amzn.to/4hT2w5k",
      //   cover: "https://m.media-amazon.com/images/I/61RkBd4uLfL._AC_UL1000_.jpg"
      // },
      {
        title: "Al-Muwatta of Imam Malik",
        description: "A well-known compilation of Hadiths on jurisprudence and legal matters by Imam Malik.",
        link: "https://ia903201.us.archive.org/22/items/al-muwatta-of-imam-malik/Al-Muwatta%20of%20Imam%20Malik.pdf",
        buyLink: "https://amzn.to/4guO4kH",
        cover: "https://m.media-amazon.com/images/I/71e8PxNANIL._SL1500_.jpg"
      },
      // {
      //   title: "The Islamic Concept of God",
      //   description: "An introduction to the understanding of Tawhid and the oneness of Allah in Islam.",
      //   link: "https://ia801704.us.archive.org/9/items/IslamicConceptOfGod/The%20Islamic%20Concept%20of%20God.pdf",
      //   buyLink: "https://amzn.to/4iHsiO7",
      //   cover: "https://m.media-amazon.com/images/I/71b-KDeCm2L._AC_UL1000_.jpg"
      // },
      {
        title: "Al-Ghazali on the Duties of Brotherhood",
        description: "A work by Imam Al-Ghazali on the importance and responsibilities of brotherhood in Islam.",
        link: "https://vulms.vu.edu.pk/Courses/ISL201/Downloads/Duties%20of%20Brotherhood%20-%20Al%20Ghazali.pdf",
        buyLink: "https://amzn.to/4fQ6so5",
        cover: "https://m.media-amazon.com/images/I/91gMm7MBk0L._SL1500_.jpg"
      },
      {
        title: "The Principles of Islamic Jurisprudence",
        description: "A comprehensive guide to understanding the basics of Islamic law by Mohammad Hashim Kamali.",
        link: "https://ia803408.us.archive.org/31/items/principles-of-islamic-jurisprudence/Principles%20of%20Islamic%20jurisprudence.pdf",
        buyLink: "https://amzn.to/3OC1tes",
        cover: "https://m.media-amazon.com/images/I/719Ku767KyL._SL1500_.jpg"
      },
      {
        title: "The Study Quran: A New Translation and Commentary",
        description: "A comprehensive English translation and commentary of the Quran, providing an in-depth understanding of its verses by Seyyed Hossein Nasr and a team of scholars.",
        link: "https://ia803008.us.archive.org/22/items/TheStudyQuranANewTranslationAndCommentaryBySeyyedHosseinNasr/TheStudyQuranANewTranslationAndCommentaryBySeyyedHosseinNasr.pdf",
        buyLink: "https://amzn.to/3B7ORZK",
        cover: "https://m.media-amazon.com/images/I/A1QCm4AR5gL._SL1500_.jpg"
      },
      // {
      //   title: "The Fundamentals of Islam",
      //   description: "An accessible introduction to the five pillars of Islam and the core beliefs and practices of Muslims.",
      //   link: "https://ia904609.us.archive.org/8/items/fundamentals-of-islam-part-1-part-1/FOI%20Part%201.pdf",
      //   buyLink: "https://amzn.to/4hUu0Bh",
      //   cover: "https://m.media-amazon.com/images/I/81rYpVttTeL._AC_UL1000_.jpg"
      // },
      // {
      //   title: "The Life of Imam Ali",
      //   description: "A comprehensive account of the life and legacy of Imam Ali (RA), the cousin and son-in-law of the Prophet Muhammad (PBUH).",
      //   link: "https://ia802601.us.archive.org/5/items/TheLifeOfImamAli/The%20Life%20of%20Imam%20Ali.pdf",
      //   buyLink: "https://amzn.to/4hXq4g1",
      //   cover: "https://m.media-amazon.com/images/I/91-Q4L3Vg9L._AC_UL1000_.jpg"
      // },
      // {
      //   title: "Islamic History: A Short Introduction",
      //   description: "A brief overview of Islamic history from the time of Prophet Muhammad (PBUH) to modern times.",
      //   link: "https://ia801707.us.archive.org/28/items/IslamicHistoryAShortIntroduction/Islamic%20History%20A%20Short%20Introduction.pdf",
      //   buyLink: "https://amzn.to/4hTxXY9",
      //   cover: "https://m.media-amazon.com/images/I/81gQ5X6YFjL._AC_UL1000_.jpg"
      // },
      // {
      //   title: "Islamic Ethics: A Guide to the Islamic Way of Life",
      //   description: "A detailed guide to Islamic ethics and moral values based on the Quran and Hadith.",
      //   link: "https://ia802703.us.archive.org/20/items/IslamicEthicsAGuideToTheIslamicWayOfLife/Islamic%20Ethics%20A%20Guide%20to%20the%20Islamic%20Way%20of%20Life.pdf",
      //   buyLink: "https://amzn.to/4hXxW2n",
      //   cover: "https://m.media-amazon.com/images/I/91VqyojctgL._AC_UL1000_.jpg"
      // },
      // {
      //   title: "The Islamic State",
      //   description: "An analysis of the concept of the Islamic state, its governance, and the historical models in Islamic history.",
      //   link: "https://ia801605.us.archive.org/6/items/TheIslamicState/The%20Islamic%20State.pdf",
      //   buyLink: "https://amzn.to/3EXwPfm",
      //   cover: "https://m.media-amazon.com/images/I/81ZqS4fg6pL._AC_UL1000_.jpg"
      // }     
  ];

const filteredBooks = books.filter((book) =>
  book.title.toLowerCase().includes(searchTerm.toLowerCase()) 
);

// ||
  // book.description.toLowerCase().includes(searchTerm.toLowerCase())

return (
  <div className="IslamicBooks">
    <h1>Islamic Books</h1>
    <input
        type="text"
        placeholder="Search for books..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
    <div className="books-grid">
      {filteredBooks.map((book, index) => (
        <div key={index} className="book-item">
          <a href={book.link} target="_blank" rel="noopener noreferrer">
            <img
              src={book.cover}
              alt={`${book.title} Cover`}
              className="book-cover"
            />
          </a>
          <h3>{book.title}</h3>
          <p>{book.description}</p>
          <a href={book.buyLink} target="_blank" rel="noopener noreferrer" className="buy-button">
            Buy
          </a>
        </div>
      ))}
    </div>
  </div>
);
};

export default IslamicBooks;
