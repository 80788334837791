import React from 'react';
import './NinetyNineNames.css'; // Add a CSS file for styling (optional)
import { useState } from 'react';

const namesOfAllah = [
  { arabic: "ٱلرَّحْمَٰنُ", transliteration: "Ar-Rahman", meaning: "The Most Gracious" },
  { arabic: "ٱلرَّحِيمُ", transliteration: "Ar-Rahim", meaning: "The Most Merciful" },
  { arabic: "ٱلْمَلِكُ", transliteration: "Al-Malik", meaning: "The King and Owner of the Dominion" },
  { arabic: "ٱلْقُدُّوسُ", transliteration: "Al-Quddus", meaning: "The Absolutely Pure" },
  { arabic: "ٱلسَّلَامُ", transliteration: "As-Salam", meaning: "The Source of Peace and Safety" },
  { arabic: "ٱلْمُؤْمِنُ", transliteration: "Al-Mu’min", meaning: "The Giver of Faith and Security" },
  { arabic: "ٱلْمُهَيْمِنُ", transliteration: "Al-Muhaymin", meaning: "The Guardian, The Witness, The Overseer" },
  { arabic: "ٱلْعَزِيزُ", transliteration: "Al-Aziz", meaning: "The Almighty" },
  { arabic: "ٱلْجَبَّارُ", transliteration: "Al-Jabbar", meaning: "The Compeller, The Restorer" },
  { arabic: "ٱلْمُتَكَبِّرُ", transliteration: "Al-Mutakabbir", meaning: "The Supreme, The Majestic" },
  { arabic: "ٱلْخَالِقُ", transliteration: "Al-Khaliq", meaning: "The Creator, The Maker" },
  { arabic: "ٱلْبَارِئُ", transliteration: "Al-Bari", meaning: "The Evolver" },
  { arabic: "ٱلْمُصَوِّرُ", transliteration: "Al-Musawwir", meaning: "The Fashioner" },
  { arabic: "ٱلْغَفَّارُ", transliteration: "Al-Ghaffar", meaning: "The Constant Forgiver" },
  { arabic: "ٱلْقَهَّارُ", transliteration: "Al-Qahhar", meaning: "The All-Subduer" },
  { arabic: "ٱلْوَهَّابُ", transliteration: "Al-Wahhab", meaning: "The Supreme Bestower" },
  { arabic: "ٱلرَّزَّاقُ", transliteration: "Ar-Razzaq", meaning: "The Provider" },
  { arabic: "ٱلْفَتَّاحُ", transliteration: "Al-Fattah", meaning: "The Supreme Solver" },
  { arabic: "ٱلْعَلِيمُ", transliteration: "Al-Alim", meaning: "The All-Knowing" },
  { arabic: "ٱلْقَابِضُ", transliteration: "Al-Qabid", meaning: "The Withholder" },
  { arabic: "ٱلْبَاسِطُ", transliteration: "Al-Basit", meaning: "The Extender" },
  { arabic: "ٱلْخَافِضُ", transliteration: "Al-Khafid", meaning: "The Reducer" },
  { arabic: "ٱلرَّافِعُ", transliteration: "Ar-Rafi", meaning: "The Exalter, The Elevator" },
  { arabic: "ٱلْمُعِزُّ", transliteration: "Al-Mu’izz", meaning: "The Honourer, The Bestower" },
  { arabic: "ٱلْمُذِلُّ", transliteration: "Al-Mudhill", meaning: "The Dishonourer" },
  { arabic: "ٱلسَّمِيعُ", transliteration: "As-Sami", meaning: "The All-Hearing" },
  { arabic: "ٱلْبَصِيرُ", transliteration: "Al-Basir", meaning: "The All-Seeing" },
  { arabic: "ٱلْحَكَمُ", transliteration: "Al-Hakam", meaning: "The Impartial Judge" },
  { arabic: "ٱلْعَدْلُ", transliteration: "Al-Adl", meaning: "The Utterly Just" },
  { arabic: "ٱلْلَّطِيفُ", transliteration: "Al-Latif", meaning: "The Subtle One, The Most Gentle" },
  { arabic: "ٱلْخَبِيرُ", transliteration: "Al-Khabir", meaning: "The All-Aware" },
  { arabic: "ٱلْحَلِيمُ", transliteration: "Al-Halim", meaning: "The Most Forbearing" },
  { arabic: "ٱلْعَظِيمُ", transliteration: "Al-Azim", meaning: "The Magnificent, The Infinite" },
  { arabic: "ٱلْغَفُورُ", transliteration: "Al-Ghaffur", meaning: "The Great Forgiver" },
  { arabic: "ٱلشَّكُورُ", transliteration: "Ash-Shakur", meaning: "The Most Appreciative" },
  { arabic: "ٱلْعَلِيُّ", transliteration: "Al-Aliyy", meaning: "The Most High, The Exalted" },
  { arabic: "ٱلْكَبِيرُ", transliteration: "Al-Kabir", meaning: "The Most Great" },
  { arabic: "ٱلْحَفِيظُ", transliteration: "Al-Hafiz", meaning: "The Preserver" },
  { arabic: "ٱلْمُقِيتُ", transliteration: "Al-Muqit", meaning: "The Sustainer" },
  { arabic: "ٱلْحَسِيبُ", transliteration: "Al-Hasib", meaning: "The Reckoner" },
  { arabic: "ٱلْجَلِيلُ", transliteration: "Al-Jalil", meaning: "The Majestic" },
  { arabic: "ٱلْكَرِيمُ", transliteration: "Al-Karim", meaning: "The Most Generous, The Most Esteemed" },
  { arabic: "ٱلرَّقِيبُ", transliteration: "Ar-Raqib", meaning: "The Watchful" },
  { arabic: "ٱلْمُجِيبُ", transliteration: "Al-Mujib", meaning: "The Responsive One" },
  { arabic: "ٱلْوَاسِعُ", transliteration: "Al-Wasi", meaning: "The All-Encompassing, the Boundless" },
  { arabic: "ٱلْحَكِيمُ", transliteration: "Al-Hakim", meaning: "The All-Wise" },
  { arabic: "ٱلْوَدُودُ", transliteration: "Al-Wadud", meaning: "The Most Loving" },
  { arabic: "ٱلْمَجِيدُ", transliteration: "Al-Majid", meaning: "The Glorious, the Most Honorable" },
  { arabic: "ٱلْبَاعِثُ", transliteration: "Al-Ba’ith", meaning: "The Infuser of New Life" },
  { arabic: "ٱلشَّهِيدُ", transliteration: "Ash-Shahid", meaning: "The All-and-Ever Witnessing" },
  { arabic: "ٱلْحَقُ", transliteration: "Al-Haqq", meaning: "The Absolute Truth" },
  { arabic: "ٱلْوَكِيلُ", transliteration: "Al-Wakil", meaning: "The Trustee, the Disposer of Affairs" },
  { arabic: "ٱلْقَوِيُ", transliteration: "Al-Qawiyy", meaning: "The All-Strong" },
  { arabic: "ٱلْمَتِينُ", transliteration: "Al-Matin", meaning: "The Firm One" },
  { arabic: "ٱلْوَلِيُّ", transliteration: "Al-Waliyy", meaning: "The Sole-Authority" },
  { arabic: "ٱلْحَمِيدُ", transliteration: "Al-Hamid", meaning: "The Praiseworthy" },
  { arabic: "ٱلْمُحْصِيُ", transliteration: "Al-Muhsi", meaning: "The All-Enumerating, the Counter" },
  { arabic: "ٱلْمُبْدِئُ", transliteration: "Al-Mubdi", meaning: "The Originator, the Initiator" },
  { arabic: "ٱلْمُعِيدُ", transliteration: "Al-Mueed", meaning: "The Restorer, the Reinstater" },
  { arabic: "ٱلْمُحْيِى", transliteration: "Al-Muhyi", meaning: "The Giver of Life" },
  { arabic: "ٱلْمُمِيتُ", transliteration: "Al-Mumit", meaning: "The Creator of Death" },
  { arabic: "ٱلْحَىُّ", transliteration: "Al-Hayy", meaning: "The Ever-Living" },
  { arabic: "ٱلْقَيُّومُ", transliteration: "Al-Qayyum", meaning: "The Sustainer, the Self-Subsisting" },
  { arabic: "ٱلْوَاجِدُ", transliteration: "Al-Wajid", meaning: "The Perceiver" },
  { arabic: "ٱلْمَاجِدُ", transliteration: "Al-Majid", meaning: "The Glorious, the Most Honorable" },
  { arabic: "ٱلْواحِدُ", transliteration: "Al-Wahid", meaning: "The Only" },
  { arabic: "ٱلْأَحَدُ", transliteration: "Al-Ahad", meaning: "The Indivisible, the Infinite" },
  { arabic: "ٱلصَّمَدُ", transliteration: "As-Samad", meaning: "The Self-Sufficient, The Impregnable" },
  { arabic: "ٱلْقَادِرُ", transliteration: "Al-Qadir", meaning: "The Omnipotent" },
  { arabic: "ٱلْمُقْتَدِرُ", transliteration: "Al-Muqtadir", meaning: "The Creator of All Power" },
  { arabic: "ٱلْمُقَدِّمُ", transliteration: "Al-Muqaddim", meaning: "The Expediter, the Promoter" },
  { arabic: "ٱلْمُؤَخِّرُ", transliteration: "Al-Mu’akhkhir", meaning: "The Delayer" },
  { arabic: "ٱلأوَّلُ", transliteration: "Al-Awwal", meaning: "The First" },
  { arabic: "ٱلْآخِرُ", transliteration: "Al-Akhir", meaning: "The Last" },
  { arabic: "ٱلْظَّاهِرُ", transliteration: "Az-Zahir", meaning: "The Manifest" },
  { arabic: "ٱلْبَاطِنُ", transliteration: "Al-Batin", meaning: "The Hidden One, Knower of the Hidden" },
  { arabic: "ٱلْوَالِي", transliteration: "Al-Wali", meaning: "Sole Governor" },
  { arabic: "ٱلْمُتَعَالِي", transliteration: "Al-Muta’ali", meaning: "Self Exalted" },
  { arabic: "ٱلْبَرُّ", transliteration: "Al-Barr", meaning: "The Source of All Goodness" },
  { arabic: "ٱلْتَّوَابُ", transliteration: "At-Tawwab", meaning: "The Ever-Accepter of Repentance" },
  { arabic: "ٱلْمُنْتَقِمُ", transliteration: "Al-Muntaqim", meaning: "The Avenger" },
  { arabic: "ٱلْعَفُوُ", transliteration: "Al-Afuww", meaning: "The Pardoner" },
  { arabic: "ٱلْرَّؤُوفُ", transliteration: "Ar-Ra’uf", meaning: "The Most Kind" },
  { arabic: "مَالِكُ ٱلْمُلْكُ", transliteration: "Malik-ul-Mulk", meaning: "Master of the Kingdom, Owner of the Dominion" },
  { arabic: "ذُوالْجَلَالِ وَالإكْرَامِ", transliteration: "Dhul-Jalali Wal-Ikram", meaning: "Possessor of Glory and Honour, Lord of Majesty and Generosity" },
  { arabic: "ٱلْمُقْسِطُ", transliteration: "Al-Muqsit", meaning: "The Just One" },
  { arabic: "ٱلْجَامِعُ", transliteration: "Al-Jami", meaning: "The Gatherer, the Uniter" },
  { arabic: "ٱلْغَنِيُّ", transliteration: "Al-Ghani", meaning: "The Self-Sufficient, the Wealthy" },
  { arabic: "ٱلْمُغْنِيُّ", transliteration: "Al-Mughni", meaning: "The Enricher" },
  { arabic: "ٱلْمَانِعُ", transliteration: "Al-Mani", meaning: "The Withholder" },
  { arabic: "ٱلضَّارَ", transliteration: "Ad-Darr", meaning: "The Distresser" },
  { arabic: "ٱلنَّافِعُ", transliteration: "An-Nafi", meaning: "The Propitious, the Benefactor" },
  { arabic: "ٱلْنُّورُ", transliteration: "An-Nur", meaning: "The Light" },
  { arabic: "ٱلْهَادِي", transliteration: "Al-Hadi", meaning: "The Provider of Guidance" },
  { arabic: "ٱلْبَدِيعُ", transliteration: "Al-Badi", meaning: "The Incomparable Originator" },
  { arabic: "ٱلْبَاقِي", transliteration: "Al-Baqi", meaning: "The Ever-Surviving" },
  { arabic: "ٱلْوَارِثُ", transliteration: "Al-Warith", meaning: "The Inheritor, The Heir" },
  { arabic: "ٱلرَّشِيدُ", transliteration: "Ar-Rashid", meaning: "The Guide, Infallible Teacher and Knower" },
  { arabic: "ٱلصَّبُورُ", transliteration: "As-Sabur", meaning: "The Extensively Enduring" }
];


function NinetyNineNames() {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredNames = namesOfAllah.filter((name) =>
    name.transliteration.toLowerCase().includes(searchQuery.toLowerCase())
  );

return (
  <div className="ninety-nine-names-container">
    <h2>99 Names of Allah</h2>
    <input
      type="text"
      placeholder="Search by transliteration"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="search-input"
    />
    <div className="names-list">
      {filteredNames.map((name, index) => (
        <div key={index} className="name-card">
          <h3 className="arabic-name">{name.arabic}</h3>
          <p className="transliteration">{name.transliteration}</p>
          <p className="meaning">{name.meaning}</p>
        </div>
      ))}
    </div>
  </div>
);
}

export default NinetyNineNames;