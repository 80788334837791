import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './IslamicCountries.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'; // Optional: If map is still required

const IslamicCountries = () => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryDetails, setCountryDetails] = useState(null);
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      const countriesData = [
        { name: "Afghanistan", code: "AF", capital: "Kabul", region: "Asia", lat: 33.9391, lon: 67.7099 },
        { name: "Algeria", code: "DZ", capital: "Algiers", region: "Africa", lat: 28.0339, lon: 1.6596 },
        { name: "Azerbaijan", code: "AZ", capital: "Baku", region: "Asia", lat: 40.1431, lon: 47.5769 },
        { name: "Bahrain", code: "BH", capital: "Manama", region: "Middle East", lat: 25.9304, lon: 50.6378 },
        { name: "Bangladesh", code: "BD", capital: "Dhaka", region: "Asia", lat: 23.685, lon: 90.3563 },
        { name: "Brunei", code: "BN", capital: "Bandar Seri Begawan", region: "Asia", lat: 4.5353, lon: 114.7277 },
        { name: "Chad", code: "TD", capital: "N'Djamena", region: "Africa", lat: 12.6348, lon: 14.0028 },
        { name: "Comoros", code: "KM", capital: "Moroni", region: "Africa", lat: -11.875, lon: 43.8722 },
        { name: "Djibouti", code: "DJ", capital: "Djibouti", region: "Africa", lat: 11.8251, lon: 42.5903 },
        { name: "Egypt", code: "EG", capital: "Cairo", region: "Africa", lat: 26.8206, lon: 30.8025 },
        { name: "Gabon", code: "GA", capital: "Libreville", region: "Africa", lat: -0.8037, lon: 11.6094 },
        { name: "Gambia", code: "GM", capital: "Banjul", region: "Africa", lat: 13.4432, lon: -15.3101 },
        { name: "Guinea", code: "GN", capital: "Conakry", region: "Africa", lat: 9.9456, lon: -9.6966 },
        { name: "Indonesia", code: "ID", capital: "Jakarta", region: "Asia", lat: -0.7893, lon: 113.9213 },
        { name: "Iran", code: "IR", capital: "Tehran", region: "Asia", lat: 32.4279, lon: 53.6880 },
        { name: "Iraq", code: "IQ", capital: "Baghdad", region: "Middle East", lat: 33.2232, lon: 43.6793 },
        { name: "Jordan", code: "JO", capital: "Amman", region: "Middle East", lat: 30.5852, lon: 36.2384 },
        { name: "Kazakhstan", code: "KZ", capital: "Astana", region: "Asia", lat: 48.0196, lon: 66.9237 },
        { name: "Kuwait", code: "KW", capital: "Kuwait City", region: "Middle East", lat: 29.3759, lon: 47.9774 },
        { name: "Kyrgyzstan", code: "KG", capital: "Bishkek", region: "Asia", lat: 41.2044, lon: 74.7661 },
        { name: "Lebanon", code: "LB", capital: "Beirut", region: "Middle East", lat: 33.8547, lon: 35.8623 },
        { name: "Libya", code: "LY", capital: "Tripoli", region: "Africa", lat: 26.3351, lon: 17.2283 },
        { name: "Malaysia", code: "MY", capital: "Kuala Lumpur", region: "Asia", lat: 4.2105, lon: 101.9758 },
        { name: "Maldives", code: "MV", capital: "Malé", region: "Asia", lat: 3.2028, lon: 73.2207 },
        { name: "Mali", code: "ML", capital: "Bamako", region: "Africa", lat: 12.6392, lon: -8.0029 },
        { name: "Mauritania", code: "MR", capital: "Nouakchott", region: "Africa", lat: 21.0079, lon: -10.9400 },
        { name: "Morocco", code: "MA", capital: "Rabat", region: "Africa", lat: 31.7917, lon: -7.0926 },
        { name: "Niger", code: "NE", capital: "Niamey", region: "Africa", lat: 17.6078, lon: 8.0817 },
        { name: "Nigeria", code: "NG", capital: "Abuja", region: "Africa", lat: 9.082, lon: 8.6753 },
        { name: "Oman", code: "OM", capital: "Muscat", region: "Middle East", lat: 21.3737, lon: 55.3131 },
        { name: "Pakistan", code: "PK", capital: "Islamabad", region: "Asia", lat: 30.3753, lon: 69.3451 },
        { name: "Palestine", code: "PS", capital: "Ramallah", region: "Middle East", lat: 31.9474, lon: 35.2272 },
        { name: "Qatar", code: "QA", capital: "Doha", region: "Middle East", lat: 25.276987, lon: 51.520008 },
        { name: "Saudi Arabia", code: "SA", capital: "Riyadh", region: "Middle East", lat: 23.8859, lon: 45.0792 },
        { name: "Senegal", code: "SN", capital: "Dakar", region: "Africa", lat: 14.6928, lon: -14.0079 },
        { name: "Sierra Leone", code: "SL", capital: "Freetown", region: "Africa", lat: 8.460555, lon: -13.2317 },
        { name: "Somalia", code: "SO", capital: "Mogadishu", region: "Africa", lat: 5.1521, lon: 46.1996 },
        { name: "Sudan", code: "SD", capital: "Khartoum", region: "Africa", lat: 12.8628, lon: 30.2176 },
        { name: "Syria", code: "SY", capital: "Damascus", region: "Middle East", lat: 34.8021, lon: 38.9968 },
        { name: "Tajikistan", code: "TJ", capital: "Dushanbe", region: "Asia", lat: 38.8610, lon: 71.2761 },
        { name: "Tanzania", code: "TZ", capital: "Dodoma", region: "Africa", lat: -6.369028, lon: 34.8888 },
        { name: "Tunisia", code: "TN", capital: "Tunis", region: "Africa", lat: 33.8869, lon: 9.5375 },
        { name: "Turkey", code: "TR", capital: "Ankara", region: "Asia", lat: 38.9637, lon: 35.2433 },
        { name: "Turkmenistan", code: "TM", capital: "Ashgabat", region: "Asia", lat: 38.9697, lon: 59.5563 },
        { name: "United Arab Emirates", code: "AE", capital: "Abu Dhabi", region: "Middle East", lat: 23.4241, lon: 53.8478 },
        { name: "Uzbekistan", code: "UZ", capital: "Tashkent", region: "Asia", lat: 41.3775, lon: 64.5853 },
        { name: "Yemen", code: "YE", capital: "Sanaa", region: "Middle East", lat: 15.5523, lon: 48.5164 }
      ];
      setCountries(countriesData);
    };

    fetchCountries();
  }, []);

  const handleCountryClick = async (countryCode) => {
    const country = countries.find((c) => c.code === countryCode);
    
    if (country) {
      setSelectedCountry(country);

      try {
        const response = await axios.get(`https://restcountries.com/v3.1/alpha/${countryCode}`);
        const countryDetails = response.data[0];
        setCountryDetails({
          population: countryDetails.population,
          area: countryDetails.area,
          languages: countryDetails.languages,
          currencies: countryDetails.currencies,
        });
      } catch (error) {
        console.error('Error fetching country details:', error);
      }

      try {
        const weatherResponse = await axios.get(
          `https://api.openweathermap.org/data/2.5/weather?lat=${country.lat}&lon=${country.lon}&appid=YOUR_API_KEY&units=metric`
        );
        setWeatherData(weatherResponse.data);
      } catch (error) {
        console.error('Error fetching weather data:', error);
      }
    }
  };

  const closePopup = () => {
    setSelectedCountry(null);
    setCountryDetails(null);
    setWeatherData(null);
  };

  return (
    <div className="islamic-countries-container">
      <h2>Islamic Countries</h2>
      <div className="country-list">
        {countries.map((country) => (
          <div
            key={country.code}
            className="country-item"
            onClick={() => handleCountryClick(country.code)}
          >
            <h3>{country.name}</h3>
            <p>{country.region}</p>
          </div>
        ))}
      </div>

      {selectedCountry && (
        <div className="popup">
          <div className="popup-content">
            <h3>Details of {selectedCountry.name}</h3>
            <p>Capital: {selectedCountry.capital}</p>
            <p>Region: {selectedCountry.region}</p>
            {countryDetails && (
              <>
                <p>Population: {countryDetails.population}</p>
                <p>Area: {countryDetails.area} km²</p>
                <p>Languages: {Object.values(countryDetails.languages).join(', ')}</p>
                <p>Currencies: {Object.values(countryDetails.currencies).map(curr => curr.name).join(', ')}</p>
              </>
            )}
            {weatherData && (
              <div className="country-weather">
                <h4>Weather in {selectedCountry.name}</h4>
                <p>Temperature: {weatherData.main.temp}°C</p>
                <p>Weather: {weatherData.weather[0].description}</p>
                <p>Humidity: {weatherData.main.humidity}%</p>
              </div>
            )}
             <button className="close-button" onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IslamicCountries;
